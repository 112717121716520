/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Environment } from 'datnek-core';

@Component({
  selector: 'del-shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  active = 'all';

  @Input()
  topFooter = true;
  
  @Input()
  topDesignBg = true;

  constructor(config: NgbModalConfig, private modalService: NgbModal, @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,) {}

  ngOnInit(): void {}

  open(newContent: any) {
    this.modalService.open(newContent, {
      centered: false,
      backdropClass: 'dc-modal-backdrop',
      windowClass: 'dc-modal-languages',
      scrollable: true,
      size: 'xl',
    });
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  inProgress() {
    //this.router.navigate(['/chat/home']);
    window.location.href = `${this.environment.website.websiteDelenscioHome}/in-progress`;
  }
}
