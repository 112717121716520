<ng-container *ngIf="userData.length && !isLoading">
  <div
    class="
      nk-shared-user-infos-small
      suggestion-list
      d-flex
      align-items-center
      p-3
    "
    *ngFor="let data of userData; let i = index"
  >
    <datnek-ds-avatar
      [size]="sizeEnum.medium1"
      imgSrc="{{ data.imageSrc }}"
      class="mr-3"
      [type]="status"
    ></datnek-ds-avatar>

    <!-- IF TYPE === SOCIETY -->
    <ng-container *ngIf="status === 'society'">
      <div
        class="pointer infos flex-1 p-0" (datnekdsClickOrTouch)="gotoSocietyProfile($event)"
      >
        <div class="d-flex flex-column mr-1">
          <div class="d-flex mb-1 align-items-center">
            <div class="name clip ellipsis">
                <div class="display-3 clip ellipsis nowrap mr-1">
                  {{ data.name }}
                </div>
            </div>
            <img src="assets/icons/src/check-color.png" style="width: 12px !important; height: 12px;" alt="" />
          </div>
          <span class="truncate_1 nowrap">{{ data.sector }}</span>
        </div>
      </div>

      <datnek-ds-button-icon
        [type]="colorEnum.primary"
        [size]="sizeEnum.medium"
        [Icon]="iconEnum.outlineWhiteofficeAdd"
        (datnekdsClickOrTouch)="isFollowed(i)"
        *ngIf="follow === false && status === 'society'"
      ></datnek-ds-button-icon>
      <datnek-ds-button-icon
        [type]="colorEnum.secondary"
        [size]="sizeEnum.medium"
        [Icon]="iconEnum.outlinecheck"
        (datnekdsClickOrTouch)="isFollowed(i)"
        *ngIf="follow === true && status === 'society'"
      ></datnek-ds-button-icon>
    </ng-container>

    <!-- IF STATUS === PERSON -->
    <ng-container *ngIf="status === 'person'">
      <div class="infos pointer flex-1 p-0" target="_blank">
        <ng-container *ngIf="profile && !link">
          <!-- <div class="d-flex flex-column" (datnekdsClickOrTouch)="gotoPersonProfile($event)">
            <div class="d-flex">
              <div class="name">
                <div class="display-2 clip ellipsis nowrap mr-2">
                  {{ data.name }}
                </div>
                <img src="assets/icons/src/check-color.png" alt="" />
              </div>
            </div>
            <span class="details"><span class="clip ellipsis pb-0 nowrap">{{ data.professionalTitle }}</span>  à <span class="clip ellipsis pb-0 nowrap">{{ data.society }}</span> </span>
            <span id="relations" class="display-8">
              {{ data.relations }} relations en commun
            </span>
          </div> -->
          <div class="d-flex flex-column mr-1" (datnekdsClickOrTouch)="gotoPersonProfile($event)">
            <div class="d-flex align-items-center mb-1" style="width: 97%;">
              <div class="name display-3 clip ellipsis mr-1">
                <div class="clip ellipsis">{{ data.name }}</div> 
              </div>
              <img style="/*margin-top: -6px*/" src="assets/icons/src/check-color.png" alt="" />
            </div>
            <span class="truncate_2">{{ data.professionalTitle }} à {{ data.society }}</span>
            <span id="relations" class="display-8">
              {{ data.relations }} relations en commun
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="chat">
          <div class="d-flex flex-column mr-1" (datnekdsClickOrTouch)="addToChat()">
            <div class="d-flex align-items-center mb-1" style="width: 97%;">
              <div class="name display-3 clip ellipsis mr-1">
                <div class="clip ellipsis">{{ data.name }}</div> 
              </div>
              <img style="/*margin-top: -6px*/" src="assets/icons/src/check-color.png" alt="" />
            </div>
            <span class="truncate_2">{{ data.professionalTitle }} à {{ data.society }}</span>
            <span id="relations" class="display-8">
              {{ data.relations }} relations en commun
            </span>
          </div>
        </ng-container>

        <ng-container  *ngIf="link">
          <div class="d-flex flex-column mr-1">
            <div class="d-flex align-items-center mb-1" style="width: 97%;">
              <div class="name display-3 clip ellipsis mr-1">
                <div class="clip ellipsis">{{ data.name }}</div> 
              </div>
              <img style="/*margin-top: -6px;*/" src="assets/icons/src/check-color.png" alt="" />
            </div>
            <span class="truncate_2">{{ data.professionalTitle }} à {{ data.society }}</span>
            <span id="relations" class="display-8">
              {{ data.relations }} relations en commun
            </span>
          </div>
        </ng-container>
      </div>
      <datnek-ds-button-icon
        [type]="colorEnum.primary"
        [size]="sizeEnum.medium"
        [Icon]="iconEnum.outlineWhiteuserAdd"
        (datnekdsClickOrTouch)="isFollowed(i)"
        class=""
        *ngIf="follow === false && status === 'person'"
      ></datnek-ds-button-icon>
      <datnek-ds-button-icon
        [type]="colorEnum.secondary"
        [size]="sizeEnum.medium"
        [Icon]="iconEnum.outlinecheck"
        (datnekdsClickOrTouch)="isFollowed(i)"
        class=""
        *ngIf="follow === true && status === 'person'"
      ></datnek-ds-button-icon>
      <datnek-ds-button
        [type]="colorEnum.primary"
        [size]="sizeEnum.medium"
        label="Message"
        (datnekdsClickOrTouch)="isFollowed(i)"
        *ngIf="sendMessage === true"
        class="ml-3"
      ></datnek-ds-button>
      <div class="roles">
        <div class="d-inline-block roles-dropdown" *ngIf="role === true">
          <button
            (datnekdsClickOrTouch)="openDropdown(actions, origin3)"
            #origin3
          >
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsVertical"
              [type]="colorEnum.unstyled"
              [size]="sizeEnum.medium"
              id="dropdownBasic2"
              class="ml-3"
            ></datnek-ds-button-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <!-- IF TYPE === GROUP -->
    <ng-container *ngIf="status === 'group'">
      <div
        class="d-flex flex-column pointer"
        (datnekdsClickOrTouch)="gotoGroup($event)"
      >
        <div class="d-flex">
          <div class="name display-4 d-inline mr-2">
            {{ data.groupName }}
          </div>
        </div>
        <span>En ligne il ya 22h</span>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="countSkeleton === 1">
  <ng-container *ngFor="let in of counter(1); let i = index">
    <datnek-skeleton-suggestion-list
      *ngIf="isLoading"
    ></datnek-skeleton-suggestion-list>
  </ng-container>
</ng-container>

<ng-container *ngIf="countSkeleton === 2">
  <ng-container *ngFor="let in of counter(2); let i = index">
    <datnek-skeleton-suggestion-list
      *ngIf="isLoading"
    ></datnek-skeleton-suggestion-list>
  </ng-container>
</ng-container>

<ng-container *ngIf="countSkeleton === 3">
  <ng-container *ngFor="let in of counter(3); let i = index">
    <datnek-skeleton-suggestion-list
      *ngIf="isLoading"
    ></datnek-skeleton-suggestion-list>
  </ng-container>
</ng-container>

<!--NOTIFS MESSAGES-->
<ng-template #adm let-close="close">
  John Doe a été promu comme Administrateur du groupe
</ng-template>
<ng-template #md let-close="close">
  John Doe a été promu comme modérateur du groupe
</ng-template>
<ng-template #del let-close="close">
  John Doe a été retiré du groupe
</ng-template>

<!-- DROPDOWNS -->
<ng-template #actions let-close="close">
  <div class="datnek-ds-card dropdown-card suggestion-members-action">
    <button ngbDropdownItem (datnekdsClickOrTouch)="showToast(adm); close()">
      Ajouter comme Admin
    </button>
    <button ngbDropdownItem (datnekdsClickOrTouch)="showToast(md); close()">
      Ajouter comme modérateur
    </button>
    <button ngbDropdownItem (datnekdsClickOrTouch)="showToast(del); close()">
      Retirer du groupe
    </button>
  </div>
</ng-template>
