import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {
  MenuModule,
  MoleculeModule,
  NetworkSharedModule,
  OrganismSharedModule,
} from '@network-shared';
import {
  DatnekDsCropperModule,
  DatnekDsGalleryModule,
  DatnekDsUploadFileModule,
  DialogRef,
} from 'datnek-ds';
import { ComponentComp } from './ui/components/component-comp';
import { ContainerComp } from './ui/container/container-comp';
import { HomeCompFormly } from './ui/formly/home-comp-formly';
import { FormlyModule } from '@ngx-formly/core';
import {SharedModule} from "@del-shared";
import { CalendarViewComponent } from './ui/components/events/calendar-view/calendar-view.component';
import { RouterModule } from '@angular/router';
import { ListViewComponent } from './ui/components/events/list-view/list-view.component';
import { ListEventsItemComponent } from './ui/components/events/list-view/list-events-item/list-events-item.component';

@NgModule({
  declarations: [...ContainerComp, ...ComponentComp, ...HomeCompFormly, CalendarViewComponent, ListViewComponent, ListEventsItemComponent],
  imports: [
        RouterModule,
        CommonModule,
        HomeRoutingModule,
        NetworkSharedModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        OrganismSharedModule,
        MenuModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        NgbModalModule,
        CarouselModule,
        MoleculeModule,
        DatnekDsGalleryModule,
        DatnekDsCropperModule,
        DatnekDsUploadFileModule,
        FormlyModule,
        SharedModule,
    ],
  providers: [{ provide: DialogRef, useValue: {} }],
})
export class HomeModule {}
