import {RouterModule, Routes} from "@angular/router";

import {NgModule} from "@angular/core";
import { InProgressComponent } from "./ui/pages/in-progress/in-progress.component";


const routes: Routes = [
  { path:  "in-progress", component: InProgressComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
