<ng-container *ngIf="isGroup === false">
  <div class="nk-shared-user-infos-small d-flex align-items-start">
    <datnek-ds-avatar
      [size]="sizeEnum.medium1"
      [type]="status"
      [imgSrc]="imgSrc"
      class="mr-3"
    ></datnek-ds-avatar>
    <div class="infos">
      <div class="top-infos d-flex flex-wrap">
        <div class="name d-flex align-items-center display-2 text-left mr-2">
          <span class="mr-1 clip ellipsis nowrap">{{ name }}</span>
          <img
            src="/assets/icons/src/check-color.png"
            alt=""
            *ngIf="status !== 'group'"
          />
        </div>

        <!--<datnek-ds-link
          label="@bassakirou"
          class="ml-2 d-none d-sm-block"
          *ngIf="status !== 'group'"
        ></datnek-ds-link>-->
        <span
          >a publié dans <datnek-ds-link label="@nomDuGroupe"></datnek-ds-link
        ></span>
      </div>
      <div class="position-relative d-none d-sm-block" *ngIf="status !== 'group'">
        <span
          class="d-flex align-items-center pointer"
          (datnekdsClickOrTouch)="onShowSubmenuCompetence()"
          >{{ professionalTitle }}
          <datnek-ds-svg-icon
            [icon]="iconEnum.outlinechevronDown"
            class="ml-2"
          ></datnek-ds-svg-icon
        ></span>
        <div
          class="datnek-ds-card submenu-competences"
          [class.d-flex]="showSubmenuCompetence === true"
        >
          Compétence associée 1, Compétence associée 2, Compétence associée 3,
          Compétence associée 4, Compétence associée 5
        </div>
      </div>

      <div class="user-dadas d-flex align-items-center">
        <small class="time display-6 mr-2">Il ya 5j</small>
        <small class="point"></small>
        <datnek-ds-icon-dropdown
          [icon]="iconEnum.outlineglobe"
          [submenuData]="stateSubmenu"
        ></datnek-ds-icon-dropdown>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isGroup === true">
  <div class="nk-shared-user-infos-small d-flex align-items-center">
    <datnek-ds-avatar
      [size]="sizeEnum.medium1"
      [type]="status"
      [imgSrc]="imgSrc"
      class="mr-3"
    ></datnek-ds-avatar>
    <div class="infos">
      <div class="top-infos d-flex flex-wrap mb-1">
        <div class="name d-flex align-items-center display-2 text-left mr-2">
          <span class="mr-1">{{ name | truncatetext: 18 }}</span>
        </div>
      </div>

      <div
        class="user-dadas d-flex align-items-center"
        *ngIf="isTyping === false"
      >
        <small class="time display-6 mr-2">Connecté Il ya 5j</small>
      </div>
      <div
        class="user-dadas d-flex align-items-center"
        *ngIf="isTyping === true"
      >
        <small class="time display-6 mr-2"><em>Typing...</em></small>
      </div>
    </div>
  </div>
</ng-container>
