<div class="show-gallery-publication-lightbox" *ngIf="currentLightboxVideo">
    <div class="d-flex w-100 flex-column flex-md-row">
      <div class="lightbox-view">
        <span *ngIf="showCount" class="count"
          >{{ currentVideoIndex + 1 }}/{{ totalVideoCount }} |
          {{ currentLightboxVideo.title }}</span
        >
        <!--CLOSE-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.solidinboxclose"
          [type]="colorEnum.secondary"
          class="close-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="ref.close()"
        ></datnek-ds-button-icon>
  
        <!--ZOOM IN-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinezoomIn"
          [type]="colorEnum.secondary"
          class="zoomin-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="zoomInTemplate()"
        ></datnek-ds-button-icon>
  
        <!--ZOOM OUT-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinezoomOut"
          [type]="colorEnum.secondary"
          class="zoomout-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="zoomOutTemplate()"
        ></datnek-ds-button-icon>
  
        <!--FAVORITE-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlineheart"
          [type]="colorEnum.secondary"
          class="favorite-btn btn-gallery"
          [round]="true"
          [fade]="true"
        ></datnek-ds-button-icon>
  
        <!--PREVIOUS-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinechevronLeft"
          *ngIf="controls && previewVideo"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="prevVideo()"
          class="lightbox-carousel btn-gallery btn-prev"
          [round]="true"
          [fade]="true"
        ></datnek-ds-button-icon>
  
        <!--NEXT-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinechevronRight"
          *ngIf="controls && previewVideo"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="nextVideo()"
          class="lightbox-carousel btn-gallery btn-next"
          [round]="true"
          [fade]="true"
        ></datnek-ds-button-icon>
  
        <!-- LES IMAGES -->
        <div *ngIf="previewVideo" class="lightbox-img">
          <video
            class="embed-responsive embed-responsive-16by9"
            autoplay="false"
            controls
          >
            <source [src]="currentLightboxVideo.videoSrc" />
          </video>
        </div>
      </div>
    </div>
    <!--COMMENTAIRES BOX-->
    <div class="comments-view bg-white">
      <ng-container *ngIf="ref.param.data.componentCommentLightBox">
        <ng-container *ngComponentOutlet="ref.param.data.componentCommentLightBox;
        injector: componentCommentLightBoxInjector"></ng-container>
      </ng-container>
      <!--datnek-ds-comment-lightbox></datnek-ds-comment-lightbox-->
    </div>
  </div>