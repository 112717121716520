import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LyDialog } from '@alyle/ui/dialog';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
} from 'datnek-ds';
import { Overlay } from '@angular/cdk/overlay';
import {
  DialogDeleteGroupComponent,
  DialogPostInGroupOrUserComponent,
  DialogSharePostWithEditComponent,
  DialogViewParticipantsComponent,
} from '@network-shared';
import { DialogAddCalendarComponent, Event, EventsService } from '@del-shared';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
  challengedCompetence: string;
  userYearsOfExperience: number;
}

@Component({
  selector: 'network-single-event',
  templateUrl: './single-event.component.html',
  styleUrls: ['./single-event.component.scss'],
})
export class SingleEventComponent implements OnInit {
  event!: Event;
  isLoading = false;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  eventTitle = "Nom complet de l'évènement de l'évènement";
  eventSubtitle = "Sous-titre de l'évènement";

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur et Data Senior',
      name: 'Fara Moiten 2 kkjsfksjd skfjkds fkjhfjksdfh kj fhjsdfdsk skjdnskj jksdfhkjdfh jds',
      society: 'DIGITAILLE Multimedia Intertainement',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
      challengedCompetence: 'Angular JS',
      userYearsOfExperience: 10,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Dan Luiten',
      society: 'DIGITAILLE',
      groupName: 'Jobs, emplois et concours',
      relations: 18,
      sector: 'Technologie',
      challengedCompetence: 'Angular JS',
      userYearsOfExperience: 10,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Jobs, emplois et concours',
      relations: 0,
      sector: 'Agroalimentaire',
      challengedCompetence: 'Angular JS',
      userYearsOfExperience: 10,
    },
  ];

  constructor(
    private modalService: DialogService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
    const eventId = this.route.snapshot.params['id'];
    this.event = this.eventService.getEventById(eventId);
  }

  editEvent() {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  deleteEvent() {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer l'évènement`,
        size: DialogSizeEnum.small,
      },
    });
  }

  sharePostWithEdit() {
    const ref = this.modalService.open(DialogSharePostWithEditComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  postInGroupOrUser() {
    const ref = this.modalService.open(DialogPostInGroupOrUserComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager l'évènement dans un groupe`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  // viewParticipants() {
  //   const ref = this.modalService.open(DialogViewParticipantsComponent, {
  //     dialogType: DialogTypeEnum.Dialog,
  //     data: {
  //       title: `Voir les partitipants à l'évènement`,
  //       size: DialogSizeEnum.medium,
  //     },
  //   });
  // }

  goToEvent(e: any) {
    this.router.navigate(['/events/list-view']);
  }

  onViewParticipants() {
    this.router.navigateByUrl(
      `/events/list-view/${this.event.Id}/users`
    );
  }
}
