import { Component, OnInit } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlDisplayEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogRef
} from 'datnek-ds';

interface Items {
  id: number;
  name: string;
  src: string;
  type: string;
}

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-dialog-post-in-user-profile',
  templateUrl: './dialog-post-in-user-profile.component.html',
  styleUrls: ['./dialog-post-in-user-profile.component.scss'],
})
export class DialogPostInUserProfileComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  displayEnum = ControlDisplayEnum;

  meSubmenu: Items[] = [
    {
      id: 0,
      name: 'John Doe',
      src: 'assets/Img/user-placeholder-avatar.svg',
      type: 'person',
    },
    {
      id: 0,
      name: 'DIgitaille_CM',
      src: 'assets/Img/profile_img_jobs.png',
      type: 'society',
    },
    {
      id: 0,
      name: 'Graven Inc.',
      src: 'assets/Img/profile_img_jobs.png',
      type: 'society',
    },
  ];

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur et Data Senior',
      name: 'Fara Moiten 2 kkjsfksjd skfjkds fkjhfjksdfh kj fhjsdfdsk',
      society: 'DIGITAILLE Multimedia Intertainement',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 0,
      sector: 'Agroalimentaire',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];
  constructor(public ref: DialogRef) {}

  ngOnInit(): void {}

  close() {
    this.ref.close(null);
  }
}
