import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LyDialogModule, LyDialogRef } from '@alyle/ui/dialog';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TabViewModule } from 'primeng/tabview';
import { Alyle, PrimeModule } from './modules';
import { OverlayModule } from '@angular/cdk/overlay';
//import { ComponentComp } from './components';
//import { DirectiveComp } from './directive/directive-comp';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  DatnekDsAtomModule,
  DatnekDsMoleculeModule,
  DatnekDsSharedModule,
} from 'datnek-ds';
import { DatnekSkeletonNetworkModule } from 'datnek-skeleton';
import { NgxsModule } from '@ngxs/store';
import { AppNetworkState } from '../../core/store/app-network-state';
import { HammerModule } from '@angular/platform-browser';
import { LyIconModule } from '@alyle/ui/icon';
import { LyButtonModule } from '@alyle/ui/button';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LySnackBarModule } from '@alyle/ui/snack-bar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ChipsModule } from 'primeng/chips';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { SkeletonModule } from 'primeng/skeleton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RegisterNavigateSsoService } from 'datnek-sso';
import { OrganismSharedModule } from '../lib';

@NgModule({
  declarations: [
    // ...ComponentComp,
    //...Pipe,
    // ...DirectiveComp
  ],
  imports: [
    OverlayModule,
    //OrganismSharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    NgxFileDropModule,
    NgbModalModule,
    TabViewModule,
    // PdfViewerModule,
    DatnekDsAtomModule,
    DatnekDsMoleculeModule,
    DatnekSkeletonNetworkModule,
    DatnekDsSharedModule,
    NgxsModule.forFeature([...AppNetworkState]),

    //Alyle
    HammerModule,
    LyIconModule,
    LyButtonModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    LySnackBarModule,
    // end

    //primeNG
    MessagesModule,
    MessageModule,
    ButtonModule,
    ProgressBarModule,
    RippleModule,
    SlideMenuModule,
    ScrollPanelModule,
    ChipsModule,
    TabViewModule,
    AccordionModule,
    SidebarModule,
    FileUploadModule,
    CarouselModule,
    SkeletonModule,
    PanelMenuModule,
    DropdownModule,
    TooltipModule,
    AutoCompleteModule,
    //end prime NG
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    //  ...ComponentComp,
    // ...Pipe,
    // ...DirectiveComp,
    NgbModule,
    //OrganismSharedModule,
    DatnekDsAtomModule,
    DatnekDsMoleculeModule,
    DatnekDsSharedModule,
    DatnekSkeletonNetworkModule,

    //Alyle
    HammerModule,
    LyIconModule,
    LyButtonModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    LySnackBarModule,
    // end

    //primeNG
    MessagesModule,
    MessageModule,
    ButtonModule,
    ProgressBarModule,
    RippleModule,
    SlideMenuModule,
    ScrollPanelModule,
    ChipsModule,
    TabViewModule,
    AccordionModule,
    SidebarModule,
    FileUploadModule,
    CarouselModule,
    SkeletonModule,
    PanelMenuModule,
    DropdownModule,
    TooltipModule,
    AutoCompleteModule,
    //end prime NG
  ],
  providers: [{ provide: LyDialogRef, useValue: {} }],
})
export class NetworkSharedModule {}
