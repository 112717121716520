/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'del-shared-dialog-challenge-no-results',
  templateUrl: './dialog-challenge-no-results.component.html',
  styleUrls: ['./dialog-challenge-no-results.component.scss'],
})
export class DialogChallengeNoResultsComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(public ref: DialogRef) {}

  ngOnInit(): void {}

  close() {
    this.ref.close();
  }
}
