import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable} from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';

import { EventCompanyActions } from './events.actions';
import {Environment, ListData, ListDataInterface} from "datnek-core";

import {Event as EventCompany, MediaNetwork, EventsService,
  LocalizationsNetworksService, MediaNetworksService} from '@del-shared';

import {ToastService} from "datnek-ds";

interface EventStateModel {
  events: ListDataInterface<EventCompany>;
  loadingEventByLazy: boolean;
  loadingSave: boolean;
  loadingRemove: boolean;
  error: any;
}

//const USER_STATE_TOKEN = template StateToken<UserStateModel>('user');

@State<EventStateModel>({
  name: 'eventCompanyNetwork', // TODO: update this
  defaults: {
    events: new ListData(),
    loadingEventByLazy: false,
    loadingSave: false,
    loadingRemove: false,
    error: null,
  },
})
@Injectable()
export class EventState {
  constructor(
    private eventsService: EventsService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private mediaNetworksService: MediaNetworksService,
    private localizationsNetworksService: LocalizationsNetworksService,
    @Inject(Environment.Provider.ENVIRONMENT) private environment: Environment.Env
  ) {}

  @Action(EventCompanyActions.setEventCompany)
  async setEventCompany(
    ctx: StateContext<EventStateModel>,
    action: EventCompanyActions.setEventCompany
  ) {
    ctx.patchState({ events: new ListData(action.count as number, action.data as EventCompany[]) });
  }

  @Action(EventCompanyActions.getLazyByCompany)
  async getLazyByCompany(
    ctx: StateContext<EventStateModel>,
    action: EventCompanyActions.getLazyByCompany
  ) {
    const state = ctx.getState();

    ctx.patchState({ loadingEventByLazy: true, error: null });

    try {
      const result = await this.eventsService
        .getByCompany(action.companyId, action.skip, action.take ?? this.environment.paginationTake.events )
        .toPromise();

      const events = result?.Results?.sort((a, b) => {
        return new Date(b.UpdateAt as Date).getTime() - new Date(a.UpdateAt as Date).getTime();
      }) as EventCompany[];

      const events1: EventCompany[] = state.events.Data?.length
        ? [...state.events.Data, ...events  as EventCompany[]]
        : events as EventCompany[];
      ctx.dispatch(new EventCompanyActions.setEventCompany(result?.Count, events1));
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors  de la récupération la liste des evenements'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingEventByLazy: false });
    }
  }

  @Action(EventCompanyActions.create)
  async createEvent(
    ctx: StateContext<EventStateModel>,
    action: EventCompanyActions.create
  ) {
    const state = ctx.getState();

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const resultSaveAdress = await this.localizationsNetworksService
        .createData(action.model.Address)
        .toPromise();
      if (!resultSaveAdress) {
        throw new Error(
          `Le save de l'adresse a rencontré une erreur, le resulta du save est: result = ${resultSaveAdress}`
        );
      }

      delete action.model.Address;
      action.model.AddressId = resultSaveAdress.Id;

      const saved = await this.eventsService.createData(action.model).toPromise();
      if (!saved) throw new Error('Error');

      saved.Address = resultSaveAdress;

      ctx.dispatch(
        new EventCompanyActions.setEventCompany(state.events.Count as number + 1,
          [
          saved,
          ...state.events.Data as EventCompany[]])
      );

      this.toastService.success('Success', 'evenement enregistrée avec succès');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de l'enregistrement de l'evenement`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(EventCompanyActions.update)
  async updateEvent(
    ctx: StateContext<EventStateModel>,
    action: EventCompanyActions.update
  ) {
    const _events = JSON.parse(JSON.stringify(ctx.getState().events)) as ListDataInterface<EventCompany>;

    ctx.patchState({ loadingSave: true, error: null });

    try {
      const resultSaveAdress = await this.localizationsNetworksService
        .editData(action.model.Address, action.model.AddressId as string)
        .toPromise();
      if (!resultSaveAdress) {
        throw new Error(
          `Le save de l'adresse a rencontré une erreur, le resulta du save est: result = ${resultSaveAdress}`
        );
      }

      delete action.model.Address;

      const saved = await this.eventsService
        .editData(action.model, action.model.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error');

      saved.Address = resultSaveAdress;

      const foundIndex = _events?.Data?.findIndex((e) => e.Id === saved.Id) as number;

      const oldDocuments = _events.Data?.find((ob, i) => i === foundIndex)?.CoverPictureMedias?.length
        ? _events.Data?.find((ob, i) => i === foundIndex)?.CoverPictureMedias
        : [];
      const newDocuments = saved.CoverPictureMedias?.length
        ? [...saved.CoverPictureMedias, ...(oldDocuments as MediaNetwork[])]
        : oldDocuments;

      _events.Data?.splice(foundIndex, 1, {
        ...saved,
        CoverPictureMedias: newDocuments,
      });

      ctx.dispatch(
        new EventCompanyActions.setEventCompany(_events.Count, _events?.Data as EventCompany[])
      );

      this.toastService.success('Success', 'evenement enregistrée avec succès');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de la mise à jour de l'evenement`
      );
      ctx.patchState({ error: error });
      console.log('ERRRORRR ===> ', error);
    } finally {
      ctx.patchState({ loadingSave: false });
    }
  }

  @Action(EventCompanyActions.remove)
  async removeEvent(
    ctx: StateContext<EventStateModel>,
    action: EventCompanyActions.remove
  ) {
    const _events = JSON.parse(
      JSON.stringify(ctx.getState().events.Data)
    ) as EventCompany[];

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const saved = await this.eventsService
        .deleteData(action.model.Id as string)
        .toPromise();
      if (!saved) throw new Error('Error to delete education');

      const foundIndex = _events.findIndex((e) => e.Id === saved.Id);

      _events.splice(foundIndex, 1);
      ctx.dispatch(
        new EventCompanyActions.setEventCompany(
          ctx.getState().events.Count as number - 1,
          _events
        )
      );

      this.toastService.success('Success', `L'evenement a bien été supprimée`);
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        `Un problème est survenue lors de la suppression`
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }

  @Action(EventCompanyActions.removeMedia)
  async removeEventMedia(
    ctx: StateContext<EventStateModel>,
    action: EventCompanyActions.removeMedia
  ) {
    const _data = JSON.parse(
      JSON.stringify(ctx.getState().events.Data)
    ) as EventCompany[];

    ctx.patchState({ loadingRemove: true, error: null });

    try {
      const result = await this.mediaNetworksService
        .deleteData(action.model.Id as string)
        .toPromise();
      if (!result)
        throw new Error(
          'la suppression du media a rencontré une erreur, le resulat du save = ' +
            result
        );

      const foundIndex = _data.findIndex(
        (e) => e.Id === action.model.CoverPictureEventId
      );
      const indexMediaDeleted = _data?.find(cov => cov.Id === action.model.CoverPictureEventId)?.CoverPictureMedias?.findIndex(
        (elt) => elt.Id === action.model.Id
      );

      _data[foundIndex].CoverPictureMedias?.splice(indexMediaDeleted as number, 1);
      _data.splice(foundIndex, 1, _data[foundIndex]);

      ctx.dispatch(
        new EventCompanyActions.setEventCompany(
          ctx.getState().events.Count as number,
          _data
        )
      );

      this.toastService.success('Success', 'Le media a bien été supprimée');
      this.modalService.dismissAll();
    } catch (error) {
      this.toastService.error(
        'Error',
        'Un problème est survenue lors de la suppression du media'
      );
      ctx.patchState({ error: error });
    } finally {
      ctx.patchState({ loadingRemove: false });
    }
  }
}
