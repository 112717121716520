<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="datnek-ds-dialog-body dialog-add-calendar">
    <formly-form
      [model]="model"
      [fields]="fields"
      [options]="options"
      [form]="form"
    ></formly-form>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [disabled]="form.invalid || isLoading"
      [label]="'Publier'"
      [role]="'submit'"
      [size]="sizeEnum.medium"
      [type]="colorEnum.primary"
    ></datnek-ds-button>
  </div>
</form>

<!-- TOASTS MESSAGE -->
<ng-template #afterPublish let-close="close">
  L'évènement a bien été publié
</ng-template>