import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  Type,
} from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from 'datnek-core';

import {
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  SuggestionTypeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-suggestion-list',
  templateUrl: './suggestion-list.component.html',
  styleUrls: ['./suggestion-list.component.scss'],
})
export class SuggestionListComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;

  @Input() userData: UsersItems[] = [];
  currentIndex = 0;

  /**
   * Nombre d'occurrences dans les skeletons
   *
   * @default = 'suggestion'
   */
  @Input() countSkeleton: number = 3;

  /**
   * Le type de suggestion renvoyé : 'invitation' | 'suggestion'
   *
   * @default = 'suggestion'
   */
  @Input()
  type: SuggestionTypeEnum = SuggestionTypeEnum.suggestion;
  //type: 'invitation' | 'suggestion' = 'suggestion';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status: AvatarTypeEnum = AvatarTypeEnum.person;

  /**
   * Button d'envoi de message
   *
   * @default = false
   */
  @Input()
  sendMessage = false;

  /**
   * Si l'on veut ajoutr l'utilisateur comme admin d'un groupe
   *
   * @default = false
   */
  @Input()
  role = false;

  /**
   * Définit s'il s'agit d'un lien ou d'un simple clic pour effectuer une action
   *
   * @default = true
   */
  @Input()
  link = false;

  /**
   * Si le lien renvoit à la page du profil. Sinon le click permet juste d'ajouter les user à une liste
   *
   * @default = true
   */
  @Input()
  profile = true;

  /**
   * Si le lien renvoit à l'ajout de la personne à une conversation de chat
   *
   * @default = false
   */
  @Input()
  chat!: boolean;

  follow = false;

  constructor(private router: Router, private modalService: DialogService,@Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,) {}

  ngOnInit(): void {
    console.log(typeof this.type);
    setTimeout(() => (this.isLoading = false), 2500);
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  isFollowed(index: number): void {
    this.follow = !this.follow;
    this.currentIndex = index;
  }

  gotoEnterprise(e: any): void {
    e.preventDefault();
    this.router.navigate(['/enterprise']);
  }
  gotoGroup(e: any): void {
    e.preventDefault();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/groups/single-group/group-discussion`;
  }
  gotoPersonProfile(e: any): void {
    e.preventDefault();
    window.location.href = `${this.environment.website.websiteDelenscioHome}/private/user/formation/education`;
  }

  addToChat(): void {
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/chat/chat-messages`;
  }

  gotoSocietyProfile(e: any): void {
    e.preventDefault();
    window.location.href = `${this.environment.website.websiteDelenscioHome}/private/company/home`;
  }

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<Event>();

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }
}
