import { Component, Injector, Input, OnInit, Type } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  DialogRef,
  ZoomService,
  AvatarTypeEnum,
  CommentInjectorRef,
} from 'datnek-ds';

interface ImagesItems {
  imageSrc: string;
  imageAlt: string;
  title: string;
  date: string;
}

interface VideosItems {
  videoSrc: string;
  title: string;
  date: string;
}

@Component({
  selector: 'del-shared-dialog-show-medias',
  templateUrl: './dialog-show-medias.component.html',
})
export class DialogShowMediasComponent {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  liked = false;

  showCount = true;
  noComment = false;
  currentIndex = 0;
  currentVideoIndex = 0;
  totalImageCount = 0;
  totalVideoCount = 0;
  currentLightboxImage!: ImagesItems;
  currentLightboxVideo!: VideosItems;
  previewImage = false;
  previewVideo = false;
  controls = true;
  imagesItems!: ImagesItems[];
  galleryVideoItems!: VideosItems[];

  componentCommentLightBoxInjector!: Injector;

  @Input()
  componentCommentLightBox!: Type<any>;

  constructor(
    public ref: DialogRef,
    private zoomService: ZoomService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    console.log(this.ref.param.data);
    this.currentIndex = this.ref.param.data.currentIndex;
    this.currentVideoIndex = this.ref.param.data.currentVideoIndex;
    this.imagesItems = this.ref.param.data.imagesItems;
    this.galleryVideoItems = this.ref.param.data.galleryVideoItems;
    this.controls = this.ref.param.data.controls;
    this.previewImage = this.ref.param.data.previewImage;
    this.previewVideo = this.ref.param.data.previewVideo;
    if (this.imagesItems) {
      this.currentLightboxImage = this.imagesItems[this.currentIndex];
      this.totalImageCount = this.imagesItems.length;
    }
    if (this.galleryVideoItems) {
      this.currentLightboxVideo =
        this.galleryVideoItems[this.currentVideoIndex];
      this.totalVideoCount = this.galleryVideoItems.length;
    }

    if (!this.componentCommentLightBox) {
      this.componentCommentLightBox =
        this.ref.param.data.componentCommentLightBox;
    }

    if (this.componentCommentLightBox) {
      alert();
      this.componentCommentLightBoxInjector = Injector.create({
        providers: [
          { provide: CommentInjectorRef, useValue: { data: {} }, deps: [] },
        ],
        parent: this.injector,
      });
    }
  }

  prev(): void {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.imagesItems.length - 1;
    }
    this.currentLightboxImage = this.imagesItems[this.currentIndex];
  }

  prevVideo(): void {
    this.currentVideoIndex = this.currentVideoIndex - 1;
    if (this.currentVideoIndex < 0) {
      this.currentVideoIndex = this.galleryVideoItems.length - 1;
    }
    this.currentLightboxVideo = this.galleryVideoItems[this.currentVideoIndex];
  }

  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.imagesItems.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLightboxImage = this.imagesItems[this.currentIndex];
  }

  nextVideo(): void {
    this.currentVideoIndex = this.currentVideoIndex + 1;
    if (this.currentVideoIndex > this.galleryVideoItems.length - 1) {
      this.currentVideoIndex = 0;
    }
    this.currentLightboxVideo = this.galleryVideoItems[this.currentVideoIndex];
  }

  zoomInTemplate(): void {
    this.zoomService.zoom =
      this.zoomService.zoom === 2 ? 1 : this.zoomService.zoom + 0.1;
  }

  zoomOutTemplate(): void {
    this.zoomService.zoom =
      this.zoomService.zoom === 0 ? 0 : this.zoomService.zoom - 0.1;
  }

  zoom(): string {
    return (
      'transform : scale(' +
      (this.zoomService.zoom + 1) +
      ');transform-origin: center center;'
    );
  }

  Like() {
    this.liked = !this.liked;
  }

  removeComments(){
    this.noComment = !this.noComment;
  }
}
