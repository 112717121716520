/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Router } from '@angular/router';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';
import { Environment } from 'datnek-core';

@Component({
  selector: 'del-shared-comment-user-infos',
  templateUrl: './comment-user-infos.component.html',
  styleUrls: ['./comment-user-infos.component.scss'],
})
export class CommentUserInfosComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  follow = false;

  @Input()
  commentUserInfo = true;

  linkDescription = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;
  descriptionPage = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;

  constructor(private router: Router, public ref: DialogRef, @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,) {}

  ngOnInit(): void {}

  profile() {
    window.location.href = `${this.environment.website.websiteDelenscioHome}/private/user/formation/education`;
  }

  goToChat() {
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/chat/home`;
  }

  link(url: string) {
    window.open(url, '_blank');
  }
}
