<network-list-events-item
      *ngFor="let item of myEvent"
      [event]="item"
    ></network-list-events-item>
    <ng-container *ngIf="more">
      <network-list-events-item
      *ngFor="let item of myEvent1"
      [event]="item"
    ></network-list-events-item>
    </ng-container>
    <div
    class="footer py-2 d-flex justify-content-center align-items-center"
    >
      <datnek-ds-button
      label="{{more === true ? 'Voir moins' : 'Voir plus'}}"
      (datnekdsClickOrTouch)="showMore()"
      [type]="colorEnum.link"
        [size]="sizeEnum.medium"
      >

      </datnek-ds-button>
    </div>