/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  DialogAddVoiceComponent,
  DialogAddVoiceNoTypeComponent,
  IconRadioImageEnum,
  ControlDisplayEnum,
  UtilsInterface,
  ImageTextDropdownFormlyFieldProps,
  DialogAddPicturesNoTypeComponent, DialogAddVideoNoTypeComponent, DialogAddDocumentNoTypeComponent,
  InputDateFormlyFieldProps
} from 'datnek-ds';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import { Publication } from '../../../core';


@Component({
  selector: 'del-shared-dialog-new-content',
  templateUrl: './dialog-new-content.component.html',
  styleUrls: ['./dialog-new-content.component.scss'],
})
export class DialogNewContentComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  imagesList = false;
  videoList = false;
  linkList = false;
  docList = false;
  voiceList = false;

  imported = false;
  import = false;

  imageList = [
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
    'assets/Img/AdobeStock_104857309-1140x650.jpg',
  ];

  // CROP
  custormBrowser: string = UUID.UUID();
  profile = '';
  subscribeMedia = new Subscription();

  progress = 100;

  linkDescription = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;

  publication: Publication | null = null;

  modelSelect = {
    Visibility:  'public',
    CreatorId:  'danick',
  };
  form = new FormGroup({});
  
  model = {
    Visibility:  'public',
    CreatorId:  'danick',
    Content: null,
    Documents:  null,
  };
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: true,
    },
  };

  fields!: FormlyFieldConfig[];
  //fieldsSelect!: FormlyFieldConfig[];

  constructor(
    private ref: DialogRef,
    private overlay: Overlay,
    private modalService: DialogService
  ) {
    setInterval(() => {
      if (this.progress < 100) {
        Math.round((this.progress = this.progress + 1));
        //console.log('progress bar : ' + this.progress + '%');
      } else {
        this.imported = true;
      }
    }, 200);

    this.fields = [
      {
        key: 'CreatorIdAndVisibility',
        type: 'dialog-new-content-header',
        props: {
          label: 'Publier en tant que *',
          required: true
        },
        fieldArray: {
          fieldGroupClassName: "d-flex align-items-center gap-16",
          fieldGroup: [
            {
              key: 'CreatorId',
              type: 'dropdown-image-text',
              className: '',
              props: {
                required: true,
                isFormControl: true,
                icon: IconRadioImageEnum.me,
                options: [
                  { label: 'Danick takam', value: 'danick', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.me },
                  { label: 'Datnek Bv', value: 'datnek', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.enterprise },
                  { label: 'Afelio', value: 'afelio', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.enterprise },
                ] as UtilsInterface.DropdownItem[],
                valueChange: (value: any)=> {
                  console.log('model', this.model);
                  this.model.CreatorId = value;
                }
              } as ImageTextDropdownFormlyFieldProps,
            },

            {
              key: 'Visibility',
              type: 'select',
              className: '',
              props: {
                required: true,
                options: [
                  { Label: 'Public', Value: 'public'},
                  { Label: 'Privé', Value: 'private' },
                ] as UtilsInterface.ValueLabel[],
                valueChange: (value: any)=> {
                  console.log('model', this.model);
                  this.model.Visibility = value;
                }
              }
            }
          ]
        },
      },
      {
        wrappers: ['panel-div'],
        props: {
          label: 'p-3'
        },
        fieldGroup: [
          {
            key: 'Content',
            type: 'editor-simple',
            props: {
              placeholder: "Que voulez-vous publier aujourd'hui ?*",
              required: true,
              minLength: 3,
              className: ['h-150'],
              valueChange: (value: any)=> {

              }
            }
          },
        ]
      }
    ];
  }


  ngOnInit(): void {}

  addPicture(): void {
    const ref = this.modalService.open(DialogAddPicturesNoTypeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une image`,
        size: DialogSizeEnum.small,
        coverTitle: "Téléchargez une photo de couverture",
        imgSize: "Largeur minimum de 1280 X 720",
        fileType: "single",
        maxFiles: 1,
        btnLabel: "Soumettre"
      },
    });
  }

  addVideo() {
    const ref = this.modalService.open(DialogAddVideoNoTypeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une vidéo`,
        size: DialogSizeEnum.small,
        coverTitle: "Téléchargez une video de présentation",
        imgSize: "La video doit être de très bonne qualité",
        fileType: "multiple",
        maxFiles: 11,
        btnLabel: "Soumettre"
      },
    });
  }

  addDocument() {
    const ref = this.modalService.open(DialogAddDocumentNoTypeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une document`,
        coverTitle: "Téléchargez les fichiers",
        imgSize: "La taille maximal d'un fichier doit être de 25Mo",
        size: DialogSizeEnum.small,
        fileType: "multiple",
        maxFiles: 8,
        btnLabel: "Soumettre"
      },
    });
  }

  addVoice() {
    const ref = this.modalService.open(DialogAddVoiceNoTypeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un audio`,
        size: DialogSizeEnum.small,
        maxFiles: 10,
        fileType: "multiple",
        btnLabel: "Soumettre",
        downloadLabel: "Télécharger un fichier audio",
        recordLabel: "Enregistrez un message"
      },
    });
  }

  close() {
    this.ref.close(null);
  }

  removeFile(index: any) {
    this.imageList.splice(index, 1);
  }

  link(url: string) {
    window.open(url, '_blank');
  }

  showImages() {
    this.imagesList = !this.imagesList;
  }
  showVideos() {
    this.videoList = !this.videoList;
  }
  showLinks() {
    this.linkList = !this.linkList;
  }
  showDocuments() {
    this.docList = !this.docList;
  }
  showVoices() {
    this.voiceList = !this.voiceList;
  }


  submit(){
    console.log('this.form.value: ', this.model);
    if(this.form.valid){
      alert('hello world');
    }
  }
}
