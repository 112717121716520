<div
  class="datnek-ds-card user-box card-user-box"
  [class.chat]="onChat === true"
  *ngIf="!isLoading"
>
  <div class="header">
    <img src="/assets/Img/bg/bg-placeholder.svg" alt="" />
    <datnek-ds-button-icon
      [Icon]="iconEnum.outlinepencil"
      [type]="colorEnum.secondary"
      [round]="true"
      [fade]="true"
      [size]="sizeEnum.small"
      *ngIf="status === 'group'"
      (datnekdsClickOrTouch)="editPicture()"
    ></datnek-ds-button-icon>
  </div>
  <div class="content text-center">
    <div class="avatar">
      <datnek-ds-button-icon
        [Icon]="iconEnum.outlinepencil"
        [type]="colorEnum.secondary"
        [round]="true"
        [fade]="true"
        [size]="sizeEnum.small"
        *ngIf="status === 'group'"
        (datnekdsClickOrTouch)="editPicture()"
      ></datnek-ds-button-icon>
      <datnek-ds-avatar
        [size]="sizeEnum.large"
        [type]="status"
        [imgSrc]="imgSrc"
        *ngIf="onChat === false"
      ></datnek-ds-avatar>
      <datnek-ds-avatar
        [size]="sizeEnum.medium"
        [type]="status"
        [imgSrc]="imgSrc"
        *ngIf="onChat === true"
      ></datnek-ds-avatar>
    </div>
    <div
      class="awards mt-3 d-flex justify-content-center align-items-center"
      *ngIf="allStats === true"
    >
      <img src="/assets/Img/icons/badges/star.svg" alt="" />
      <img src="/assets/Img/icons/badges/star.svg" alt="" />
      <img src="/assets/Img/icons/badges/star.svg" alt="" />
      <img src="/assets/Img/icons/badges/star.svg" alt="" />
      <span>+ 4</span>
    </div>
    <div class="infos">
      <div class="display-1">{{ name }}</div>
      <datnek-ds-link
        [label]="'@'+userName"
        *ngIf="onChat === false && status !== 'group'"
      ></datnek-ds-link>
      <p *ngIf="onChat === false && status !== 'group'">{{ resumeTitle }}</p>
      <div
        class="d-flex align-items-center justify-content-center mt-1"
        *ngIf="status === 'group'"
      >
        <img
          style="width: 12px"
          src="/assets/icons/src/outline/globe.svg"
          alt=""
        />
        <span class="mr-2">Groupe Public</span>
        <span>.</span>
        <span class="members ml-2">92 membres</span>
      </div>
    </div>

    <div
      class="competences"
      [class.open]="readMore"
      *ngIf="openForJob === true && onChat === false"
    >
      <!--<span class="" *ngFor="let item of competences" style="color: #b9b9b9">
        {{ readMore ? item.name : (item.name | slice: 0:50) }}
      </span>-->
      <span class="" *ngFor="let item of competences" style="color: #b9b9b9">
        {{ item.name }}
      </span>
      <div class="more" (datnekdsClickOrTouch)="toggleReadMore()">
        <datnek-ds-svg-icon
          [icon]="iconEnum.outlinearrowDown"
          *ngIf="!readMore"
        ></datnek-ds-svg-icon>
        <datnek-ds-svg-icon
          [icon]="iconEnum.outlinearrowUp"
          *ngIf="readMore"
        ></datnek-ds-svg-icon>
      </div>
    </div>

    <!--<datnek-ds-link
      href="javascript:;"
      label="Voir plus"
      *ngIf="!readMore && openForJob === true && onChat === false"
      (datnekdsClickOrTouch)="readMore = true"
      class="d-block m-auto p-3"
    ></datnek-ds-link>
    <datnek-ds-link
      href="javascript:;"
      label="Voir moins"
      *ngIf="readMore"
      (datnekdsClickOrTouch)="
        readMore = false && openForJob === true && onChat === false
      "
      class="d-block m-auto p-3"
    ></datnek-ds-link>-->

    <div class="stats" *ngIf="onChat === false && status !== 'group'">
      <div class="datas d-flex justify-content-around pb-3 border-bottom">
        <div class="left text-center pointer" *ngIf="allStats === true">
          <div class="display-1">10</div>
          <span class="display-7">Années d'expérience</span>
        </div>
        <div
          class="center text-center pointer"
          (datnekdsClickOrTouch)="certifications()"
        >
          <div class="display-1">2</div>
          <span class="display-7">Certifications</span>
        </div>
        <div
          class="right text-center pointer"
          (datnekdsClickOrTouch)="recommendations()"
        >
          <div class="display-1">134</div>
          <span class="display-7">Recommanda...</span>
        </div>
      </div>
      <div class="datas d-flex justify-content-around pt-3">
        <div
          class="left text-center pointer"
          (datnekdsClickOrTouch)="goToFollowingPersons()"
        >
          <div class="display-1">6,664</div>
          <span class="display-7">Following</span>
        </div>
        <div
          class="right text-center pointer"
          (datnekdsClickOrTouch)="goToMyFollowers()"
        >
          <div class="display-1">19,924</div>
          <span class="display-7">Followers</span>
        </div>
      </div>
    </div>

    <div
      class="management ng-star-inserted text-left pt-3"
      *ngIf="status === 'group'"
    >
      <div class="display-5 px-3">Groupe géré par</div>
      <nk-shared-suggestion-list
        [status]="avatarEnum.person"
        [userData]="dataUsersChat"
      ></nk-shared-suggestion-list>
    </div>

    <ng-container
      *ngIf="openForJob === false && onChat === false && status !== 'group'"
    >
      <div class="footer d-flex justify-content-center text-center">
        <datnek-ds-button
          label="Voir le profil"
          *ngIf="status === 'person'"
          (datnekdsClickOrTouch)="profile()"
          [type]="colorEnum.link"
          [size]="sizeEnum.medium"
        >
        </datnek-ds-button>
        <datnek-ds-button
          label="Voir le profil"
          *ngIf="status === 'society'"
          (datnekdsClickOrTouch)="company()"
          [type]="colorEnum.link"
          [size]="sizeEnum.medium"
        >
        </datnek-ds-button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="openForJob === true && onChat === false && status !== 'group'"
    >
      <div
        class="
          footer
          d-flex
          text-center
          align-items-center
          justify-content-around
        "
      >
        <datnek-ds-link
          href="javascript:;"
          label="Voir le profil"
          (datnekdsClickOrTouch)="profile()"
        ></datnek-ds-link>
        <datnek-ds-link
          href="javascript:;"
          label="Voir les feeds"
          (datnekdsClickOrTouch)="goToNetwork($event)"
        ></datnek-ds-link>
      </div>
    </ng-container>

    <ng-container *ngIf="status === 'group'">
      <div
        class="
          challenge-footer
          align-items-center
          d-flex
          justify-content-between
        "
      >
        <datnek-ds-button
          *ngIf="restricted === false"
          label="Inviter"
          [size]="sizeEnum.large"
          [block]="true"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="inviteMember()"
        ></datnek-ds-button>

        <div class="share-post" *ngIf="restricted === false">
          <div (datnekdsClickOrTouch)="openDropdown(share, target1)" #target1>
            <datnek-ds-button
              [label]="'Partager'"
              [size]="sizeEnum.large"
              [block]="true"
              [type]="colorEnum.secondary"
            ></datnek-ds-button>
          </div>
        </div>
        <datnek-ds-button
          *ngIf="restricted === true"
          [leftIcon]="iconEnum.outlineWhiteuserGroup"
          [type]="colorEnum.primary"
          [block]="true"
          [size]="sizeEnum.large"
          label="Rejoindre le groupe"
          (datnekdsClickOrTouch)="adhesionDemand()"
        ></datnek-ds-button>
      </div>
    </ng-container>
  </div>
</div>

<datnek-skeleton-card-user-box
  *ngIf="isLoading && onChat === false"
></datnek-skeleton-card-user-box>

<datnek-skeleton-chat-card-user-box
  *ngIf="isLoading && onChat === true"
></datnek-skeleton-chat-card-user-box>

<!--NOTIFS MESSAGES-->
<ng-template #cp let-close="close">
  Le lien a été copié dans le presse-papier
</ng-template>
<ng-template #sh let-close="close">
  Le groupe a été partagé sur votre fil d'actualité
</ng-template>

<!-- DROPDOWNS -->
<ng-template #share let-close="close">
  <div class="datnek-ds-card dropdown-card">
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinereplyRight"
      label="Partager le groupe tel quel"
      (datnekdsClickOrTouch)="showToast(sh); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.pencilAlt"
      label="Partager avec modification"
      (datnekdsClickOrTouch)="sharePostWithEdit(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinechatAlt"
      label="Envoyer dans le chat"
      (datnekdsClickOrTouch)="shareOnChatbox(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlineuserGroup"
      label="partager dans un groupe"
      (datnekdsClickOrTouch)="postInGroupOrUser(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlineuser"
      label="partager sur le profil d'un contact"
      (datnekdsClickOrTouch)="postInUserProfile(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinelink"
      label="Copier le lien d'invitation"
      (datnekdsClickOrTouch)="showToast(cp); close()"
    ></datnek-ds-icon-submenu>
  </div>
</ng-template>
