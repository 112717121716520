<main class="content events">
  <div class="container p-0">
    <div class="datnek-ds-card px-3">
      <div class="row g-0">
        <div class="d-none d-md-block col-md-4 col-lg-3 col-xl-3 px-4 px-md-3">
          <span
            class="display-5 title-menu text-uppercase py-3 d-flex"
            *ngIf="!isLoading"
            >Application</span
          >
          <div class="p-3 skeleton" *ngIf="isLoading">
            <div class="line h15 w-50"></div>
          </div>
          <div class="d-none d-md-block">
            <del-shared-menu-sidebar [isMessages]="true"></del-shared-menu-sidebar>
          </div>

          <!-- LES EVENTS -->
          <span
            class="display-5 title-menu model2 text-uppercase py-3 d-flex"
            *ngIf="!isLoading"
            >Evènements
          </span>
          <div class="p-3 skeleton" *ngIf="isLoading">
            <div class="line h15 w-50"></div>
          </div>
          <div class="p-3 skeleton d-flex gap-3" *ngIf="isLoading">
            <div class="line h15 w-100"></div>
            <div class="line h15 w-100"></div>
          </div>
          <div class="sidebar-users" *ngIf="!isLoading">
            <p-tabView>
              <p-tabPanel header="Vos évènement">
                <div class="sidebar-event">
                  <nk-shared-events-list
                    [EventData]="EventData"
                  ></nk-shared-events-list>
                </div>
              </p-tabPanel>
              <p-tabPanel header="A suivre">
                <div class="sidebar-event">
                  <nk-shared-events-list></nk-shared-events-list>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
          <ng-container *ngIf="isLoading">
            <datnek-skeleton-user-chat-list
              *ngFor="let in of counter(5); let i = index"
            ></datnek-skeleton-user-chat-list>
          </ng-container>

          <!--MEDIAS FILES-->
          <div class="sidebar-right-files d-lg-none">
            <p-tabView>
              <p-tabPanel header="Images">
                <div class="sidebar-media-chat pt-3">
                  <datnek-ds-image-gallery
                    [isList]="true"
                  ></datnek-ds-image-gallery>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Vidéos">
                <div class="sidebar-media-chat pt-3">
                  <datnek-ds-video-gallery
                    [galleryVideoItems]="videoItems"
                  ></datnek-ds-video-gallery>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Fichiers">
                <div class="sidebar-media-chat pt-3">
                  <datnek-ds-document-gallery
                    [styleDoc]="displayEnum.inline"
                  ></datnek-ds-document-gallery>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>

        <div
          class="col-12 col-md-8 px-0"
          [ngClass]="
            rightSidebar === true ? 'col-lg-6 col-xl-6' : 'col-lg-9 col-xl-9'
          "
        >
          <div
            class="header d-flex justify-content-between"
            [ngClass]="rightSidebar === true ? 'px-md-0 px-3' : 'px-3'"
            *ngIf="!isLoading"
          >
            <div class="title d-flex align-items-center">
              <datnek-ds-svg-icon
                [icon]="iconEnum.outlinecalendar"
                class="mr-1"
              ></datnek-ds-svg-icon>
              <div class="display-1">Evènements</div>
            </div>
            <div class="actions d-flex align-items-center justify-content-end">
              <datnek-ds-button
                label="Nouvel Evènement"
                [leftIcon]="iconEnum.outlineWhiteplus"
                [type]="colorEnum.primary"
                [size]="sizeEnum.large"
                class="mr-0 mr-md-3 d-none d-md-block"
                (datnekdsClickOrTouch)="addEvent()"
              ></datnek-ds-button>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlineWhiteplus"
                [type]="colorEnum.primary"
                [size]="sizeEnum.medium"
                (datnekdsClickOrTouch)="addEvent()"
                class="d-md-none mr-3"
              ></datnek-ds-button-icon>
              <!-- <datnek-ds-button-icon
                [Icon]="iconEnum.outlineviewList"
                [type]="colorEnum.secondary"
                [size]="sizeEnum.medium"
                pTooltip="Vue en liste"
                tooltipPosition="top"
                class="mr-3"
                [ngClass]="router.url === '/events/list-view' ? 'active' : ''"
                (datnekdsClickOrTouch)="viewListCalendar()"
              ></datnek-ds-button-icon>
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlineviewGrid"
                [type]="colorEnum.secondary"
                [size]="sizeEnum.medium"
                pTooltip="Vue en grille"
                tooltipPosition="top"
                class="mr-0 mr-md-3"
                [ngClass]="router.url === '/events/calendar-view' ? 'active' : ''"
                (datnekdsClickOrTouch)="viewGridCalendar()"
              ></datnek-ds-button-icon> -->
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinemenu"
                [type]="colorEnum.unstyled"
                (datnekdsClickOrTouch)="showRightSidebar()"
                class="d-none d-lg-block"
              ></datnek-ds-button-icon>
            </div>
          </div>
          <div
            class="
              skeleton
              suggestion-header
              d-flex
              align-items-center
              justify-content-between
            "
            *ngIf="isLoading"
          >
            <div class="w-75 d-flex align-items-center">
              <div class="skeleton-left mr-3">
                <div class="circle medium-c"></div>
              </div>
              <div class="skeleton-right w-25 d-none d-md-block">
                <div class="line w-100 h15"></div>
              </div>
              <div class="skeleton-right w-75 d-md-block">
                <div class="line w-100 h15"></div>
              </div>
            </div>
            <div class="gap-3 w-50 justify-content-end d-none d-md-flex">
              <div class="button w-50 medium-h"></div>
              <div class="button w-75 medium-h"></div>
            </div>
            <div class="d-flex gap-3 w-50 justify-content-end d-md-none">
              <div class="circle medium-c"></div>
            </div>
          </div>
          <div class="body-content">
            <!--No Content-->
            <datnek-ds-no-content
              titleMsg="Aucun Evènement"
              contentDesc="Vous n’avez encore créé aucun évènement pour l’instant. Cliquez sur le bouton de création de nouveau évènement plus haut"
              imgSrc="assets/icons/src/calendar_1.svg"
            ></datnek-ds-no-content>
            <router-outlet></router-outlet>
            
            <!-- <del-shared-calendar-view></del-shared-calendar-view>  -->
            <!-- <network-calendar-view></network-calendar-view>  -->

            
          </div>
        </div>

        <div
          class="d-none d-lg-block col-lg-3 col-xl-3 pr-4 pt-3"
          *ngIf="rightSidebar === true"
        >
          <nk-shared-card-user-box
            [onChat]="true"
            name="Bassahak Jean Marc"
            resumeTitle="Web Designer senior"
            class="pt-3"
            [status]="avatarEnum.person"
          ></nk-shared-card-user-box>
          <div class="p-3 skeleton d-flex gap-3" *ngIf="isLoading">
            <div class="line h15 w-100"></div>
            <div class="line h15 w-100"></div>
            <div class="line h15 w-100"></div>
          </div>

          <div class="sidebar-right-files" *ngIf="!isLoading">
            <p-tabView>
              <p-tabPanel header="Images">
                <div class="sidebar-media-chat pt-3">
                  <datnek-ds-image-gallery
                    [isList]="true"
                  ></datnek-ds-image-gallery>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Vidéos">
                <div class="sidebar-media-chat pt-3">
                  <nk-shared-video-gallery [grid]="'style2'"></nk-shared-video-gallery>
                  <!-- <datnek-ds-video-gallery
                    [galleryVideoItems]="videoItems"
                  ></datnek-ds-video-gallery> -->
                </div>
              </p-tabPanel>
              <p-tabPanel header="Fichiers">
                <div class="sidebar-media-chat pt-3">
                  <datnek-ds-document-gallery
                    [styleDoc]="displayEnum.inline"
                  ></datnek-ds-document-gallery>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>

          <datnek-skeleton-multimedia-images
            *ngIf="isLoading"
          ></datnek-skeleton-multimedia-images>
        </div>
      </div>
    </div>
  </div>
</main>
