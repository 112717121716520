import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PopupInterface,
  AvatarTypeEnum,
  ControlTypeColorEnum,
  IconEnum,
  ControlSizeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  time: string;
  name: string;
  writer: string;
  message: string;
}

@Component({
  selector: 'nk-shared-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;

  menuLeft = false;
  status = false;

  dataUsersChat: PopupInterface[] = [
    {
      id: '1',
      name: 'Danick takam ouhakh kjahkjehkjehjk ekajh kjehkjeh kjaehazkjehaz kjh',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      writer: 'Vous',
      message: 'Je vous en prie monsieur. vous poriez me demander votre numéro ?',
    },
    {
      id: '2',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jean-Marc',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '3',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Arno yankam',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '4',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Kevin Sokodjou',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '5',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jordan Wildes',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '6',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jospin Chimi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '7',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Lionel Messi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '8',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Christiane',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
  ];
  constructor(public router: Router) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  showStatus() {
    this.status = !this.status;
  }

  chat() {
    this.router.navigate(['/chat/home']);
  }

  goToChatMessage(e: any) {
    e.preventDefault();
    this.router.navigate(['/chat/home']);
  }

  home() {
    this.router.navigate(['/']);
  }

  enterprise() {
    this.router.navigate(['/enterprise/list-enterprises']);
  }

  groups() {
    this.router.navigate(['/groups/home-group']);
  }

  showmenuLeft() {
    this.menuLeft = !this.menuLeft;
    console.log('MENU LEFT : ', this.menuLeft);
  }

  link($url = '') {
    return $url;
  }
}
