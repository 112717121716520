import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  IconEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-suggestion-single',
  templateUrl: './suggestion-single.component.html',
  styleUrls: ['./suggestion-single.component.scss'],
})
export class SuggestionSingleComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;

  @Input()
  data: any;

  @Input()
  status: AvatarTypeEnum = this.avatarEnum.person;

  @Input()
  isInline = false;

  @Input()
  avatar = true;

  @ViewChild('videoPlayer')
  videoplayer!: ElementRef<HTMLVideoElement>;

  followed = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log('VIDEO PLAYER : ' + this.videoplayer);
    setTimeout(() => (this.isLoading = false), 2500);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  startPreview(): void {
    this.videoplayer.nativeElement.muted = true;
    this.videoplayer.nativeElement.currentTime = 1;
    this.videoplayer.nativeElement.playbackRate = 0.5;
    this.videoplayer.nativeElement.play();
  }

  stopPreview(): void {
    this.videoplayer.nativeElement.currentTime = 0;
    this.videoplayer.nativeElement.playbackRate = 1;
    this.videoplayer.nativeElement.pause();
  }

  goToPitch(e: any): void {
    this.router.navigate(['/request/suggestion-pitch']);
  }

  goToSociety(): void {
    this.router.navigate(['/enterprise']);
  }

  onFollow(): void {
    this.followed = !this.followed;
  }
}
