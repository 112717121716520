import { Router } from '@angular/router';
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';

import {
  AvatarTypeEnum,
  ChallengeStateTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  LikeBoxPositionEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
  DialogRedirectToComponent,
} from 'datnek-ds';
import { UUID } from 'angular2-uuid';
import { Overlay } from '@angular/cdk/overlay';

import {
  DialogAddSignalizationComponent,
  DialogSharePostWithEditComponent,
  DialogDeleteGroupComponent,
  DialogAddCategoryComponent,
} from '../../../../dialogs';

interface ImagesItems {
  imageSrc: string;
  imageAlt: string;
  title: string;
  date: string;
}

interface VideosItems {
  videoSrc: string;
  title: string;
  date: string;
}

interface DocumentItem {
  documentSrc: string;
  docKeyshot: string;
  docName: string;
  docDescription: string;
}

interface SubmenuItems {
  label: string;
  labeldesc: string;
  Icon: string;
}

interface ChallengesItems {
  id: string;
  imageSrc: string;
  title: string;
  subtitle: string;
  description: string;
  name_organizer: string;
  date_debut: string;
  date_fin: string;
  status: AvatarTypeEnum;
  progress: ChallengeStateTypeEnum;
}

interface CommentsItems {
  imageSrc: string;
  name: string;
  since: string;
  desc: string;
  readMoreComment: boolean;
  multimedia: string;
}

interface stateItems {
  id: number;
  name: string;
}

@Component({
  selector: 'del-shared-card-publication-box',
  templateUrl: './card-publication-box.component.html',
  styleUrls: ['./card-publication-box.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({ transform: 'scale(0.5)' }),
        animate('150ms', style({ transform: 'scale(1)' })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0.8 })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
    trigger('moreSuggestion', [
      state(
        'false',
        style({
          opacity: 0,
        })
      ),
      state(
        'true',
        style({
          opacity: 1,
        })
      ),
      transition('* => *', animate(150)),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPublicationBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  positionEnum = LikeBoxPositionEnum;

  challengeEnum = ChallengeStateTypeEnum;

  showMore = false;

  galleryData: ImagesItems[] = [];

  /**
   *
   */
  dataSubmenu: SubmenuItems[] = [
    {
      label: 'Enregistrer',
      labeldesc: 'Enregistrer pour plus tard',
      Icon: 'bookmark',
    },
    {
      label: 'Copiez le lien de ce post',
      labeldesc:
        'Vous pourrez le partager ou y accéder en copiant dans le navigateur',
      Icon: 'link',
    },
    {
      label: 'Ne plus suivre {{John Doe}}',
      labeldesc:
        'Restez en relation avec {{John Doe}} mais ne plus suivre ses actualites',
      Icon: 'x-circle',
    },
    {
      label: 'Ne plus voir ce post',
      labeldesc: 'Supprimez ce post de votre mur',
      Icon: 'eye-off',
    },
    {
      label: 'Signaler ce post',
      labeldesc: 'Ce post contient du contenu offansant ou compte piraté',
      Icon: 'flag',
    },
  ];

  dataComments: CommentsItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/comment-4.png',
      name: 'Ulrich Guagang 2',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat.`,
      readMoreComment: false,
      multimedia: '',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/comment-2.jpg',
      name: 'Ebenyst',
      since: '3m',
      desc: `Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada.`,
      readMoreComment: false,
      multimedia: 'images',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/comment-3.jpg',
      name: 'Marie Alavert',
      since: '3m',
      desc: `Malesuada !!!.`,
      readMoreComment: false,
      multimedia: 'videos',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-1.jpg',
      name: 'Damien Frost',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat. <strong>Nulla porttitor accumsan tincidunt</strong>. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada.`,
      readMoreComment: false,
      multimedia: 'link',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-1.jpg',
      name: 'Damien Frost',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat. Nulla porttitor accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada.`,
      readMoreComment: false,
      multimedia: 'voice',
    },
  ];

  dataSubComments: CommentsItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/comment-4.png',
      name: 'Ulrich Guagang 2',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat.`,
      readMoreComment: false,
      multimedia: '',
    },
  ];

  challengeData: ChallengesItems[] = [
    {
      id: UUID.UUID(),
      imageSrc: 'assets/Img/challenge-placeholder-bg.png',
      title: 'Angular component sprint winning',
      subtitle:
        'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus',
      description:
        'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Sed porttitor lectus nibh.',
      name_organizer: 'Balnero Allan',
      date_debut: '15 janvier 2022',
      date_fin: '12 févirer 2023',
      status: this.avatarEnum.person,
      progress: ChallengeStateTypeEnum.future,
    },
  ];

  addComment = false;

  savedMessage = '#';
  showCopy = false;
  showCancel = false;
  showSaved = false;
  showNoFollow = false;

  eventTitle = "Titre de l'évènement à cet endroit";
  groupName = 'Nom du groupe ici';
  articleTitle = "Titre de l'publication";
  articleDesc = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur velit accusamus nobis atque sint alias quia dolore mollitia architecto`;

  /**
   * Défini si la publication est une publication partagée ou non. Si elle est partagée,
   * alors elle s'affiche dans une autre publication et certains elements disparaissent: le bouton des options et deux des likes et des partages
   *
   * @booblean
   */
  @Input()
  shared = false;

  likes = false;

  @Input() videoData: VideosItems[] = [];
  @Input() documentData: DocumentItem[] = [];
  @Input() showCount = true;

  // Vérifie si l'utilisateur est Public. Certaines fonctionnalités ne seront plus accessibles
  @Input() isPublic = false;
  
  @Input() totalImg!: (__number: number) => number;

  @Input() publicationType:
    | 'images'
    | 'video'
    | 'document'
    | 'post'
    | 'challenge'
    | 'article'
    | 'event'
    | 'group' = 'images';

  galleryClass = '';

  @Input() set init(value: ImagesItems[]) {
    this.galleryClass =
      value.length === 1
        ? 'one-img'
        : value.length === 2
        ? 'two-img'
        : value.length === 3
        ? 'three-img'
        : value.length === 4
        ? 'four-img'
        : value.length === 5
        ? 'five-img'
        : 'img-more';
    this.galleryData = [...value];
  }

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  haveReaction = [
    'John Doe',
    'Yankep Arno',
    'Danick Takam',
    'Escalator',
    'Predatro Rhymes',
    'Siblings',
  ];

  rest = this.haveReaction.slice(5, -1);

  readMore = false;

  @Input()
  longText = `Nulla quis lorem ut libero malesuada feugiat. Nulla porttitor accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus suscipit tortor eget felis porttitor volutpat. Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem ut libero malesuada feugiat.`;

  numberImg = this.galleryData.slice(0, 5);
  previewImage = false;
  showMask = false;
  currentLightboxImage: ImagesItems = this.galleryData[0];
  currentIndex = 0;
  controls = true;

  totalImageCount = 0;

  isLoading = false;

  follow = false;
  autohide = true;
  showListComments = false;

  label = 'Les plus récents';

  subMenu: stateItems[] = [
    {
      id: 0,
      name: 'Les plus pertinents',
    },
    {
      id: 1,
      name: 'Les plus récents',
    },
  ];

  constructor(
    private router: Router,
    private overlay: Overlay,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
    this.totalImageCount = this.galleryData.length;
    //this.numberImage();
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  addSignalization() {
    const ref = this.modalService.open(DialogAddSignalizationComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Veuillez sélectionner une raison`,
        size: DialogSizeEnum.small,
      },
    });
  }

  sharePostWithEdit() {
    const ref = this.modalService.open(DialogSharePostWithEditComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau post`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  deletePost() {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer le post`,
        size: DialogSizeEnum.small,
      },
    });
  }

  redirectToJobs() {
    const ref = this.modalService.open(DialogRedirectToComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Redirection`,
        size: DialogSizeEnum.small,
      },
    });
  }

  redirectToCV() {
    const ref = this.modalService.open(DialogRedirectToComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Redirection`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addToCategory() {
    const ref = this.modalService.open(DialogAddCategoryComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter dans une catégorie`,
        size: DialogSizeEnum.small,
      },
    });
  }

  showLikes(): void {
    this.likes = !this.likes;
  }

  isFollowed(): void {
    this.follow = !this.follow;
  }

  openPreviewImg(index: number): void {
    this.showMask = true;
    this.previewImage = true;
    this.currentIndex = index;
    this.currentLightboxImage = this.galleryData[index];
  }

  onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.showMask = false;
    }
  }

  onClosePreview() {
    this.previewImage = false;
    this.showMask = false;
  }

  prev(): void {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.galleryData.length - 1;
    }
    this.currentLightboxImage = this.galleryData[this.currentIndex];
  }

  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.galleryData.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLightboxImage = this.galleryData[this.currentIndex];
  }

  onAddComment() {
    this.addComment = !this.addComment;
  }

  onShowMore() {
    this.showMore = !this.showMore;
  }

  openArticle(e: any) {
    this.router.navigate(['/single-publication']);
  }

  openGroup(e: any) {
    this.router.navigate(['/groups/single-group/group-discussion']);
  }

  openEvent(e: any) {
    this.router.navigate(['/events/single-events']);
  }

  clickListener(e: any) {
    if (e.keyCode === 13) {
      console.log(e);
      this.showListComments = true;
    }
  }

  onShowListComments() {
    this.showListComments = !this.showListComments;
  }

  public get classes(): string[] {
    const shared = this.shared === true ? 'shared-publication' : '';
    return [
      shared
    ];
  }
}
