import {Router} from '@angular/router';
import {Component, Inject, OnInit} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';
import {
  DialogService,
  DialogSizeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogAddPicturesComponent,
  DialogTypeEnum, BaseComponent
} from 'datnek-ds';

import {
  DialogAddCalendarComponent,
  DialogAddChallengeComponent, DialogAddGroupComponent,
  DialogNewContentComponent,
} from '../../../../dialogs';
import {AuthService} from "datnek-sso";
import { Environment } from 'datnek-core';

@Component({
  selector: 'del-shared-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent extends BaseComponent {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(
    private router: Router,
    private overlay: Overlay,
    private authService: AuthService,
    private modalService: DialogService,
    @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,
  ) {
    super();
  }

 

  newContentDialog() {
    const ref = this.modalService.open(DialogNewContentComponent, {
     dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créez un nouveau contenu`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addPicture() {
    const ref = this.modalService.open(DialogAddPicturesComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter une image`,
        size: DialogSizeEnum.small,
      },
    });
  }

  addCalendar() {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un évènemnent`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addChallenge() {
    const ref = this.modalService.open(DialogAddChallengeComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau challenge`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addNewGroupDialog() {
    const ref = this.modalService.open(DialogAddGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Créer un nouveau groupe`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  addArticlePage(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/add-publication`;
  }

  newAds(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/delenscio-ads`;
  }

  goToEvent(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/events/calendar-view `;
  }
  goToPeaple(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/request/friends`;
  }
  goToContacts(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/contacts`;
  }

  goToGroups(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/groups/home-group`;
  }
  goToChallenges(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/challenge/list`;
  }

  onLogout(e: any): void {
    e.preventDefault();
    e.stopPropagation();
   const sub =  this.authService.logout('/').subscribe(res => {
      if (res){
        window.location.reload();
      }
    });
    this.subscribe.add(sub);
    // this.router.navigate(['/register']);
  }
}
