<!-- SECTION SOLUTIONS -->

<section class="solutions">
    <div class="solutions-top">
      <div class="container">
        <div class="row">
          <div class="col-12 px-0 px-md-3">
            <div class="solutions-header d-flex justify-content-center">
              <div class="intro text-center mb-4">
                <h2
                  class="f-800 mb-3 animate__animated animate__fadeInUp animate__fast"
                >
                  Onze diensten
                </h2>
                <p
                  class="txt-20 animate__animated animate__fadeInUp animate__fast"
                >
                  Ontdek de diensten die wij u aanbieden
                </p>
              </div>
            </div>
            <div class="solutions-content d-flex justify-content-center">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li
                  class="nav-item animate__animated animate__bounceIn animate__delay-1s"
                  role="presentation"
                  *ngFor="let titles of sectionTitles; let i = index"
                >
                  <a
                    class="nav-link"
                    id="{{titles.id}}"
                    data-toggle="tab"
                    href="#{{titles.id}}"
                    role="tab"
                    aria-controls="titles.id"
                    aria-selected="true"
                    [ngClass]="{'active' : selectedIndex === i}"
                    (datnekdsClickOrTouch)="selectItem(i)"
                  >
                    <img
                      [src]="titles.icon"
                      alt=""
                    />
                    <span>{{ titles.title }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="solutions-bottom">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show"
                id="reseau-pro"
                role="tabpanel"
                aria-labelledby="reseau-pro-tab"
                *ngFor="let info of sectionDetails; let i=index"
                [ngClass]="{'active' : selectedIndex === i}"
                (datnekdsClickOrTouch)="selectItem(i)"
              >
                <div class="row no-gutters">
                  <div class="col-12 col-sm-12 col-lg-6 py-6 px-3">
                    <h2
                      class="section-content-title mb-4 f-800 animate__animated animate__fadeInUp animate__fast"
                    >
                      {{info.title}}
                    </h2>
                    <div [innerHTML]="info.description"></div>

                    <datnek-ds-button
                        label="Begin nu"
                        [size]="sizeEnum.large"
                        [type]="colorEnum.primary"
                    ></datnek-ds-button>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-6">
                    <div
                      class="img-solution animate__animated animate__fadeInUp animate__fast"
                    >
                      <img
                        [src]="info.imgSrc"
                        alt="{{info.title}}"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="grey"></div>
    </div>
  </section>
