import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';
import { AccordionComponent } from './accordion.component';

@Component({
  selector: 'del-shared-accordion',
  template: ` <ng-content></ng-content> `,
})
export class AccordionContentComponent implements AfterContentInit {
  @ContentChildren(AccordionComponent)
  groups!: QueryList<AccordionComponent>;

  ngAfterContentInit() {
    this.groups.toArray()[0].opened = false;
    this.groups.toArray().forEach((t) => {
      t.toggle.subscribe(() => {
        this.openGroup(t);
      });
    });
  }

  openGroup(group: any) {
    this.groups.toArray().forEach((t) => (t.opened = false));
    group.opened = true;
  }
}
