<main class="content single-event">
  <div class="container p-0">
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1">
        <div class="d-flex no-gutters gap-16">
          <div class="col-lg-8 col-12">
            <div class="datnek-ds-card px-3">
              <div class="row g-0">
                <div class="col-12 px-0">
                  <div
                    class="
                      header
                      d-flex
                      justify-content-between
                      align-items-center
                      px-3
                    "
                  >
                    <datnek-ds-button
                      label="Retour"
                      [leftIcon]="iconEnum.outlinearrowLeft"
                      [type]="colorEnum.secondary"
                      [size]="sizeEnum.large"
                      class="mr-3"
                      (datnekdsClickOrTouch)="back()"
                      *ngIf="!isLoading"
                    ></datnek-ds-button>

                    <div class="skeleton" *ngIf="isLoading">
                      <div class="line w-25 h-15"></div>
                    </div>
                  </div>
                  <div class="body-content">
                    <div class="item-content formation event network event-id users border-bottom p-0">
                      <h3 *ngIf="!isLoading" class="p-3 border-bottom display-1 events-users">
                        Liste des participants <span>(25 particpiants)</span>
                      </h3>


                        <div
                          class="users d-flex flex-column justify-content-between flex-wrap flex-md-nowrap align-items-start p-3 border-bottom"
                          *ngFor="let data of userData"
                        >
                          <div class="user-box d-flex align-items-center gap-8">
                            <datnek-ds-avatar
                              [size]="sizeEnum.medium1"
                              imgSrc="{{ data.imageSrc }}"
                              [type]="avatarEnum.person"
                            ></datnek-ds-avatar>
                            <div class="d-flex flex-column w-100">
                              <div class="d-flex">
                                <div class="name display-2 d-flex w-100 mr-2">
                                  <span class="clip ellipsis  d-inline-block">{{ data.name }}</span> 
                                  <img
                                    class="ml-2"
                                    src="assets/icons/src/check-color.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <span class="clip ellipsis  d-inline-block">{{ data.sector }}</span>
                            </div>
                          </div>
                          <div class="actions d-flex align-items-center gap-8">
                            <button
                              class="contact d-flex align-items-center gap-8"
                              (datnekdsClickOrTouch)="registerPopup($event)"
                            >
                              <img src="assets/icons/home/events/contact-user-event.svg" alt="" />
                              Contact
                            </button>
                            <button
                              class="confirm d-flex align-items-center gap-8"
                              *ngIf="confirmed"
                              (datnekdsClickOrTouch)="onConfirm()"
                            >
                              <img src="assets/icons/home/events/check-user-event.svg" alt="" />
                              Confirmé
                            </button>
                            <button
                              class="confirm-wait d-flex align-items-center gap-8"
                              *ngIf="!confirmed"
                              (datnekdsClickOrTouch)="onConfirm()"
                            >
                              <img
                                src="assets/icons/home/events/check-waiting-user-event.svg"
                                alt=""
                              />
                              En attente de confirmation
                            </button>
                            <button
                              class="cancel d-flex align-items-center gap-8"
                              (datnekdsClickOrTouch)="deleteUser(delete, target2)"
                              #target2
                            >
                              <img src="assets/icons/home/events/cancel-user-event.svg" alt="" />
                              Annuler
                            </button>
                          </div>
                        </div>
                  

                      <ng-container *ngIf="showMore">
                        <div
                        class="users d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center p-3 border-bottom"
                        *ngFor="let data of userData"
                      >
                        <div class="user-box d-flex align-items-center gap-8">
                          <datnek-ds-avatar
                            [size]="sizeEnum.medium1"
                            imgSrc="{{ data.imageSrc }}"
                            [type]="avatarEnum.person"
                          ></datnek-ds-avatar>
                          <div class="d-flex flex-column">
                            <div class="d-flex">
                              <div class="name display-2 d-inline mr-2">
                                <span class="clip ellipsis">{{ data.name }}</span> 
                                <img
                                  class="badge ml-2"
                                  src="assets/icons/src/check-color.png"
                                  alt=""
                                />
                              </div>
                            </div>
                            <span class="clip ellipsis">{{ data.sector }}</span>
                          </div>
                        </div>
                        <div class="actions d-flex align-items-center gap-8">
                          <button
                            class="contact d-flex align-items-center gap-8"
                            (datnekdsClickOrTouch)="registerPopup($event)"
                          >
                            <img src="assets/icons/home/events/contact-user-event.svg" alt="" />
                            Contact
                          </button>
                          <button
                            class="confirm d-flex align-items-center gap-8"
                            *ngIf="confirmed"
                            (datnekdsClickOrTouch)="onConfirm()"
                          >
                            <img src="assets/icons/home/events/check-user-event.svg" alt="" />
                            Confirmé
                          </button>
                          <button
                            class="confirm-wait d-flex align-items-center gap-8"
                            *ngIf="!confirmed"
                            (datnekdsClickOrTouch)="onConfirm()"
                          >
                            <img
                              src="assets/icons/home/events/check-waiting-user-event.svg"
                              alt=""
                            />
                            En attente de confirmation
                          </button>
                          <button
                            class="cancel d-flex align-items-center gap-8"
                            (datnekdsClickOrTouch)="deleteUser(delete, target2)"
                            #target2
                          >
                            <img src="assets/icons/home/events/cancel-user-event.svg" alt="" />
                            Supprimer
                          </button>
                        </div>
                      </div>
                      </ng-container>
                      
                      
                    </div>
                    <div
                      class="footer py-2 d-flex justify-content-center align-items-center"
                    >
                      <datnek-ds-button
                      label="{{showMore === true ? 'Voir moins' : 'Voir plus'}}"
                      (datnekdsClickOrTouch)="onShowMore()"
                      [type]="colorEnum.link"
                        [size]="sizeEnum.medium"
                      >

                      </datnek-ds-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="medias">
              <div class="datnek-ds-card images mb-3">
                <div class="header d-flex align-items-center display-1 px-3">
                  Images de l'évènement
                </div>
                <div class="p-3">
                  <del-shared-image-gallery></del-shared-image-gallery>
                  <!-- <datnek-ds-image-gallery></datnek-ds-image-gallery> -->
                </div>
              </div>
              <div class="datnek-ds-card videos mb-3">
                <div class="header d-flex align-items-center display-1 px-3">
                  Vidéos de l'évènement
                </div>
                <div class="p-3">
                  <datnek-ds-video-gallery></datnek-ds-video-gallery>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<!-- MODALS -->
<ng-template #delete let-close="close">
  <div class="datnek-ds-dialog-body p-3">
    <div class="content-modal">
      <div class="form">Voulez-vous vraiment supprimer ce participant ?</div>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer gap-16">
    <datnek-ds-button
      [type]="colorEnum.secondary"
      [size]="sizeEnum.large"
      [label]="'Annuler'"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [type]="colorEnum.danger"
      [size]="sizeEnum.large"
      [label]="'Oui, supprimer'"
      (datnekdsClickOrTouch)="close(); showToast(deleted)"
    ></datnek-ds-button>
  </div>
</ng-template>

<!-- TOASTS -->
<ng-template #deleted let-close="close">
  <p class="mb-0">
    Le particpant a été définitivement supprimé.
    <!--<a class="ml-2">Voir</a>-->
  </p>
</ng-template>
