<div *ngFor="let challenge of challengeData">
  <div
    class="datnek-ds-card challenge-box card-challenge-box"
    *ngIf="!isLoading"
  >
    <div
      class="
        challenge-header
        d-flex
        justify-content-between
        align-items-center
        border-bottom
      "
    >
      <div class="left w-100 mr-3 d-flex align-items-center">
        <img
          src="/assets/icons/src/outline/color/competition-champion-color.svg"
          alt=""
          class="mr-3"
        />
        <div class="display-1 clip ellipsis nowrap head-title-with-icon">
          {{ challengeName }}
        </div>
      </div>

      <!--<datnek-ds-button-icon
        [Icon]="iconEnum.solidinboxclose"
        [type]="colorEnum.secondary"
        [size]="sizeEnum.medium"
        [round]="true"
        (datnekdsClickOrTouch)="onHide()"
      ></datnek-ds-button-icon>-->
    </div>

    <div class="challenge-content d-flex flex-column">
      <!--<countdown [config]="{ leftTime: 30 }"></countdown>-->
      <div class="img mb-3">
        <img src="{{ challenge.imageSrc }}" alt="" />
      </div>
      <div class="d-flex mb-3 justify-content-between align-items-center">
        <div class="timer mb-0" *ngIf="challengeEnum === 'future'">
          <datnek-ds-svg-icon [icon]="iconEnum.outlineclock"></datnek-ds-svg-icon>
          <span class="ml-2"
            >{{ days }} : {{ hours }} : {{ mins }} : {{ secs }}</span
          >
        </div>
        <div class="timer" *ngIf="challengeEnum === 'inProgress'">
          <datnek-ds-svg-icon [icon]="iconEnum.outlineclock"></datnek-ds-svg-icon>
          <span class="ml-2">En cours ...</span>
        </div>
        <div class="timer" *ngIf="challengeEnum === 'finished'">
          <datnek-ds-svg-icon [icon]="iconEnum.outlineclock"></datnek-ds-svg-icon>
          <span class="ml-2">Terminé</span>
        </div>
        <datnek-ds-button
          [label]="'Relancer'"
          [size]="sizeEnum.medium"
          [block]="true"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="editChallenge()"
          *ngIf="timer === false"
          class="mr-3"
        ></datnek-ds-button>
      </div>

      <p class="px-3">{{ challenge.description | truncatetext: 100 }}</p>
      <div
        class="d-flex justify-content-between align-items-center px-3 mb-3"
        style="gap: 16px"
        [ngClass]="
          isSidebar === true ? 'flex-wrap' : 'flex-wrap flex-md-nowrap'
        "
      >
        <div class="date">
          <span>Du {{ challenge.date_debut }} au {{ challenge.date_fin }}</span>
        </div>
        <datnek-ds-button
          label="En savoir plus"
          [type]="colorEnum.link"
          (datnekdsClickOrTouch)="goToChallenge()"
          class=""
          *ngIf="fullChallenges === true && acceptedChallenges === false"
        ></datnek-ds-button>
        <datnek-ds-button
          label="En savoir plus"
          [type]="colorEnum.link"
          (datnekdsClickOrTouch)="goToChallengeAccepted()"
          class=""
          *ngIf="acceptedChallenges === true && challengeEnum === 'future'"
        ></datnek-ds-button>
        <datnek-ds-button
          label="En savoir plus"
          [type]="colorEnum.link"
          (datnekdsClickOrTouch)="goToChallengeAcceptedInProgress()"
          class=""
          *ngIf="acceptedChallenges === true && challengeEnum === 'inProgress'"
        ></datnek-ds-button>
        <datnek-ds-button
          label="En savoir plus"
          [type]="colorEnum.link"
          (datnekdsClickOrTouch)="goToChallengeAcceptedFinished()"
          class=""
          *ngIf="acceptedChallenges === true && challengeEnum === 'finished'"
        ></datnek-ds-button>
      </div>
    </div>

    <div
      class="datnek-ds-card-footer d-flex align-items-center p-3"
      *ngIf="listChallenges && !acceptedChallenges"
    >
      <div class="icons" [ngClass]="{ 'm-auto w-50': !isSidebar }">
        <datnek-ds-button
          [label]="'Oui je souhaite participer'"
          [size]="sizeEnum.medium"
          [block]="true"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="confirmChallenge()"
          *ngIf="results === false"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Voir mes résultats'"
          [size]="sizeEnum.medium"
          [block]="true"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="resultsDetails($event)"
          *ngIf="results === true"
        ></datnek-ds-button>
      </div>
    </div>

    <div
      class="datnek-ds-card-footer d-flex align-items-center p-3"
      *ngIf="acceptedChallenges"
    >
      <div class="icons" [ngClass]="{ 'm-auto w-50': !isSidebar }">
        <datnek-ds-button
          [label]="'Accéder au challenge'"
          [size]="sizeEnum.medium"
          [block]="true"
          [type]="colorEnum.border"
          (datnekdsClickOrTouch)="accessToChallenge()"
          *ngIf="challengeEnum === 'inProgress'"
        ></datnek-ds-button>
        <datnek-ds-button
          [label]="'Voir mes résultats'"
          [size]="sizeEnum.medium"
          [block]="true"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="resultsDetails($event)"
          *ngIf="challengeEnum === 'finished'"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</div>

<datnek-skeleton-challenge-box
  *ngIf="isLoading"
></datnek-skeleton-challenge-box>

<!--NOTIFS MESSAGES-->
<ng-template #no let-close="close">
  Vous avez rejeté le challenge.
</ng-template>
