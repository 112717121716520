import { Component, OnInit } from '@angular/core';
import {
  DialogAddCalendarComponent,
  Event,
  EventsService,
} from '@del-shared';
import { DialogService, DialogTypeEnum, DialogSizeEnum, ControlSizeEnum, ControlTypeColorEnum, IconEnum, assetUrl } from 'datnek-ds';

@Component({
  selector: 'network-list-view',
  templateUrl: './list-view.component.html',
})
export class ListViewComponent implements OnInit {

  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  myEvent!: Event[];
  myEvent1: Event[] = [
    {
      Id: '1',
      CreateAt: new Date(),
      UpdateAt: new Date(),
      Title: 'Mon premier évènement !',
      SubTitle: 'Sous-titre de mon premier event',
      ReportOfTheMeeting: 'Aucun report',
      StartDate: '12 dec 2023',
      StartTime: { hours: 12, minutes: 34 },
      AddressId: 'Zoom',
      EventImage: assetUrl('Img/items/attachment_98927507.png'),
    },
    {
      Id: '2',
      CreateAt: new Date(),
      UpdateAt: new Date(),
      Title: 'Mon deuxième évènement !',
      SubTitle: 'Sous-titre de mon deuxième event',
      ReportOfTheMeeting: 'Aucun report',
      StartDate: '12 dec 2023',
      StartTime: { hours: 12, minutes: 34 },
      AddressId: 'Iron palace',
      EventImage: assetUrl('Img/items/1d29bdc244b2aa8503f3ca4a531f3fb4.webp'),
    },
  ];
  isLoading = true;
  more = false;
  isList = true;
  isCalendar = false;
  constructor(
    private modalService: DialogService,
    private eventService: EventsService
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
    this.myEvent = this.eventService.getAllEvents();
  }
  editEvent(): void {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un nouvel évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  showMore() {
    this.more = !this.more
  }

}
