/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';
import { IconEnum, ControlSizeEnum, ControlTypeColorEnum } from 'datnek-ds';

interface sectionDetails {
  imgSrc: string;
  title: string;
  description: string;
  id: string;
}

interface sectionTitles {
  title: string;
  icon: string;
  id: string;
}
@Component({
  selector: 'del-shared-apps-section',
  templateUrl: './apps-section.component.html',
})
export class AppsSectionComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;

  @Input() sectionDetails: sectionDetails[] = [];
  @Input() sectionTitles: sectionTitles[] = [];
  @Input() autoSlide = false;
  @Input() slideInterval = 3000;

  selectedIndex = 0;

  activeTab: any = 'reseau-pro';

  constructor() { }

  ngOnInit(): void {
  }

  selectItem(index: number) {
    this.selectedIndex = index;
  }

  reseauPro(activeTab: any) {
    this.activeTab = activeTab;
  }

  test(activeTab: any) {
    this.activeTab = activeTab;
  }

  emploi(activeTab: any) {
    this.activeTab = activeTab;
  }

  eLearning(activeTab: any) {
    this.activeTab = activeTab;
  }

}
