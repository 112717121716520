import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogRef,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';
import { Emails } from '../../../core/emails/emails.model';
import { EmailListService } from '../../../core/services/emails/email-list.service';


interface emailList {
  id: number;
  email: string;
}

@Component({
  selector: 'del-shared-dialog-share-to-email',
  templateUrl: './dialog-share-to-email.component.html',
  styleUrls: ['./dialog-share-to-email.component.scss'],
})
export class DialogShareToEmailComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  form!: FormGroup;
  emails?: Emails;

  public emailList: Emails[] = [];

  constructor(
    public emailServices: EmailListService,
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  onSubmit() {
    console.log(this.form.value);
    this.emailServices.addEmail(this.form.value.email);

    this.initForm();
  }

  deleteResult(item: any) {
    this.emails = item;
    this.emailServices.deleteEmail(item);
  }

  close(): void {
    this.dialogRef.close();
  }
}
