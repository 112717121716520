<div class="datnek-ds-card suggestion-box skeleton">
  <div
    class="suggestion-header d-flex justify-content-between w-100"
    *ngIf="!isLoading"
  >
    <div class="display-1 mr-3 clip ellipsis">{{ titleCard }}</div>
    <datnek-ds-button
      label="Suivre"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="showToast(follow); folowed = true"
      *ngIf="!folowed && isAuthor"
    ></datnek-ds-button>
    <datnek-ds-button
      label="Suivi"
      [type]="colorEnum.secondary"
      (datnekdsClickOrTouch)="showToast(nofollow); folowed = false"
      *ngIf="folowed && isAuthor"
    ></datnek-ds-button>
  </div>
  <div
    class="suggestion-header d-flex justify-content-between w-100"
    *ngIf="isLoading"
  >
    <div class="line w-50 h15"></div>
    <div class="circle small-c"></div>
  </div>

  <div class="suggestion-content">
    <ng-container *ngIf="suggestionType != 'article'">
      <nk-shared-suggestion-list
        [userData]="dataUsers"
        [status]="status"
      ></nk-shared-suggestion-list>
    </ng-container>

    <ng-container *ngIf="suggestionType === 'article'">
      <nk-shared-suggestion-article-list
        [status]="status"
        [isSidebar]="isSidebar"
        [isFull]="isFull"
      ></nk-shared-suggestion-article-list>
    </ng-container>
  </div>
  <div class="suggestion-footer d-flex justify-content-center text-center">
    <!--IF IS SUGGESTION LIST-->
    <datnek-ds-button
      label="Voir plus"
      *ngIf="suggestionType === 'profile'"
      (datnekdsClickOrTouch)="gotoSuggestion($event)"
      [type]="colorEnum.link"
      [size]="sizeEnum.medium"
    >
    </datnek-ds-button>

    <!--IF IS ENTERPRISE LIST-->
    <datnek-ds-button
      label="Voir plus"
      *ngIf="suggestionType === 'jobs'"
      (datnekdsClickOrTouch)="gotoEnterprises($event)"
      [type]="colorEnum.link"
      [size]="sizeEnum.medium"
    >
    </datnek-ds-button>
    <!--IF IS ARTICLE LIST-->
    <datnek-ds-button
      label="Voir plus"
      (datnekdsClickOrTouch)="gotoListArticles($event)"
      *ngIf="suggestionType === 'article'"
      [type]="colorEnum.link"
      [size]="sizeEnum.medium"
    >
    </datnek-ds-button>
    <div class="line w-50 h15 m-auto" *ngIf="isLoading"></div>
  </div>
</div>

<!--MESSAGES-->
<ng-template #follow let-close="close">
  Vous suivez désormais John Doe</ng-template
>

<ng-template #nofollow let-close="close">
  Vous ne suivez plus John Doe</ng-template
>
