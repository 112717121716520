import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum,
  ControlTypeColorEnum,
  IconEnum,
  PopupInterface,
  ControlSizeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  time: string;
  name: string;
  writer: string;
  message: string;
}

@Component({
  selector: 'nk-shared-chat-list-box',
  templateUrl: './chat-list-box.component.html',
  styleUrls: ['./chat-list-box.component.scss'],
})
export class ChatListBoxComponent implements OnInit {
  isLoading = false;

  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  showPopup = false;
  dataUsersChat: PopupInterface[] = [
    {
      id: '1',
      name: 'Danick takam ouhakh kjahkjehkjehjk ekajh kjehkjeh kjaehazkjehaz kjh',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      writer: 'Vous',
      message: 'Je vous en prie monsieur. vous poriez me demander votre numéro ?',
    },
    {
      id: '2',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jean-Marc',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '3',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Arno yankam',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '4',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Kevin Sokodjou',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '5',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jordan Wildes',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '6',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jospin Chimi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '7',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Lionel Messi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '8',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Christiane',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '9',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Laurent Nkoh',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '10',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'BITE Blaise',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
  ];

  status = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  counter(i: number) {
    return new Array(i);
  }

  showStatus() {
    this.status = !this.status;
    console.log('STATUS : ' + this.status);
  }

  popupShow() {
    this.showPopup = !this.showPopup;
    console.log('POPUP PARENT : ' + this.showPopup);
  }

  goToChatMessage(e: any) {
    e.preventDefault();
    this.router.navigate(['/chat/home']);
    this.status = false;
    console.log(e);
  }

  //this variable represents the total number of popups can be displayed according to the viewport width
  totalPopups = 0;

  //arrays of popups ids
  popups: PopupInterface[] = [];

  //this function can remove a array element.
  remove(item: PopupInterface) {
    this.popups = this.popups.filter((pp) => pp.id !== item.id);
    console.log('new count popups:', this.popups);
  }

  //this is used to close a popup
  closePopup(item: PopupInterface) {
    this.remove(item);
    this.calculatePopups();
  }

  //displays the popups. Displays based on the maximum number of popups that can be displayed on the current viewport width
  displayPopups() {
    let right = 376;

    let i = 0;
    for (i; i < this.totalPopups; i++) {
      if (this.popups[i]) {
        this.popups[i].right = right;
        right = right + 360;
        this.popups[i].display = true;
      }
    }

    for (var j = i; j < this.popups.length; j++) {
      this.popups[i].display = false;
    }
  }

  //creates markup for a new popup. Adds the id to popups array.
  registerPopup(item: PopupInterface) {
    //already registered. Bring it to front.
    this.popups = this.popups.filter((pp) => pp.id !== item.id);

    this.popups.unshift(item);
    console.log('count popups:', this.popups);
    this.calculatePopups();
  }

  //calculate the total number of popups suitable and then populate the toatal_popups variable.
  @HostListener('window:resize', ['$event'])
  @HostListener('window:load', ['$event'])
  calculatePopups() {
    //recalculate when window is loaded and also when window is resized.
    let width = window.innerWidth;
    if (width < 540) {
      this.totalPopups = 0;
    } else {
      width = width - 350;
      //350 is width of a single popup box
      this.totalPopups = parseInt(`${width / 350}`);
    }

    this.displayPopups();
  }
}
