/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

@Component({
  selector: 'del-shared-dialog-delete-comment',
  templateUrl: './dialog-delete-comment.component.html',
  styleUrls: ['./dialog-delete-comment.component.scss'],
})
export class DialogDeleteCommentComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(public ref: DialogRef) {}

  ngOnInit(): void {}

  close() {
    this.ref.close(null);
  }
}
