import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  DialogRef,
  IconEnum,
  SelectListTypeEnum,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';


@Component({
  selector: 'del-shared-dialog-share-to-group',
  templateUrl: './dialog-share-to-group.component.html',
  styleUrls: ['./dialog-share-to-group.component.scss'],
})
export class DialogShareToGroupComponent {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  selectType = SelectListTypeEnum;
  constructor(
    public dialogRef: DialogRef,
    private modalService: DialogService
  ) {}


  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
