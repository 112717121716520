<div class="datnek-ds-card suggestion-box invitation-box skeleton">
  <div class="suggestion-header" *ngIf="!isLoading">
    <div class="display-1 head-title-with-icon">{{ titleCard }}</div>
  </div>
  <div class="suggestion-header" *ngIf="isLoading">
    <div class="line w-50 h15"></div>
  </div>
  <div class="suggestion-content">
    <nk-shared-invitation-list [userData]="dataUsers" [status]="status"></nk-shared-invitation-list>
  </div>
  <div class="suggestion-footer d-flex justify-content-center text-center">
    <datnek-ds-button
      label="Voir plus"
      (datnekdsClickOrTouch)="gotoInvitation($event)"
        *ngIf="!isLoading"
      [type]="colorEnum.link"
      [size]="sizeEnum.medium"
    >
    </datnek-ds-button>
    <div class="line w-50 h15 m-auto" *ngIf="isLoading"></div>
  </div>
</div>
