import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';
import { Overlay } from '@angular/cdk/overlay';
import { DialogAddSignalizationComponent } from '../dialog-add-signalization/dialog-add-signalization.component';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

interface Items {
  label: string;
  description?: string;
}

@Component({
  selector: 'nk-shared-dialog-group-adhesion',
  templateUrl: './dialog-group-adhesion.component.html',
  styleUrls: ['./dialog-group-adhesion.component.scss'],
})
export class DialogGroupAdhesionComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  dataUser: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur et Data Senior',
      name: 'Fara Moiten 2 kkjsfksjd skfjkds fkjhfjksdfh kj fhjsdfdsk',
      society: 'DIGITAILLE Multimedia Intertainement',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
    },
  ];

  acceptRules: Items[] = [
    {
      label: 'J’accepte les règles du groupe',
    },
  ];

  quizz: Items[] = [
    {
      label: 'Oui',
    },
    {
      label: 'Non',
    },
    {
      label: 'Peut-être',
    },
  ];

  quizz1: Items[] = [
    {
      label: 'Partager mes connaissances & expériences',
    },
    {
      label: 'Apprendre de nouvelles techinques de vente',
    },
    {
      label: 'Améliorer mes compétences',
    },
    {
      label: 'Rcontrer de nouveaux profils qualifiés',
    },
    {
      label: 'Autre',
    },
  ];

  constructor(
    public ref: DialogRef,
    private overlay: Overlay,
    private modalService: DialogService
  ) {}

  ngOnInit(): void {}

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  addSignalization() {
    const ref = this.modalService.open(DialogAddSignalizationComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Signaler ce groupe`,
        size: DialogSizeEnum.small,
      },
    });
  }

  close() {
    this.ref.close(null);
  }
}
