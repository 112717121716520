<form class="dialog-new-content"  (ngSubmit)="submit()">
  <div class="datnek-ds-dialog-body dialog-share-post-with-edit">
    <div class="content-modal">
      <formly-form [model]="model" [fields]="fields"
                   [options]="options" [form]="form"></formly-form>

      <del-shared-card-publication-box
          [shared]="true"
          [status]="avatarEnum.person"
        ></del-shared-card-publication-box>
    </div>
    <!-- FOOTER MODAL -->
    <div class="datnek-ds-dialog-footer">
      <div class="icons d-flex align-items-center justify-content-between w-100">
        <div class="icon-content d-flex">
          <datnek-ds-button-icon
            [type]="colorEnum.ghost"
            [size]="sizeEnum.large"
            [Icon]="iconEnum.outlinephotograph"
            (datnekdsClickOrTouch)="addPicture(); showImages()"
          ></datnek-ds-button-icon>
          <datnek-ds-button-icon
            [type]="colorEnum.ghost"
            [size]="sizeEnum.large"
            [Icon]="iconEnum.outlinefilm"
            (datnekdsClickOrTouch)="addVideo(); showVideos()"
          ></datnek-ds-button-icon>
          <datnek-ds-button-icon
            [type]="colorEnum.ghost"
            [size]="sizeEnum.large"
            [Icon]="iconEnum.outlinedocumentText"
            (datnekdsClickOrTouch)="addDocument(); showDocuments()"
          ></datnek-ds-button-icon>
          <datnek-ds-button-icon
            [type]="colorEnum.ghost"
            [size]="sizeEnum.large"
            [Icon]="iconEnum.outlinemicrophone"
            (datnekdsClickOrTouch)="addVoice(); showVoices()"
          ></datnek-ds-button-icon>
          <!--datnek-ds-button-icon
            [type]="colorEnum.ghost"
            [size]="sizeEnum.large"
            [Icon]="iconEnum.outlinecalendar"
            (datnekdsClickOrTouch)="addCalendar(); showLinks()"
          ></datnek-ds-button-icon-->
        </div>

        <datnek-ds-button
          [label]="'Publier'"
          [role]="'submit'"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
        ></datnek-ds-button>
      </div>
    </div>
  </div>
</form>

