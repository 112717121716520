<div class="menu-right">
    <!-- <datnek-ds-button-icon
      [Icon]="iconEnum.solidinboxclose"
      [type]="colorEnum.secondary"
      [size]="sizeEnum.medium"
      [round]="true"
      class="close"
      (datnekdsClickOrTouch)="showmenuRight()"
    ></datnek-ds-button-icon> -->
  
    <nk-shared-card-user-box
                [onChat]="true"
                name="John Doe"
                resumeTitle="Web Designer senior"
                class="pt-3"
                [status]="avatarEnum.person"
    ></nk-shared-card-user-box>

    <div class="p-3 skeleton d-flex gap-3" *ngIf="isLoading">
      <div class="line h15 w-100"></div>
      <div class="line h15 w-100"></div>
      <div class="line h15 w-100"></div>
    </div>

    <div class="sidebar-right-files bg-white" *ngIf="!isLoading">
      <p-tabView>
        <p-tabPanel header="Images">
          <p-scrollPanel [style]="{ width: '100%', height: '165px' }">
            <div class="sidebar-media-chat pt-2">
              <datnek-ds-image-gallery
                [isList]="true"
              ></datnek-ds-image-gallery>
            </div>
          </p-scrollPanel>
        </p-tabPanel>
        <p-tabPanel header="Vidéos">
          <p-scrollPanel [style]="{ width: '100%', height: '165px' }">
            <div class="sidebar-media-chat pt-2">
              <datnek-ds-video-gallery
                [galleryVideoItems]="videoItems"
              ></datnek-ds-video-gallery>
            </div>
          </p-scrollPanel>
        </p-tabPanel>
        <p-tabPanel header="Fichiers">
          <p-scrollPanel [style]="{ width: '100%', height: '165px' }">
            <div class="sidebar-media-chat pt-2">
              <datnek-ds-document-gallery></datnek-ds-document-gallery>
            </div>
          </p-scrollPanel>
        </p-tabPanel>
        <p-tabPanel header="Liens">
          <p-scrollPanel [style]="{ width: '100%', height: '164px' }">
            <div class="sidebar-media-chat pt-2">
              <div class="link-item d-flex align-items-baseline">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.outlinelink"
                  class="mr-1"
                ></datnek-ds-svg-icon>
                <datnek-ds-link
                  label="http://localhost:4200/groups/single-group/group-multimedia"
                  href="http://localhost:4200/groups/single-group/group-multimedia"
                ></datnek-ds-link>
              </div>
              <div class="link-item d-flex align-items-baseline">
                <datnek-ds-svg-icon
                  [icon]="iconEnum.outlineexternalLink"
                  class="mr-1"
                ></datnek-ds-svg-icon>
                <datnek-ds-link
                  label="https://www.figma.com/file/oeYduVl0zJAZmDpdLnRY5V/DELENSCIO-NETWORK?node-id=1%3A994"
                  href="https://www.figma.com/file/oeYduVl0zJAZmDpdLnRY5V/DELENSCIO-NETWORK?node-id=1%3A994"
                  [target]="'_blank'"
                ></datnek-ds-link>
              </div>
            </div>
          </p-scrollPanel>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="pl-3" *ngIf="isLoading">
      <datnek-skeleton-multimedia-images></datnek-skeleton-multimedia-images>
    </div>
  </div>