import { Subscription } from 'rxjs';
import {
  Component,
  TemplateRef,
  Type,
  ElementRef,
  OnInit,
  OnDestroy, ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum, BaseComponent,
  ControlDisplayEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  DialogAddDocumentComponent,
  DialogAddLinkComponent,
  DialogAddPicturesComponent,
  DialogAddVideoComponent,
  DialogAddVoiceComponent,
  DialogRef,
  FileAcceptEnum,
  IconEnum,
  IconRadioImageEnum,
  ImageTextDropdownFormlyFieldProps, InputBaseFormlyFieldProps,
  InputCheckboxFormlyFieldProps,
  InputDateFormlyFieldProps, InputTimeFormlyFieldProps, Select2FormlyFieldProps, SelectListTypeEnum,
  SelectTagsComponent,
  UploadFileFormlyFieldProps,
  UtilsInterface,
} from 'datnek-ds';
import { DialogService, DialogTypeEnum, TooltipTypeEnum } from 'datnek-ds';
import {
  CategoryService
} from "@network-shared";
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {
  AddArticleHeaderFormlyFieldProps
} from "../../formly/add-article-header-formly/add-article-header-formly.component";

@Component({
  selector: 'network-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss'],
})
export class AddArticleComponent extends BaseComponent{

  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;



  form = new FormGroup({});
  model = {
    Background:  null,
    CreatorId:  null,
    Subjects:  null,
    Title:  null,
    Content: null
  };
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: true,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      key: 'CreatorId',
      type: 'add-article-header',
      props: {
        label: 'Publier en tant que *',
        goToHome: (e: any)=> {
          this.goToHome(e);
        }
      } as AddArticleHeaderFormlyFieldProps,
      fieldArray: {
        type: 'dropdown-image-text',
        props: {
          required: true,
          isFormControl: true,
          icon: IconRadioImageEnum.me,
          options: [
            { label: 'Danick takam', value: 'danick', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.me },
            { label: 'Datnek Bv', value: 'datnek', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.enterprise },
            { label: 'Afelio', value: 'afelio', type: ControlDisplayEnum.inline, icon: IconRadioImageEnum.enterprise },
          ] as UtilsInterface.DropdownItem[],
          valueChange: (value: any)=> {
            console.log('model', this.model);
          }
        } as ImageTextDropdownFormlyFieldProps,
      },
    },
    {
      wrappers: ['panel-div'],
      props: {
        label: 'p-3'
      },
      fieldGroupClassName: "content d-flex pt-3 row p-md-3",
      fieldGroup: [
        {
          className: "col-md-8 col-12",
          fieldGroup: [
            {
              key: 'Title',
              type: 'textarea',
              props: {
                required: true,
                placeholder: "Titre de la publication",
                rows: 1,
                className: ['big-text']
              } as InputBaseFormlyFieldProps,
            },

            {
              key: 'Content',
              type: 'editor',
              props: {
                placeholder: 'Commencez à rédiger...',
                className: ['h-500'],
                required: true,
              },
            },
          ]
        },

        {
          className: "col-md-4 d-none d-md-block pr-0",
          fieldGroup: [
            {
              key: 'Background',
              type: 'upload',
              props: {
                required: true,
                accept: [FileAcceptEnum.image],
                title: "Téléchargez une photo de couverture",
                imgSize: "Largeur minimum de 1280 X 720",
                fileType: "single",
                type: "image",
                multimedia: false,
                placeholder: 'Ajouter une légende'
              } as UploadFileFormlyFieldProps,
            },
            {
              key: 'Subjects',
              type: 'select2',
              className: 'mt-3',
              props: {
                label: 'Ajouter ou modifier des sujets (jusqu’à 3) pour que les lecteurs sachent ce que votre histoire représente',
                required: true,
                selectType: SelectListTypeEnum.users ,
                options: [
                  { Label: 'Développement', Number: '(29k)', Value: 'iron_man' },
                  { Label: 'data center',  Number: '(12k)', Value: 'captain_america' },
                  { Label: 'Speed dating',  Number: '(10k)', Value: 'black_widow' },
                  { Label: 'Data',  Number: '(40k)', Value: 'hulk' },
                  { Label: 'Captain Marvel',  Number: '(11k)', Value: 'captain_marvel' }
                ] as UtilsInterface.ValueLabel[],
              } as Select2FormlyFieldProps,
            },
          ]
        },

      ]
    },

  ];


  @ViewChild('afterPublish')
  private afterPublish!: TemplateRef<any>;

  @ViewChild('afterDelete')
  private afterDelete!: TemplateRef<any>;
  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private modalService: DialogService,
    private categoryService: CategoryService,
    private ref: DialogRef
  ) {
    super();
  }

  goToHome(e: any) {
    this.router.navigate(['/']);
  }

  openArticle(e: any) {
    this.router.navigate(['/single-publication']);
  }


  close(data: any) {
    this.ref.close(data);
  }

  submit() {

    if(this.form.valid){
      const ref = this.modalService.open(this.afterPublish, {
        dialogType: DialogTypeEnum.Toast,
        tooltipType: TooltipTypeEnum.base,
      });
    }
  }
}
