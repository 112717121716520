<div
  class="
    mb-3
    d-flex
    justify-content-start
    align-items-center
    position-relative
    filter-media
    gap-12
  "
>
  <datnek-ds-button
    label="Les plus pertinents"
    [type]="colorEnum.secondary"
  ></datnek-ds-button>
  <datnek-ds-button
    label="Les plus récents"
    [type]="colorEnum.secondary"
  ></datnek-ds-button>

  <!-- <button (datnekdsClickOrTouch)="openDropdown(filter, origin1)" #origin1>
    <datnek-ds-button-icon
      [Icon]="iconEnum.outlinedotsHorizontal"
      [type]="colorEnum.ghost"
      [round]="true"
      id="dropdownBasic1"
    ></datnek-ds-button-icon>
  </button> -->
</div>

<!-- DROPDOWN -->
<ng-template #filter let-close="close">
  <div class="datnek-ds-card dropdown-card">
    <button class="dropdown-item">
      <span class="">Plus récents</span>
    </button>
    <button class="dropdown-item">
      <span class="">Plus anciens</span>
    </button>
    <button class="dropdown-item" *ngIf="isLink === true">
      <span class="">Liens internes</span>
    </button>
    <button class="dropdown-item" *ngIf="isLink === true">
      <span class="">Liens externes</span>
    </button>
  </div>
</ng-template>
