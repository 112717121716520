<div
  class="suggestion-list-card group-card"
  *ngIf="!isLoading && status === avatarEnum.group"
>
  <div
    class="datnek-ds-card network-user-suggestion-small pointer mb-0"
    *ngFor="let item of groups"
  >
    <ng-container>
      <div class="img" (datnekdsClickOrTouch)="goToGroup($event)">
        <img src="{{ item.imageSrc }}" alt="" />
      </div>
      <div class="infos px-3 pt-3" (datnekdsClickOrTouch)="goToGroup($event)">
        <datnek-ds-avatar
          [type]="avatarEnum.group"
          [size]="sizeEnum.small"
          [imgSrc]="imgSrc"
        ></datnek-ds-avatar>
        <div class="name d-flex flex-column">
          <div class="d-flex w-100">
            <div class="display-2 clip ellipsis mr-2">
              {{ item.groupName }}
            </div>
          </div>

          <div class="stats d-flex w-100 justify-content-between pt-3">
            <div class="followers">
              <span class="">{{ item.members }}</span> membres
            </div>
            <div class="followers">
              <span class="">{{ item.publication }}</span> post par jour
            </div>
          </div>
        </div>
        <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
      </div>
    </ng-container>

    <div class="follow p-3">
      <datnek-ds-button
        label="Suivre"
        [type]="colorEnum.primary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === false"
        (datnekdsClickOrTouch)="adhesionDemand()"
      ></datnek-ds-button>
      <datnek-ds-button
        label="Annuler le suivi"
        [type]="colorEnum.secondary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === true"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button>
    </div>
  </div>
</div>

<div
  class="suggestion-list-card group-card"
  *ngIf="!isLoading && status === avatarEnum.society"
>
  <div
    class="datnek-ds-card network-user-suggestion-small pointer mb-0"
    *ngFor="let item of societyData"
  >
    <ng-container>
      <div
        class="img"
        (datnekdsClickOrTouch)="goToSociety('https://dev-jobs.delenscio.be')"
      >
        <img src="{{ item.imageSrc }}" alt="" />
      </div>
      <div
        class="infos px-3 pt-3"
        (datnekdsClickOrTouch)="goToSociety('https://dev-jobs.delenscio.be')"
      >
        <datnek-ds-avatar
          [type]="avatarEnum.society"
          [size]="sizeEnum.small"
          [imgSrc]="imgSrc"
        ></datnek-ds-avatar>
        <div class="name d-flex flex-column">
          <div class="d-flex">
            <div class="display-2 clip ellipsis mr-2">
              {{ item.society }}
            </div>
          </div>
          <span class="clip ellipsis">{{ item.sector }}</span>
          <span id="relations" class="display-8">
            {{ item.relations }} relations en commun
          </span>
        </div>
        <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
      </div>
    </ng-container>

    <div class="follow p-3">
      <datnek-ds-button
        label="Suivre"
        [type]="colorEnum.primary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === false"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button>
      <datnek-ds-button
        label="Annuler le suivi"
        [type]="colorEnum.secondary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === true"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button>
    </div>
  </div>
</div>

<div class="suggestion-list-card" *ngIf="isLoading && groups.length > 1">
  <datnek-skeleton-group-card
    *ngFor="let in of counter(4); let i = index"
  ></datnek-skeleton-group-card>
</div>

<div class="suggestion-list-card" *ngIf="isLoading && groups.length === 1">
  <datnek-skeleton-group-card
    *ngFor="let in of counter(1); let i = index"
  ></datnek-skeleton-group-card>
</div>

<div
  class="suggestion-list-card"
  *ngIf="isLoading && status === avatarEnum.society"
>
  <datnek-skeleton-group-card
    *ngFor="let in of counter(4); let i = index"
  ></datnek-skeleton-group-card>
</div>
