<ul class="list-comment comment-pushed" *ngIf="!addComment">
  <li *ngFor="let comment of commentData; let i = index" class="mb-3">
    <div class="d-flex comment-box position-relative">
      <div class="left mr-3">
        <div
          (mouseenter)="openDropdown(userinfos, target1)"
          (mouseleave)="openDropdown(userinfos, target1)"
          #target1
        >
          <datnek-ds-avatar
            [size]="sizeEnum.small"
            [type]="avatarEnum.person"
            class="pointer"
          ></datnek-ds-avatar>
        </div>
      </div>
      <div class="right">
        <div class="comment-item">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <div
                (mouseenter)="openDropdown(userinfos, target1)"
                (mouseleave)="openDropdown(userinfos, target1)"
                #target2
              >
                <div class="display-4 username pointer truncated">
                  {{ comment.name }}
                </div>
              </div>

              <datnek-ds-link
                href="javascript:;"
                label="Suivre"
                class="ml-3"
                *ngIf="!followed"
                (datnekdsClickOrTouch)="followed = true"
              >
              </datnek-ds-link>
              <datnek-ds-link
                href="javascript:;"
                label="Suivi"
                class="ml-3"
                *ngIf="followed"
                (datnekdsClickOrTouch)="followed = false"
              >
              </datnek-ds-link>
            </div>
            <div ngbDropdown class="d-inline-block comment-dropdown">
              <datnek-ds-button-icon
                [Icon]="iconEnum.outlinedotsVertical"
                [type]="colorEnum.unstyled"
                [size]="sizeEnum.small"
                id="dropdownBasic2"
                ngbDropdownToggle
              ></datnek-ds-button-icon>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem (datnekdsClickOrTouch)="onAddComment()">
                  Modifier le commentaire
                </button>
                <button
                  ngbDropdownItem
                  (datnekdsClickOrTouch)="deleteComment()"
                >
                  Supprimer le commentaire
                </button>
                <button
                  ngbDropdownItem
                  (datnekdsClickOrTouch)="addSignalization()"
                >
                  Signaler le commentaire
                </button>
              </div>
            </div>
          </div>

          <datnek-ds-truncate [message]="comment.desc"></datnek-ds-truncate>
          <!-- {{
              readMore
                  ? comment.desc
                  : (comment.desc
                    | slice : 0 : 45)
          }}
          <span *ngIf="!readMore">...</span>
          <a
              href="javascript:;"
              (click)="onReadMore()"
              >{{
                  readMore
                      ? "Voir moins"
                      : "Voir plus"
              }}</a
          > -->
        </div>

        <!--IF IS PICTURES ADDED-->
        <ng-container *ngIf="comment.multimedia === 'images'">
          <div class="mt-3">
            <datnek-ds-image-gallery
              [isgroupMultimedia]="true"
              [galleryItems]="galleryItems"
              [closable]="true"
            ></datnek-ds-image-gallery>
          </div>
        </ng-container>

        <!--IF IS VIDEOS ADDED-->
        <ng-container *ngIf="comment.multimedia === 'video'">
          <div class="my-3">
            <datnek-ds-video-gallery
              [isgroupMultimedia]="true"
              [galleryVideoItems]="dataVideosGallery"
              [closable]="true"
            ></datnek-ds-video-gallery>
          </div>
        </ng-container>

        <!--IF AUDIO-->
        <ng-container *ngIf="comment.multimedia === 'voice'">
          <div class="my-3 message-audio">
            <audio controls="">
              <source src="/assets/file_example_WAV_1MG.wav" type="audio/wav" />
            </audio>
          </div>
        </ng-container>

        <!--IF LINK-->
        <ng-container *ngIf="comment.multimedia === 'link'">
          <div class="mt-3">
            <div class="thumbnail-view">
              <div class="d-flex">
                <div class="items">
                  <div class="item-list link-list">
                    <div
                      class="link datnek-ds-card"
                      (datnekdsClickOrTouch)="link('http://getbootstrap.com/')"
                    >
                      <div class="img">
                        <img src="assets/Img/link-placeholder-bg.png" alt="" />
                      </div>
                      <div class="content-infos p-3">
                        <div class="display-2 clip ellipsis">Titre de la page</div>
                        <p>
                          {{ linkDescription | truncatetext: 65 }}
                        </p>
                        <span class="d-flex"
                          ><datnek-ds-svg-icon
                            [icon]="iconEnum.outlinelink"
                            class="mr-2"
                          ></datnek-ds-svg-icon>
                          <small class="truncated"
                            >http://getbootstrap.com/</small
                          ></span
                        >
                      </div>
                    </div>
                    <div
                      class="link datnek-ds-card"
                      (datnekdsClickOrTouch)="link('http://getbootstrap.com/')"
                    >
                      <div class="img">
                        <img src="assets/Img/link-placeholder-bg.png" alt="" />
                      </div>
                      <div class="content-infos p-3">
                        <div class="display-2 clip ellipsis">
                          Titre de la page se passe ici
                        </div>
                        <p>
                          {{ linkDescription | truncatetext: 65 }}
                        </p>
                        <span class="d-flex"
                          ><datnek-ds-svg-icon
                            [icon]="iconEnum.outlinelink"
                            class="mr-2"
                          ></datnek-ds-svg-icon>
                          <small class="truncated"
                            >http://getbootstrap.com/progress-bar/jsdfdskjfhkdshdhfdhs</small
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--<datnek-ds-image-gallery
        [isgroupMultimedia]="true"
        [galleryItems]="galleryItems"
        [closable]="true"
      ></datnek-ds-image-gallery>-->
          </div>
        </ng-container>

        <div
          class="actions d-flex align-items-center"
          (datnekdsClickOrTouch)="onLiked()"
        >
          <div class="like">J'aime</div>
          <div class="d-flex" *ngIf="liked === true">
            <img
              src="assets/icons/src/solid/color/heart-active-color.svg"
              alt=""
              class="mr-2"
            />
            <span class="display-6">1</span>
          </div>
          <div class="reply" (datnekdsClickOrTouch)="onRespond()">Répondre</div>
          <div class="time">il ya {{ comment.since }}</div>
        </div>

        <div class="more-comments">
          <datnek-ds-button
            [type]="colorEnum.link"
            [size]="sizeEnum.small"
            label="Voir les {3} autres commentaires"
            (datnekdsClickOrTouch)="showMoreComments()"
          ></datnek-ds-button>
        </div>
      </div>
    </div>
    <ul *ngIf="respond === true">
      <nk-shared-comment-add *ngIf="moreComments === false"></nk-shared-comment-add>
      <li>
        <nk-shared-comment-pushed
          [commentData]="dataSubComments"
        ></nk-shared-comment-pushed>
      </li>
      <li *ngIf="moreComments === true">
        <nk-shared-comment-pushed
          [commentData]="dataSubComments"
        ></nk-shared-comment-pushed>
      </li>
      <li *ngIf="moreComments === true">
        <nk-shared-comment-pushed
          [commentData]="dataSubComments"
        ></nk-shared-comment-pushed>
      </li>
    </ul>
  </li>
</ul>

<div class="editing-comment" *ngIf="addComment">
  <nk-shared-comment-add></nk-shared-comment-add>
</div>

<!-- DROPDOWN -->
<ng-template #userinfos let-close="close">
  <div class="datnek-ds-card dropdown-card user-infos-dropdown">
    <nk-shared-comment-user-infos
      [commentUserInfo]="false"
    ></nk-shared-comment-user-infos>
  </div>
</ng-template>

<ng-template #userinfos1 let-close="close">
  <nk-shared-comment-user-infos
    [commentUserInfo]="true"
  ></nk-shared-comment-user-infos>
</ng-template>
