/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  LikeBoxPositionEnum,
} from 'datnek-ds';

@Component({
  selector: 'del-shared-likes-icons-popbox',
  templateUrl: './likes-icons-popbox.component.html',
  styleUrls: ['./likes-icons-popbox.component.scss'],
})
export class LikesIconsPopboxComponent implements OnInit {
  isLoading = false;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  positionEnum = LikeBoxPositionEnum;

  fullIcons = false;

  /**
   * L'élément à afficher dans le component parent lorsque l'event @onClick est appelé
   *
   * @boolean
   */
  @Input()
  likes = false;

  /**
   * Lorsque l'on clique sur une réaction, l'icone doit s'afficher sur le parent
   *
   * @boolean
   */
  @Input()
  reaction = false;

  /**
   * Left coordonates in absolute position
   *
   * @number
   */
  @Input()
  left!: number;

  /**
   * TOp coordonates in absolute position
   *
   * @number
   */
  @Input()
  top!: number;

  /**
   *
   */
  @Input()
  position: LikeBoxPositionEnum = LikeBoxPositionEnum.bottom;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public get classes(): string[] {
    return [this.position];
  }

  itemShow() {
    this.onClick.emit(this.likes);
  }

  showReaction() {
    this.onClick.emit((this.reaction = !this.reaction));
  }

  onFullIcons() {
    this.fullIcons = !this.fullIcons;
  }

  showLikes(): void {
    this.likes = !this.likes;
  }

  positionStyles() {
    const styles = {
      left: this.left + 'px !important',
      top: this.top + 'px !important',
    };
    return styles;
  }
}
