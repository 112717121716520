/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { DialogService,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogTypeEnum } from 'datnek-ds';


interface Items {
  label: string;
  description?: string;
}

@Component({
  selector: 'del-shared-filter-media',
  templateUrl: './filter-media.component.html',
  styleUrls: ['./filter-media.component.scss'],
})
export class FilterMediaComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  /**
   * L'élément à afficher dans le component parent lorsque l'event @onClick est appelé
   *
   * @boolean
   */
  @Input()
  showItem = false;

  /**
   * L'élément Vidéo à afficher dans le component parent lorsque l'event @onClick est appelé
   *
   * @boolean
   */
  @Input()
  showVideoItem = false;

  /**
   * L'élément Vidéo à afficher dans le component parent lorsque l'event @onClick est appelé
   *
   * @boolean
   */
  @Input()
  showDocumentItem = false;

  /**
   * L'élément Lien à afficher dans le component parent lorsque l'event @onClick est appelé
   *
   * @boolean
   */
  @Input()
  showLinkItem = false;

  /**
   * Si l'onglet est de type Link
   *
   * @boolean
   */
  @Input()
  isLink = false;

  dateFilter: Items[] = [
    {
      label: 'Trier par date',
    },
  ];

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<any>();

  constructor(private modalService: DialogService) {}

  ngOnInit(): void {}

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  itemShow() {
    this.onClick.emit(this.showItem);
  }

  itemVideoShow() {
    this.onClick.emit(this.showVideoItem);
  }

  itemDocumentShow() {
    this.onClick.emit(this.showVideoItem);
  }

  itemLinkShow() {
    this.onClick.emit(this.showLinkItem);
  }
}
