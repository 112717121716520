/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'del-shared-loading-app',
  templateUrl: './loading-app.component.html',
})
export class LoadingAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
