<div class="datnek-ds-dialog-body dialog-add-member">
  <div class="content-modal">
    <div class="group-name p-3 border-bottom" *ngIf="groupName === true">
      <datnek-ds-input-text
        label="Nom du groupe"
        placeholder="Donnez un nom à ce groupe"
      ></datnek-ds-input-text>
    </div>
    <div class="content-text px-3 py-3">
      <p *ngIf="isMultiple === true">
        Ajouter des personnes à cette conversation pour former un groupe ou une
        conversation simple.
      </p>
      <p *ngIf="isMultiple === false">
        Ajouter une personne pour démarrer une conversation
      </p>
      <datnek-ds-search-input
        placeholder="Trouver des personnes"
      ></datnek-ds-search-input >
    </div>

    <div class="add-member p-3" *ngIf="items.length">
      <div
        class="member-item"
        *ngFor="let item of items; let i = index"
        
      >
        <datnek-ds-avatar [size]="sizeEnum.medium1" [type]="avatarEnum.person"></datnek-ds-avatar>
        <!-- <span class="close pointer">x</span> -->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinex"
          [type]="colorEnum.secondary"
          [size]="sizeEnum.small"
          [round]="true"
          class="close"
          (datnekdsClickOrTouch)="remove(i)"
        ></datnek-ds-button-icon>
      </div>
      <div
        class="member-item"
        *ngIf="added"
      >
        <datnek-ds-avatar [size]="sizeEnum.medium1" [type]="avatarEnum.person"></datnek-ds-avatar>
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinex"
          [type]="colorEnum.secondary"
          [size]="sizeEnum.small"
          [round]="true"
          class="close"
          (datnekdsClickOrTouch)="removed()"
        ></datnek-ds-button-icon>
      </div>
      <span>+9</span>
    </div>
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100vh - 504px)' }">
      <div class="member-list">
        <nk-shared-suggestion-list
          [userData]="dataUsers"
          [status]="avatarEnum.person"
          [link]="true"
          (datnekdsClickOrTouch)="add()"
        ></nk-shared-suggestion-list>
      </div>
    </p-scrollPanel>
  </div>

  <div class="datnek-ds-dialog-footer">
    <datnek-ds-button
      [label]="'Terminer'"
      [size]="sizeEnum.large"
      [type]="colorEnum.primary"
      (datnekdsClickOrTouch)="dialogRef.close()"
    ></datnek-ds-button>
  </div>
</div>
