import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

interface Items {
  label: string;
  description?: string;
}

@Component({
  selector: 'del-shared-dialog-add-signalization',
  templateUrl: './dialog-add-signalization.component.html',
  styleUrls: ['./dialog-add-signalization.component.scss'],
})
export class DialogAddSignalizationComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  //   {
  //     label: 'Nudité',
  //   },
  //   {
  //     label: 'Violence',
  //   },
  //   {
  //     label: 'Harcèlement',
  //   },
  //   {
  //     label: 'Fausses informations',
  //   },
  //   {
  //     label: 'Contenu indésirable',
  //   },
  //   {
  //     label: 'Discours haineux',
  //   },
  //   {
  //     label: 'Terrorisme',
  //   },
  // ];
  menu = true;
  subMenu = false;
  other = false;

  nudite: Items[] = [
    {
      label: 'La nudité est assez explicite',
    },
    {
      label: 'Les parties intimes du corps sont affichées',
    },
    {
      label: 'Actes sexuels',
    },
    {
      label: 'Exploitations sexuelles',
    },
    {
      label: "Partage d'images obsènes",
    },
  ];
  violence: Items[] = [
    {
      label: 'Images violentes',
    },
    {
      label: 'Images de cadavres',
    },
    {
      label: 'Images de blessés graves',
    },
    {
      label: 'Violence sexuelle',
    },
  ];
  harcelement: Items[] = [
    {
      label: 'Je suis la victime',
    },
    {
      label: 'Un(e) ami(e) est la victime',
    },
  ];
  fausses_infos: Items[] = [
    {
      label: 'Fausses informations sue la santé',
    },
    {
      label: 'Fausses informations sur la politique',
    },
  ];
  indesirable: Items[] = [
    {
      label: 'La nudité est assez explicite',
    },
    {
      label: 'Les parties intimes du corps sont affichées',
    },
  ];

  constructor(public ref: DialogRef) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  otherReason() {
    this.other = !this.other;
  }

  showSubmenu() {
    this.subMenu = !this.subMenu;
    this.menu = false;
  }

  close() {
    this.ref.close(null);
  }
}
