
<div
  class="show-gallery gallery-publication-lightbox stories"
  
>
  <div class="d-flex w-100 flex-column flex-md-row">
    
    <ng-container *ngIf="currentLightboxImage">
      <div class="lightbox-view" [class.no-comment]="noComment">
        <span *ngIf="showCount" class="count"
          >{{ currentIndex + 1 }}/{{ totalImageCount }} |
          {{ currentLightboxImage.title }}</span
        >
        <!--CLOSE-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.solidinboxclose"
          [type]="colorEnum.secondary"
          class="close-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="ref.close()"
        ></datnek-ds-button-icon>

        <!--ZOOM IN-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinezoomIn"
          [type]="colorEnum.secondary"
          class="zoomin-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="zoomInTemplate()"
        ></datnek-ds-button-icon>

        <!--ZOOM OUT-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinezoomOut"
          [type]="colorEnum.secondary"
          class="zoomout-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="zoomOutTemplate()"
        ></datnek-ds-button-icon>

        <!--FAVORITE-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlineheart"
          [type]="colorEnum.secondary"
          class="favorite-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="Like()"
          *ngIf="liked === false"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [Icon]="iconEnum.solidheart"
          [type]="colorEnum.secondary"
          class="favorite-btn btn-gallery"
          [round]="true"
          [fade]="true"
          (datnekdsClickOrTouch)="Like()"
          *ngIf="liked === true"
        ></datnek-ds-button-icon>

        <!--PREVIOUS-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinechevronLeft"
          *ngIf="controls && previewImage"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="prev()"
          class="lightbox-carousel btn-gallery btn-prev"
          [round]="true"
          [fade]="true"
        ></datnek-ds-button-icon>

        <!--PREVIOUS-->
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinechevronRight"
          *ngIf="controls && previewImage"
          [type]="colorEnum.secondary"
          (datnekdsClickOrTouch)="next()"
          class="lightbox-carousel btn-gallery btn-next"
          [round]="true"
          [fade]="true"
        ></datnek-ds-button-icon>

        <!--IF IMAGES ITEMS -->
         <ng-container *ngIf="currentLightboxImage">
          <div *ngIf="previewImage" class="lightbox-img">
            <!-- <h5 class="title-story text-center text-white">
                {{ currentLightboxImage.title }}
              </h5>-->
            <img
              [src]="currentLightboxImage.imageSrc"
              alt="{{ currentLightboxImage.imageAlt }}"
              [style]="zoom()"
            />
          </div>
         </ng-container>
        

        <!--IF VIDEOS ITEMS -->
         <ng-container *ngIf="currentLightboxVideo">
          <div *ngIf="previewVideo" class="lightbox-img">
            <video
              class="embed-responsive embed-responsive-16by9"
              autoplay="false"
              controls
            >
              <source [src]="currentLightboxVideo.videoSrc" />
            </video>
          </div>
         </ng-container>
        

      </div>
    </ng-container>

    <!--COMMENTAIRES BOX-->
    <div class="comments-view d-flex align-items-center justify-content-center bg-white position-relative" [class.no-comment]="noComment">
      <ng-container *ngIf="componentCommentLightBox">
        <ng-container
          *ngComponentOutlet="
            componentCommentLightBox;
            injector: componentCommentLightBoxInjector
          "
        ></ng-container>
      </ng-container>
      <datnek-ds-button-icon [Icon]="iconEnum.outlinex" [type]="colorEnum.secondary" [round]="true" class="icon-close" (datnekdsClickOrTouch)="removeComments()"></datnek-ds-button-icon>
        <div class="display-2">Liste des commentaires ici</div>
      <!--datnek-ds-comment-lightbox></datnek-ds-comment-lightbox-->
    </div>
  </div>
</div>

