<div class="menu-mobile z-150">
  <div class="mobile-menu-icon px-3" *ngIf="!isLoading">
    <datnek-ds-button-icon
      [type]="colorEnum.ghost"
      [Icon]="iconEnum.outlinehome"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="home()"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [type]="colorEnum.ghost"
      [size]="sizeEnum.large"
      [Icon]="iconEnum.outlineofficeBuilding"
      (datnekdsClickOrTouch)="enterprise()"
    ></datnek-ds-button-icon>
    <!--<datnek-ds-button-icon
      [type]="colorEnum.ghost"
      [Icon]="iconEnum.outlinebriefcase"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="link('https://dev-jobs.delenscio.be')"
    ></datnek-ds-button-icon>-->
    <!--<datnek-ds-button-icon
      [type]="colorEnum.ghost"
      [Icon]="'bell'"
      [size]="sizeEnum.large"
    ></datnek-ds-button-icon>-->
    <datnek-ds-button-icon
      [type]="colorEnum.ghost"
      [Icon]="iconEnum.outlineuserGroup"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="groups()"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [type]="colorEnum.ghost"
      [Icon]="iconEnum.outlinechatAlt2"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="chat()"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [type]="colorEnum.unstyled"
      [Icon]="iconEnum.outlineApps"
      [size]="sizeEnum.large"
      (datnekdsClickOrTouch)="showmenuLeft()"
      class="apps"
    ></datnek-ds-button-icon>
  </div>

  <div
    class="
      mobile-menu-icon
      skeleton
      d-flex
      justify-content-around
      gap-3
      px-3
      py-2
      ng-star-inserted
      bg-white
    "
    *ngIf="isLoading"
  >
    <div class="square medium-square" style="flex: unset"></div>
    <div class="square medium-square" style="flex: unset"></div>
    <div class="square medium-square" style="flex: unset"></div>
    <div class="square medium-square" style="flex: unset"></div>
    <div class="square medium-square" style="flex: unset"></div>
  </div>

  <p-sidebar
    [(visible)]="menuLeft"
    [baseZIndex]="10000"
    [showCloseIcon]="false"
    [autoZIndex]="false"
  >
    <div
      class="
        header-sidebar
        mb-3
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <img
        src="/assets/Img/Logo%20Delenscio%20final.png"
        alt=""
        style="width: 110px"
        (datnekdsClickOrTouch)="home()"
      />

      <datnek-ds-button-icon
        [type]="colorEnum.ghost"
        [Icon]="iconEnum.solidinboxclose"
        [size]="sizeEnum.medium"
        [round]="true"
        (datnekdsClickOrTouch)="showmenuLeft()"
      ></datnek-ds-button-icon>
    </div>

    <div class="content-sidebar">
      <nk-shared-home-menu-mobile
        *ngIf="router.url === '/'"
      ></nk-shared-home-menu-mobile>
      <nk-shared-contact-menu-mobile
        *ngIf="router.url === '/contacts'"
      ></nk-shared-contact-menu-mobile>
      <nk-shared-enterprise-menu-mobile
        *ngIf="router.url === '/enterprise'"
      ></nk-shared-enterprise-menu-mobile>
      <nk-shared-enterprise-list-menu-mobile
        *ngIf="router.url === '/enterprise/list-enterprises'"
      ></nk-shared-enterprise-list-menu-mobile>
      <nk-shared-event-menu-mobile
        *ngIf="router.url === '/events' || router.url === '/events/calendar-view' || router.url === '/events/list-view' "
      ></nk-shared-event-menu-mobile>
      <nk-shared-chat-menu-mobile
        *ngIf="router.url === '/chat' || router.url === '/chat/home'"
      ></nk-shared-chat-menu-mobile>
      <nk-shared-invitations-menu-mobile
        *ngIf="router.url === '/request/invitation'"
      ></nk-shared-invitations-menu-mobile>
      <nk-shared-friends-menu-mobile
        *ngIf="router.url === '/request/friends'"
      ></nk-shared-friends-menu-mobile>
      <nk-shared-suggestions-menu-mobile
        *ngIf="router.url === '/request/suggestions'"
      ></nk-shared-suggestions-menu-mobile>
      <nk-shared-saved-messages-menu-mobile
        *ngIf="router.url === '/saved-messages'"
      ></nk-shared-saved-messages-menu-mobile>
      <nk-shared-notifications-menu-mobile
        *ngIf="router.url === '/notifications'"
      ></nk-shared-notifications-menu-mobile>
      <nk-shared-following-menu-mobile
        *ngIf="router.url === '/request/following'"
      ></nk-shared-following-menu-mobile>
      <nk-shared-followers-menu-mobile
        *ngIf="router.url === '/request/followers'"
      ></nk-shared-followers-menu-mobile>
      <nk-shared-groups-menu-mobile
        *ngIf="router.url === '/groups' || router.url === '/groups/home-group'"
      ></nk-shared-groups-menu-mobile>
      <nk-shared-single-group-menu-mobile
        [menuLeft]="menuLeft"
        (onClick)="showmenuLeft()"
        *ngIf="
          router.url === '/groups/single-group' ||
          router.url === '/groups/single-group/group-hashtags' ||
          router.url === '/groups/single-group/group-hashtags/single-hashtag' ||
          router.url === '/groups/single-group/group-stats/post-pending' ||
          router.url === '/groups/single-group/group-stats/post-moderation' ||
          router.url ===
            '/groups/single-group/group-stats/post-signalization' ||
          router.url === '/groups/single-group/group-stats/adhesion-list' ||
          router.url === '/groups/single-group/group-stats/home-group-stats' ||
          router.url === '/groups/single-group/group-stats/validation-user' ||
          router.url === '/groups/single-group/group-discussion' ||
          router.url === '/groups/single-group/group-users' ||
          router.url === '/groups/single-group/group-multimedia' ||
          router.url === '/groups/single-group/group-hashtags/home-hashtags' ||
          router.url === '/groups/single-group/group-hashtags/single-hashtag' ||
          router.url ===
            '/groups/single-group/group-settings/home-group-settings' ||
          router.url === '/groups/single-group/group-settings/rules' ||
          router.url === '/groups/single-group/group-settings/quizz' ||
          router.url === '/groups/single-group/group-settings/customize' ||
          router.url === '/groups/single-group/group-settings/security'
        "
      ></nk-shared-single-group-menu-mobile>
      <nk-shared-challenge-menu-mobile
        (onClick)="showmenuLeft()"
        *ngIf="
          router.url === '/challenge' ||
          router.url === '/challenge/list' ||
          router.url === '/challenge/challenges-accepted' ||
          router.url === '/challenge/your-challenges' ||
          router.url === '/challenge/my-challenge-name' ||
          router.url === '/challenge/my-challenge-name-finished' ||
          router.url === '/challenge/my-challenge-name-in-progress' ||
          router.url === '/challenge/challenge-name-in-progress' ||
          router.url === '/challenge/challenge-name-finished' ||
          router.url === '/challenge/challenge-name' ||
          router.url === '/challenge/accepted-challenge-name'
        "
      ></nk-shared-challenge-menu-mobile>
      <nk-shared-challenge-stats-menu-mobile
        *ngIf="router.url === '/challenge/challenge-stats'"
      ></nk-shared-challenge-stats-menu-mobile>
      <nk-shared-single-article-mobile
        *ngIf="router.url === '/single-publication'"
      ></nk-shared-single-article-mobile>
    </div>
  </p-sidebar>
</div>

<!--CHAT LIST BOX-->

<div class="chat-list-box shadow-sm" [class.show]="status === true">
  <div
    class="
      header-chat-list
      d-flex
      align-items-center
      justify-content-between
      px-3
    "
  >
    <div triggers="click:blur">
      <datnek-ds-avatar
        [size]="sizeEnum.small"
        [border]="false"
        class="mr-2"
      ></datnek-ds-avatar>
      <span class="display-4" triggers="click:blur">Messagerie</span>
    </div>
    <div class="text-right">
      <datnek-ds-svg-icon-white
        [icon]="iconEnum.outlineWhitepencilAlt"
        class="mr-1 pointer"
        (datnekdsClickOrTouch)="goToChatMessage($event)"
      ></datnek-ds-svg-icon-white>
      <datnek-ds-svg-icon-white
        [icon]="iconEnum.outlineWhitechevronDown"
        (datnekdsClickOrTouch)="showStatus()"
        class="pointer"
      ></datnek-ds-svg-icon-white>
    </div>
  </div>
  <div class="searchbox p-2 bg-white">
    <datnek-ds-search-input
      placeholder="Rechercher un profil"
    ></datnek-ds-search-input >
  </div>
  <div class="body-chat-list p-2">
    <nk-shared-user-chat-list
      [userData]="dataUsersChat"
      (datnekdsClickOrTouch)="goToChatMessage($event)"
      class="d-flex flex-column d-md-none"
    ></nk-shared-user-chat-list>
  </div>
</div>
