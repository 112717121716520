/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Router } from '@angular/router';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import {
  DialogService,
  AvatarTypeEnum,
  ControlSizeEnum,
  IconEnum,
  ControlTypeColorEnum,
  DialogSizeEnum,
  DialogTypeEnum,
} from 'datnek-ds';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DialogAddSignalizationComponent, DialogDeleteCommentComponent } from '../../../../dialogs';

interface CommentsItems {
  imageSrc: string;
  name: string;
  since: string;
  desc: string;
  readMoreComment: boolean;
  multimedia: string;
}

interface ImagesItems {
  imageSrc: string;
  imageAlt: string;
  title: string;
  date: string;
}

interface VideosItems {
  videoSrc: string;
  title: string;
  date: string;
}

@Component({
  selector: 'del-shared-comment-pushed',
  templateUrl: './comment-pushed.component.html',
  styleUrls: ['./comment-pushed.component.scss'],
  providers: [NgbDropdown],
})
export class CommentPushedComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  readMore = false;
  addComment = false;
  respond = false;
  moreComments = false;
  followed = false;

  galleryItems: ImagesItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
  ];

  dataVideosGallery: VideosItems[] = [
    {
      videoSrc:
        'https://static.videezy.com/system/resources/previews/000/000/168/original/Record.mp4',
      title: "Diplome d'Ingénieur en Analyse de Données",
      date: '12 juin 2021',
    },
  ];

  liked = false;

  dataSubComments: CommentsItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/comment-4.png',
      name: 'Ulrich Guagang 2',
      since: '3m',
      desc: `Nulla quis lorem ut libero malesuada feugiat.`,
      readMoreComment: false,
      multimedia: 'images',
    },
  ];

  linkDescription = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;
  descriptionPage = `Lorem ipsum dolor sit amet consectetur, adipisicing elit.
  Cum, cupiditate similique! Exercitationem modi dicta animi,
  voluptates repellat excepturi reiciendis`;
  imported = false;
  import = false;
  follow = false;

  @Input()
  longText = `Nulla quis lorem ut libero malesuada feugiat. Nulla porttitor accumsan tincidunt. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec rutrum congue leo eget malesuada. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vivamus suscipit tortor eget felis porttitor volutpat. Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem ut libero malesuada feugiat.`;

  text = `Lorem
  <strong
    >ipsum dolor sit amet, consectetur adipiscing elit. Quisque lorem ligula,
    lacinia a justo sed, porttitor vulputate risus. In non feugiat risus. Sed
    vitae urna nisl. Duis suscipit volutpat sollicitudin </strong
  >. Donec ac massa elementum massa condimentum mollis. Pellentesque habitant
  morbi tristique senectus et netus et malesuada fames ac turpis egestas.
  Nulla sollicitudin sapien at enim sodales dapibus. Pellentesque sed nisl eu
  sem aliquam tempus nec ut leo. Quisque rutrum nulla nec aliquam placerat.
  Fusce a massa ut sem egestas imperdiet. Sed sollicitudin id dolor egestas
  malesuada. Quisque placerat lobortis ante, id ultrices ipsum hendrerit nec.
  Quisque quis ultrices erat.Nulla gravida ipsum nec sapien pellentesque
  pharetra. Suspendisse egestas aliquam nunc vel egestas. Nullam scelerisque
  purus interdum lectus consectetur mattis. Aliquam nunc erat, accumsan ut
  posuere eu, vehicula consequat ipsum. Fusce vel ex quis sem tristique
  imperdiet vel in. posuere eu, vehicula consequat ipsum. Fusce vel ex quis sem tristique
  imperdiet vel in mi. Cras leo orci, fermentum vitae volutpat vitae,
  convallis semper libero. Phasellus a volutpat diam. Ut pulvinar purus felis,
  eu vehicula enim aliquet vitae. Suspendisse quis lorem facilisis ante
  interdum euismod et vitae risus. Vestibulum varius nulla et enim malesuada
  fringilla.`;

  @Input() commentData: CommentsItems[] = [];

  constructor(
    private overlay: Overlay,
    private modalService: DialogService,
    private myDropDown: NgbDropdown,
    private router: Router
  ) {}

  ngOnInit(): void {}

  link(url: string) {
    window.open(url, '_blank');
  }

  onHover(event: any): void {
    this.myDropDown.toggle();
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  addSignalization() {
    const ref = this.modalService.open(DialogAddSignalizationComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Signaler le commentaire`,
        size: DialogSizeEnum.small,
      },
    });
  }

  deleteComment() {
    const ref = this.modalService.open(DialogDeleteCommentComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer le commentaire`,
        size: DialogSizeEnum.small,
      },
    });
  }

  onAddComment(): void {
    this.addComment = !this.addComment;
  }

  onRespond(): void {
    this.respond = !this.respond;
  }
  onReadMore() {
    this.readMore = !this.readMore;
  }

  showMoreComments(): void {
    this.respond = !this.respond;
    this.moreComments = !this.moreComments;
  }

  onLiked(): void {
    this.liked = !this.liked;
  }

  goToHome(e: any) {
    this.router.navigate(['/']);
  }
}
