import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiComp} from "./ui/ui-component";
import {DatnekDsAtomModule,
  DatnekDsSharedModule,
  DatnekDsUploadFileModule,
  DatnekDsDialogModule,
  DatnekDsDialogUtilisModule,
  DatnekDsCropperModule,
  DatnekDsMoleculeModule,
  DatnekDsGalleryModule} from "datnek-ds";
import {MenuShareModule} from "./ui";
import {FormlyModule} from "@ngx-formly/core";
import {DatnekSsoModule} from "datnek-sso";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CarouselModule} from "ngx-owl-carousel-o";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DatnekSkeletonNetworkModule } from 'datnek-skeleton';
import { SharedRoutingModule } from './shared-routing.module';
import { LyDialogRef } from '@alyle/ui/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppsSolutionsTabsComponent } from './ui/pages/home-default/apps-solutions-tabs/apps-solutions-tabs.component';
//import { NetworkSharedModule } from '@network-shared';
//import { MenuHomeMobileModule } from '@home-shared';

@NgModule({
  imports: [CommonModule,
    OverlayModule,
    //NetworkSharedModule,
    SharedRoutingModule,
    DatnekDsGalleryModule,
    DatnekDsSharedModule,
    DatnekDsAtomModule,
    DatnekDsMoleculeModule,
    DatnekDsUploadFileModule,
    DatnekDsDialogModule,
    DatnekDsDialogUtilisModule,
    DatnekDsCropperModule,
    DatnekSkeletonNetworkModule,
    MenuShareModule,
    DatnekSsoModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    SlickCarouselModule,
    CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
    }),
    FormlyModule],
  declarations: [...UiComp, AppsSolutionsTabsComponent ],
  exports: [...UiComp, MenuShareModule, DatnekDsSharedModule],
  providers: [{ provide: LyDialogRef, useValue: {} }],
})
export class SharedModule {}
