
import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';


@Component({
  selector: 'del-shared-dialog-accept-challenge-modal',
  templateUrl: './dialog-accept-challenge-modal.component.html',
  styleUrls: ['./dialog-accept-challenge-modal.component.scss'],
})
export class DialogAcceptChallengeModalComponent {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(
    public dialogRef: DialogRef,
    private modalService: DialogService
  ) {}
  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }
}
