import { Component, Inject, OnInit, PLATFORM_ID  } from '@angular/core';
import { Router } from '@angular/router';
import Aos from 'aos'; //AOS - 1
import { OwlOptions } from 'ngx-owl-carousel-o';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  assetUrl,
} from 'datnek-ds';
import { Environment } from 'datnek-core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'del-shared-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="assets/icons/src/outline/white/chevron-left.svg" class="arrow-slide">',
      '<img src="assets/icons/src/outline/white/chevron-right.svg" class="arrow-slide">',
    ],
    items: 1,
    nav: true,
  };

  sectionDetails = [
    {
      imgSrc: assetUrl('Img/homepage/de-network.png'),
      title: 'Bouw een kwalitatief professioneel netwerk op',
      description: `
        <p class="txt-20 animate__animated animate__fadeInUp animate__fast">
          Bouw een kwalitatief professioneel netwerk op met onze app. Of u nu een MKB-bedrijf, een startup, een ondernemer of een professional bent die op zoek is naar nieuwe kansen, ons platform stelt u in staat in contact te komen met talenten en experts in uw vakgebied. Gebruik onze geavanceerde matchingtools om de juiste profielen te vinden en sterke professionele relaties op te bouwen. Kom vandaag nog bij ons werken en versterk uw netwerk voor een succesvolle professionele toekomst.
        </p>
        <h3 class="mb-2 ttu">Authenticiteit en vertrouwen</h3>
        <p>
          Het opbouwen van oprechte en authentieke relaties is cruciaal. Wees jezelf en toon oprechte interesse in anderen. Vertrouwen wordt opgebouwd op basis van eerlijkheid en betrouwbaarheid. Deel uw ervaringen en wees bereid anderen te helpen, wat duurzame verbindingen zal bevorderen.
        </p>
        <h3 class="mb-2 ttu">Diversiteit en complementariteit</h3>
        <p>
          Zoek naar mensen met verschillende vaardigheden, ervaringen en perspectieven. Deze diversiteit verrijkt je kennis en biedt je veel mogelijkheden en creatieve oplossingen.
        </p>
        <h3 class="mb-2 ttu">Wederkerigheid en uitwisseling</h3>
        <p>
          Wees bereid om net zoveel te geven als je ontvangt. Deel nuttige informatie, bied hulp en sta open voor samenwerkingen. Wederzijdse relaties creëren een omgeving van wederzijdse steun en collectieve groei.
        </p>
      `,
      id: 'reseau-pro'
    },
    {
      imgSrc: assetUrl('Img/homepage/de-testing.png'),
      title: 'Verbeter uw profiel door uw vaardigheden te testen',
      description: `
        <p class="txt-20 animate__animated animate__fadeInUp animate__fast">
          Markeer uw profiel door uw vaardigheden te testen met onze applicatie. Of u nu een kmo, een startup, een ondernemer of een professional bent die op zoek is naar nieuwe kansen, ons platform biedt u vaardigheidstesten die zijn gemaakt en gevalideerd door experts. Met deze inzichtelijke en eenvoudige assessments kunt u uw capaciteiten aantonen en opvallen bij recruiters. Sluit u vandaag nog aan en maximaliseer uw potentieel door uw vaardigheden nauwkeurig en effectief onder de aandacht te brengen.
        </p>
        <h3 class="mb-2 ttu">Quiz</h3>
        <p>
          Markeer uw profiel door uw vaardigheden te testen via onze quiz. Of u nu een kmo, een startup, een ondernemer of een professional bent die op zoek is naar nieuwe kansen, met onze applicatie kunt u vaardighedenquizzen doen die zijn gemaakt en gevalideerd door experts. Met deze inzichtelijke en eenvoudige tests kunt u uw capaciteiten aantonen en opvallen bij recruiters. Doe vandaag nog mee en gebruik onze quizzen om uw potentieel te maximaliseren en uw vaardigheden nauwkeurig en effectief te verbeteren.
        </p>
        <h3 class="mb-2 ttu">Praktisch werk</h3>
        <p>
          Of u nu een kmo, een startup, een ondernemer of een professional bent die op zoek is naar nieuwe kansen, met onze applicatie kunt u praktijkbeoordelingen afleggen die zijn gemaakt en gevalideerd door experts. Met deze concrete en boeiende tests kunt u uw echte capaciteiten aantonen en opvallen bij recruiters. Sluit u vandaag nog aan en gebruik onze practica om uw potentieel te maximaliseren en uw vaardigheden nauwkeurig en effectief onder de aandacht te brengen.
        </p>
        <h3 class="mb-2 ttu">Project</h3>
        <p>
          Benadruk uw profiel door uw vaardigheden te testen via projecten. Of u nu een kmo, een startup, een ondernemer of een professional bent die op zoek is naar nieuwe kansen, met onze applicatie kunt u beoordelingen maken op basis van projecten die zijn gemaakt en gevalideerd door experts. Met deze praktische en meeslepende tests kunt u uw concrete vaardigheden aantonen en opvallen bij recruiters. Kom vandaag nog bij ons werken en gebruik onze projecten om uw potentieel te maximaliseren en uw vaardigheden nauwkeurig en effectief te laten zien.
        </p>
      `,
      id: 'test'
    },
    {
      imgSrc: assetUrl('Img/homepage/de-jobs.png'),
      title: 'Favoriet platform voor het zoeken naar werk',
      description: `
        <p class="txt-20 animate__animated animate__fadeInUp animate__fast">
          Notre plateforme maintes fois récompensée s'adapte à chaque type d'investisseur, en combinant une interface intuitive avec un large choix de fonctionnalités.
        </p>
        <h3 class="mb-2 ttu">Intuitve</h3>
        <p>
          Notre fenêtre vous permettant de passer vos ordres de manière intuitive et rapide est toujours accessible. Il vous suffit d'indiquer le nombre d'unités voulues ou le montant total que vous souhaitez investir.
        </p>
        <h3 class="mb-2 ttu">Intuitve</h3>
        <p>
          Notre fenêtre vous permettant de passer vos ordres de manière intuitive et rapide est toujours accessible. Il vous suffit d'indiquer le nombre d'unités voulues ou le montant total que vous souhaitez investir.
        </p>
        <h3 class="mb-2 ttu">Intuitve</h3>
        <p>
          Notre fenêtre vous permettant de passer vos ordres de manière intuitive et rapide est toujours accessible. Il vous suffit d'indiquer le nombre d'unités voulues ou le montant total que vous souhaitez investir.
        </p>
      `,
      id: 'emploi'
    },
    {
      imgSrc: assetUrl('Img/homepage/de-learning.png'),
      title: 'Favoriet platform voor het zoeken naar werk',
      description: `
        <p class="txt-20 animate__animated animate__fadeInUp animate__fast">
          Notre plateforme maintes fois récompensée s'adapte à chaque type d'investisseur, en combinant une interface intuitive avec un large choix de fonctionnalités.
        </p>
        <h3 class="mb-2 ttu">Intuitve</h3>
        <p>
          Notre fenêtre vous permettant de passer vos ordres de manière intuitive et rapide est toujours accessible. Il vous suffit d'indiquer le nombre d'unités voulues ou le montant total que vous souhaitez investir.
        </p>
        <h3 class="mb-2 ttu">Intuitve</h3>
        <p>
          Notre fenêtre vous permettant de passer vos ordres de manière intuitive et rapide est toujours accessible. Il vous suffit d'indiquer le nombre d'unités voulues ou le montant total que vous souhaitez investir.
        </p>
        <h3 class="mb-2 ttu">Intuitve</h3>
        <p>
          Notre fenêtre vous permettant de passer vos ordres de manière intuitive et rapide est toujours accessible. Il vous suffit d'indiquer le nombre d'unités voulues ou le montant total que vous souhaitez investir.
        </p>
      `,
      id: 'elearning'
    },
  ];

  sectionTitles = [
    {
      title: 'Professioneel netwerk',
      icon: assetUrl('Img/homepage/Orion_social.svg'),
      id: 'reseau-pro'
    },
    {
      title: 'Vaardigheidstest',
      icon: assetUrl('Img/homepage/Test_competence.svg'),
      id: 'test'
    },
    {
      title: 'Banen',
      icon: assetUrl('Img/homepage/find-matching-job.svg'),
      id: 'emploi'
    },
    {
      title: 'E-leren',
      icon: assetUrl('Img/homepage/e-learning.svg'),
      id: 'elearning'
    },
  ];

  solutionsImages = [
    {
      imgSrc: assetUrl('Img/homepage/autofill-1x.png'),
      imgAlt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    },
    {
      imgSrc: assetUrl('Img/homepage/autofill-2x.webp'),
      imgAlt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    },
    {
      imgSrc: assetUrl('Img/homepage/autofill-2x.webp'),
      imgAlt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    },
    {
      imgSrc: assetUrl('Img/homepage/autofill-1x.png'),
      imgAlt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    },
  ];

  infos = [
    {
      title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
      description: 'Obcaecati rerum ab pariatur accusamus temporibus animi laudantium sunt deserunt suscipit voluptatibus',
    },
    {
      title: 'Amet consectetur adipisicing elit',
      description: 'Obcaecati rerum ab pariatur accusamus temporibus animi laudantium sunt deserunt suscipit voluptatibus',
    },
    {
      title: 'animi laudantium sunt deserunt suscipit voluptatibus',
      description: 'Obcaecati rerum ab pariatur accusamus temporibus animi laudantium sunt deserunt suscipit voluptatibus',
    },
    {
      title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
      description: 'Obcaecati rerum ab pariatur accusamus temporibus animi laudantium sunt deserunt suscipit voluptatibus',
    },
  ]

  constructor(
    public router: Router,
    @Inject(Environment.Provider.ENVIRONMENT)
    private environment: Environment.Env,
  ) {}

  ngOnInit(): void {
    Aos.init({ disable: 'mobile' }); //AOS - 2
    Aos.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }

  register() {
    this.router.navigateByUrl(this.environment.securityRoute.login);
  }
}
