<ng-container *ngIf="isLoading">
    <!-- <he-user-education-skeleton></he-user-education-skeleton> -->
</ng-container>
<div class="" *ngIf="!isLoading">
  <div class="item-content formation event network border-bottom">
    <div class="event-item gap-16">
      <div class="img">
        <img
          [src]="event.EventImage"
          class=""
          alt="{{ event.Title }}"
        />
      </div>
      <div class="item d-flex flex-column px-3 px-md-0 gap-4">
        <div class="infos d-flex flex-column gap-4">
          <h4
            class="clip ellipsis initial pointer mb-0"
            style="width: 92%;"
            (datnekdsClickOrTouch)="onViewEvent()"
          >
            {{ event.Title }}
          </h4>
          <p class="mb-0 clip ellipsis" style="width: 92%;">{{ event.SubTitle }}</p>
        </div>
        <div class="d-flex flex-column infos-2 justify-content-between flex-wrap flex-lg-nowrap gap-16">
          <div
            class="items-stats d-flex flex-row align-items-start flex-wrap gap-16"
          >
            <div class="button-stats no-style d-flex align-items-center gap-4">
              <img [src]="'icons/home/events/place.svg' | assetUrl" alt="" />
              <span class="stats clip ellipsis"> {{ event.AddressId }}</span>
            </div>
            <div class="button-stats no-style d-flex align-items-center gap-4">
              <img [src]="'icons/home/events/calendar.svg' | assetUrl" alt="" />
              <span class="stats clip ellipsis">
                {{ event.StartDate }} à {{ event.StartTime?.hours }}:{{
                  event.StartTime?.minutes
                }}</span
              >
            </div>
            <div class="button-stats no-style d-flex align-items-center gap-4">
              <img [src]="'icons/home/events/users.svg' | assetUrl" alt="" />
              <span class="stats clip ellipsis">133 particpants</span>
            </div>
          </div>
          <div class="buttons d-flex align-items-end flex-wrap gap-12">
            <datnek-ds-button
              [type]="colorEnum.secondary"
              [size]="sizeEnum.medium"
              [leftIcon]="iconEnum.outlineeye"
              [label]="'En savoir plus'"
              (datnekdsClickOrTouch)="onViewEvent()"
            ></datnek-ds-button>
            <div
              class=""
              (datnekdsClickOrTouch)="openDropdown(subMenuShare, target2)"
              #target2
            >
              <datnek-ds-button
                [type]="colorEnum.secondary"
                [size]="sizeEnum.medium"
                [leftIcon]="iconEnum.outlineshare"
                [label]="'Partager'"
                
              ></datnek-ds-button>
            </div>
            <datnek-ds-button
              [type]="colorEnum.secondary"
              [size]="sizeEnum.medium"
              [leftIcon]="iconEnum.outlineuserGroup"
              [label]="'Participants'"
              (datnekdsClickOrTouch)="onViewParticipants()"
            ></datnek-ds-button>
            <div class="">
              <datnek-ds-button-icon
                [colorIcon]="iconEnum.outlinechatbubbleLeftRight"
                [type]="colorEnum.ghost"
                [round]="false"
                [size]="sizeEnum.medium"
                [badge]="true"
                [count]="9"
                (datnekdsClickOrTouch)="showStatus()"
              ></datnek-ds-button-icon>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>

    <div
      class="icon-dropdown"
      (datnekdsClickOrTouch)="openDropdown(sub, target1)"
      #target1
    >
      <datnek-ds-button-icon
        [colorIcon]="iconEnum.outlinedotsHorizontal"
        [type]="colorEnum.secondary"
        [round]="true"
        [size]="sizeEnum.medium"
      ></datnek-ds-button-icon>
    </div>
  </div>
</div>

<!-- CHAT LIST BOX -->
<!-- <div class="chat-list-box shadow-sm" [class.show]="status === true">
  <div
    class="header-chat-list d-flex align-items-center justify-content-between px-3"
  >
    <div triggers="click:blur">
      <datnek-ds-avatar
        [size]="sizeEnum.small"
        [border]="false"
        class="mr-2"
      ></datnek-ds-avatar>
      <span class="display-4" triggers="click:blur">Messagerie</span>
    </div>
    <div class="text-right">
      <datnek-ds-svg-icon-white
        [icon]="iconEnum.outlineWhitechevronDown"
        (datnekdsClickOrTouch)="showStatus()"
        class="pointer"
      ></datnek-ds-svg-icon-white>
    </div>
  </div>
  <div class="searchbox p-2 bg-white">
    <datnek-ds-search-input
      placeholder="Rechercher un profil"
    ></datnek-ds-search-input>
  </div>
  <ng-container *ngIf="isLoading">
    <datnek-skeleton-suggestion-list
      *ngFor="let in of counter(8); let i = index"
    ></datnek-skeleton-suggestion-list>
  </ng-container>
  <p-scrollPanel
    [style]="{ width: '100%', height: '530px' }"
    *ngIf="!isLoading"
  >
    <div class="body-chat-list p-2">
      <he-shared-user-chat-list
        [userData]="dataUsersChat"
        (openPopupEvent)="registerPopup($event)"
        class="d-none flex-column d-md-flex"
      ></he-shared-user-chat-list>

      <he-shared-user-chat-list
        [userData]="dataUsersChat"
        
        [onChatBox]="true"
        class="d-flex flex-column d-md-none"
      ></he-shared-user-chat-list>
    </div>
  </p-scrollPanel>
</div>

<he-shared-chat-popup-box
  *ngFor="let item of popups"
  [userChat]="item"
  (closePopupEvent)="closePopup($event)"
></he-shared-chat-popup-box> -->

<!-- DROPDOWNS -->
<ng-template #sub let-close="close">
  <div class="datnek-ds-card dropdown-card edit-menu">
    <a
      class="dropdown-item p-1 d-flex gap-10"
      (datnekdsClickOrTouch)="editEvent(); close()"
    >
      <img [src]="'icons/home/edit-icon.svg' | assetUrl" alt="" />Editer
    </a>
    <a
      class="dropdown-item p-1 d-flex gap-10"
      (datnekdsClickOrTouch)="openMask(mask, target2); close()"
      #target2
    >
      <img [src]="'icons/home/mask-icon.svg' | assetUrl" alt="" />Masquer
    </a>
    <a
      class="dropdown-item p-1 d-flex gap-10"
      (datnekdsClickOrTouch)="openDelete(delete, target2); close()"
      #target2
    >
      <img [src]="'icons/home/trash-icon.svg' | assetUrl" alt="" />Supprimer
    </a>
  </div>
</ng-template>

<ng-template #mask let-close="close">
  <div class="datnek-ds-dialog-body p-3">
    <div class="content-modal">
      <div class="form">
        Voulez-vous vraiment cacher cette formation ? Elles ne seront plus
        visibles pour les visiteurs de votre profil.
      </div>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer gap-16">
    <datnek-ds-button
      [type]="colorEnum.secondary"
      [size]="sizeEnum.large"
      [label]="'Annuler'"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [type]="colorEnum.primary"
      [size]="sizeEnum.large"
      [label]="'Oui, marquer'"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</ng-template>

<ng-template #delete let-close="close">
  <div class="datnek-ds-dialog-body p-3">
    <div class="content-modal">
      <div class="form">
        Voulez-vous vraiment supprimer cette formation ? Elle sera définitvement
        supprimée du système.
      </div>
    </div>
  </div>
  <div class="datnek-ds-dialog-footer gap-16">
    <datnek-ds-button
      [type]="colorEnum.secondary"
      [size]="sizeEnum.large"
      [label]="'Annuler'"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
    <datnek-ds-button
      [type]="colorEnum.danger"
      [size]="sizeEnum.large"
      [label]="'Oui, supprimer'"
      (datnekdsClickOrTouch)="close()"
    ></datnek-ds-button>
  </div>
</ng-template>
<ng-template #subMenuShare let-close="close">
  <div class="datnek-ds-card dropdown-card menu-home edit-menu">
    <a class="dropdown-item p-1 d-flex gap-10" (datnekdsClickOrTouch)="close()">
      <img [src]="'icons/home/sharing/facebook-f.svg' | assetUrl" alt=""> Partager sur facebook
    </a>
    <a class="dropdown-item p-1 d-flex gap-10" (datnekdsClickOrTouch)="close()">
      <img [src]="'icons/home/sharing/twitter.svg' | assetUrl" alt=""> Partager sur Twitter
    </a>
    <a class="dropdown-item p-1 d-flex gap-10" (datnekdsClickOrTouch)="showToast(copy); close()">
      <img [src]="'icons/home/sharing/link.svg' | assetUrl" alt=""> Copier le lien
    </a>
    <a class="dropdown-item p-1 d-flex gap-10" (datnekdsClickOrTouch)="sharePostWithEdit(); close()">
      <img [src]="'icons/home/sharing/delenscio-link.svg' | assetUrl" alt=""> Partager sur Delenscio
    </a>
  </div>
</ng-template>

<!-- TOASTS MESSAGE -->
<ng-template #copy let-close="close">
  Le lien a boen été copié
</ng-template>
