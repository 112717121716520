<ng-container *ngIf="jobData.length && !isLoading">
  <div
    class="job-list-item pointer d-flex align-items-start px-3 py-1 d-block"
    *ngFor="let data of jobData; let i = index"
    (datnekdsClickOrTouch)="redirectToJobs()"
  >
    <img src="{{ data.imageSrc }}" alt="" class="mr-2" />

    <div class="infos">
      <div class="name d-flex flex-column">
        <div class="display-3 truncate_2 mb-1">{{ data.jobTitle }}</div>
        <span class="display-5 truncate_1 mb-1">{{ data.society }}</span>
        <span id="time" class="display-8 mb-2">
          {{ data.time }}
        </span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngFor="let in of counter(3); let i = index">
  <datnek-skeleton-job-list *ngIf="isLoading"></datnek-skeleton-job-list>
</ng-container>
