import {
  animate,
  style,
  transition,
  trigger,
  AnimationEvent,
} from '@angular/animations';
import { Component, Input, OnInit, Output } from '@angular/core';
import { IconEnum, ControlSizeEnum, ControlTypeColorEnum, DialogService, DialogTypeEnum } from 'datnek-ds';
import { DialogShowMediasComponent } from '../../../dialogs';



interface ImagesItems {
  imageSrc: string;
  imageAlt: string;
  title: string;
  date: string;
}

const FULLSCREEN_DIALOG = {
  width: '100vw',
  height: '100vh',
  borderRadius: 0,
  backgroundColor: '#ffffff',
};

@Component({
  selector: 'del-shared-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({ transform: 'scale(0.5)' }),
        animate('150ms', style({ transform: 'scale(1)' })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0.8 })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
  ],
})
export class ImageGalleryComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;

  @Input() closable = false;

  @Input()
  isLoading = true;

  /**
   * La hauteur de la miniature
   */
  @Input()
  heightThumb = 80;

  closed = false;

  @Input()
  imagesItems: ImagesItems[] = [
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-6.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 novembre 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-3.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '13 septembre 2015',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-6.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 novembre 2011',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-4.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur backend',
      date: '13 juillet 2016',
    },
    {
      imageSrc: 'assets/Img/gallery-publication/gallery-5.jpg',
      imageAlt: 'Diplome Sorbone',
      title: 'Diplome de Développeur front-end',
      date: '06 mai 2011',
    },
  ];
  @Input() showCount = true;
  @Input() totalImg!: (__number: number) => number;

  /**
   * Si la galleries 'affiche dans les pages de groupe
   *
   * @boolean
   */
  @Input() isgroupMultimedia = false;

  galleryClass = '';

  @Input() set init(value: ImagesItems[]) {
    this.galleryClass =
      value.length === 1
        ? 'one-img'
        : value.length === 2
        ? 'two-img'
        : value.length === 3
        ? 'three-img'
        : value.length === 4
        ? 'four-img'
        : value.length === 5
        ? 'five-img'
        : 'img-more';
    this.imagesItems = [...value];
  }

  numberImg = this.imagesItems.slice(0, 5);

  @Input()
  previewImage = false;

  @Input()
  isList = false;

  showMask = false;
  currentLightboxImage: ImagesItems = this.imagesItems[0];

  @Input()
  currentIndex = 0;

  @Input()
  controls = true;

  @Input()
  totalImageCount = 0;

  constructor(private modalService: DialogService) {}

  ngOnInit(): void {
    this.totalImageCount = this.imagesItems.length;
    //this.numberImage();
    console.log('NOMBRE DIMAGES :' + this.numberImg);
    setTimeout(() => (this.isLoading = false), 2000);
  }

  close() {
    this.closed = true;
  }

  openPreviewImg(index: number): void {
    this.showMask = true;
    this.previewImage = true;
    this.currentIndex = index;
    this.currentLightboxImage = this.imagesItems[index];
  }

  @Output()
  showImages(index: number) {
    const ref = this.modalService.open(DialogShowMediasComponent, {
      dialogType: DialogTypeEnum.Gallery,
      data: {
        title: `show gallery`,
        previewImage: true,
        controls: true,
        currentIndex: this.currentIndex,
        imagesItems: this.imagesItems,
      },
    });
    ref.afterClosed$.subscribe((result) => console.log(result));
  }

  onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.showMask = false;
    }
  }

  onClosePreview() {
    this.previewImage = false;
    this.showMask = false;
  }

  @Output()
  prev(): void {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.imagesItems.length - 1;
    }
    this.currentLightboxImage = this.imagesItems[this.currentIndex];
  }

  @Output()
  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.imagesItems.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLightboxImage = this.imagesItems[this.currentIndex];
  }

  getThumbHeight() {
    return {
      height: this.heightThumb,
    };
  }
}
