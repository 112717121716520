import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  DialogRef,
  AvatarTypeEnum,
} from 'datnek-ds';

@Component({
  selector: 'del-shared-dialog-challenge-impossible-access',
  templateUrl: './dialog-challenge-impossible-access.component.html',
  styleUrls: ['./dialog-challenge-impossible-access.component.scss'],
})
export class DialogChallengeImpossibleAccessComponent {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  constructor(public ref: DialogRef) {}

  close() {
    this.ref.close();
  }
}
