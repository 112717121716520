<div
  class="nk-shared-user-infos-small suggestion-list d-flex align-items-center p-3"
  *ngFor="let data of userData; let i = index"
>
  <datnek-ds-avatar
    [size]="sizeEnum.medium1"
    imgSrc="{{ data.imageSrc }}"
    class="mr-3"
    [type]="status"
  ></datnek-ds-avatar>

  <!-- IF TYPE === SOCIETY -->
  <ng-container *ngIf="status === 'society'">
    <a
      class="pointer infos d-block w-100"
      target="_blank"
      href="https://dev-home.delenscio.be/user/enterprise/private/about/general-informations"
    >
      <div class="d-flex flex-column">
        <div class="d-flex">
          <div class="name display-4 d-inline mr-2">
            <div class="clip ellipsis">{{ data.society }}</div>
            <img src="assets/icons/src/check-color.png" alt="" />
          </div>
        </div>
        <span>{{ data.sector }}</span>
      </div>
    </a>

    <datnek-ds-button-icon
      [type]="colorEnum.primary"
      [size]="sizeEnum.medium"
      [Icon]="iconEnum.outlineWhiteofficeAdd"
      (datnekdsClickOrTouch)="isFollowed(i)"
      *ngIf="follow === false && status === 'society'"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [type]="colorEnum.secondary"
      [size]="sizeEnum.medium"
      [Icon]="iconEnum.outlinecheck"
      (datnekdsClickOrTouch)="isFollowed(i)"
      *ngIf="follow === true && status === 'society'"
    ></datnek-ds-button-icon>
  </ng-container>

  <!-- IF STATUS === PERSON -->
  <ng-container *ngIf="status === 'person'">
    <div class="infos pointer p-0" target="_blank">
      <ng-container (datnekdsClickOrTouch)="gotoProfile($event)" *ngIf="link">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center" style="width: 97%;">
            <div class="name display-2 clip ellipsis mr-2">
              <div class="clip ellipsis mb-1">{{ data.name }}</div> 
            </div>
            <img style="margin-top: -6px" src="assets/icons/src/check-color.png" alt="" />
          </div>
          <span>{{ data.professionalTitle }} à {{ data.society }}</span>
          <span id="relations" class="display-8">
            {{ data.relations }} relations en commun
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!link">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center" style="width: 97%;">
            <div class="name display-2 clip ellipsis mr-2">
              <div class="clip ellipsis mb-1">{{ data.name }}</div> 
            </div>
            <img style="margin-top: -6px" src="assets/icons/src/check-color.png" alt="" />
          </div>
          <span>{{ data.professionalTitle }} à {{ data.society }}</span>
          <span id="relations" class="display-8">
            {{ data.relations }} relations en commun
          </span>
        </div>
      </ng-container>
    </div>
    <datnek-ds-button-icon
      [type]="colorEnum.primary"
      [size]="sizeEnum.medium"
      [Icon]="iconEnum.outlineWhiteuserAdd"
      (datnekdsClickOrTouch)="isFollowed(i)"
      class=""
      *ngIf="follow === false && status === 'person'"
    ></datnek-ds-button-icon>
    <datnek-ds-button-icon
      [type]="colorEnum.secondary"
      [size]="sizeEnum.medium"
      [Icon]="iconEnum.outlinecheck"
      (datnekdsClickOrTouch)="isFollowed(i)"
      class=""
      *ngIf="follow === true && status === 'person'"
    ></datnek-ds-button-icon>
    <datnek-ds-button
      [type]="colorEnum.primary"
      [size]="sizeEnum.medium"
      label="Message"
      (datnekdsClickOrTouch)="isFollowed(i)"
      *ngIf="sendMessage === true"
      class="ml-3"
    ></datnek-ds-button>
  </ng-container>
</div>
