import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';
import {
  DialogAddContactComponent,
  DialogContactDetailsComponent,
  DialogDeleteGroupComponent,
  DialogEditContactComponent,
} from '@network-shared';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

interface User {
  id?: number;
  name: string;
  email: string;
  phone: string;
  function: string;
}

const USERS: User[] = [
  {
    name: 'Bassahak Jean Marc',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Yankap Arno Ghislain',
    email: 'yarnoghislain@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Goslu Tabara Jones',
    email: 'goslu2022@hotmail.com',
    phone: '+33 699 46 12 05 34',
    function: 'Analyste web',
  },
  {
    name: 'Danick Takam',
    email: 'dtakam2023@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Tambourin Judosaure Aurlius',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Batchabakem Boris',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Ateba Eyene Jr',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Alexis Lalas',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Audrey Bema',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'San Francisco Junior',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Batchabakem Boris',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Ateba Eyene Jr',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Alexis Lalas',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Bassahak Jean Marc',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Bassahak Jean Marc',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Bassahak Jean Marc',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
];

const USERS1: User[] = [
  {
    name: 'Bassahak Jean Marc',
    email: 'jmbassahk@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Yankap Arno Ghislain',
    email: 'yarnoghislain@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
  {
    name: 'Goslu Tabara Jones',
    email: 'goslu2022@hotmail.com',
    phone: '+33 699 46 12 05 34',
    function: 'Analyste web',
  },
  {
    name: 'Danick Takam',
    email: 'dtakam2023@gmail.com',
    phone: '+237 699 46 12 05',
    function: 'Analyste web',
  },
];

@Component({
  selector: 'network-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;
  leftSidebar = false;

  page = 1;
  pageSize = 10;
  collectionSize = USERS.length;
  users!: User[];
  users1!: typeof USERS1;

  favorite = false;
  onlyFavorite = false;

  rightSidebar = true;

  form = new FormGroup({});
  model: any = {
    deleteDoubleContact: null,
  };
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      key: 'person',
      type: 'select',
      props: {
        label: 'Friend',
        required: true,
        options: [
          { Label: 'Les plus pertinents', Value: 'Les_plus_pertinents' },
          { Label: 'Tous les contacts', Value: 'Tous_les_contacts' },
          { Label: 'Contacts favoris', Value: 'Contacts_favoris' },
        ],
      },
    },
  ]

  constructor(private overlay: Overlay, private modalService: DialogService) {
    this.refreshUsers();
  }

  showRightSidebar() {
    this.rightSidebar = !this.rightSidebar;
  }

  showLeftSidebar() {
    this.leftSidebar = !this.leftSidebar;
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  showOnlyFavorites() {
    this.favorite = !this.favorite;
  }

  refreshUsers() {
    this.users = USERS.map((user, i) => ({
      id: i + 1,
      ...user,
    })).slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  maskContact(index: number) {
    this.users1.splice(index, 1);
  }

  addContact(): void {
    const ref = this.modalService.open(DialogAddContactComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Ajouter un contact`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  editContact(): void {
    const ref = this.modalService.open(DialogEditContactComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier un contact`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  deleteContact(): void {
    const ref = this.modalService.open(DialogDeleteGroupComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Supprimer le contact`,
        size: DialogSizeEnum.small,
      },
    });
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  contactDetails(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    const ref = this.modalService.open(DialogContactDetailsComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Détails du contact`,
        size: DialogSizeEnum.large,
      },
    });
  }

  addFavorite(index: any): void {
    this.favorite = !this.favorite.valueOf();
  }
}
