<ng-container *ngIf="userData.length && show === true && !isLoading">
  <div
    class="
      nk-shared-user-infos-small
      invitation-list
      d-flex
      align-items-start
      p-3
      pointer
    "
    *ngFor="let data of userData; let i = index"
  >
    <datnek-ds-avatar
      [size]="sizeEnum.medium1"
      imgSrc="{{ data.imageSrc }}"
      [type]="status"
      class="mr-3 d-none d-sm-block"
      (datnekdsClickOrTouch)="goToProfile($event)"
    ></datnek-ds-avatar>
    <datnek-ds-avatar
      [size]="sizeEnum.small"
      imgSrc="{{ data.imageSrc }}"
      [type]="status"
      class="mr-3 d-sm-none"
      (datnekdsClickOrTouch)="goToProfile($event)"
    ></datnek-ds-avatar>

    <div class="infos">
      <div
        class="d-flex align-items-center justify-content-between"
        [ngClass]="
          isBlock === true ? ' flex-column flex-sm-row' : 'flex-column'
        "
      >
        <div
          class="d-flex flex-column w-100 pr-3 mb-2"
          (datnekdsClickOrTouch)="goToProfile($event)"
        >
          <div class="d-flex">
            <div class="name">
              <div class="display-3 clip ellipsis nowrap mr-1 mb-1">
                {{ data.name }}
              </div>
              <img src="assets/icons/src/check-color.png" alt="" />
            </div>
          </div>
          <span class="details"><span class="clip ellipsis pb-0 nowrap">{{ data.professionalTitle }}</span>  à <span class="clip ellipsis pb-0 nowrap">{{ data.society }}</span> </span>
            <span id="relations" class="display-8 clip ellipsis nowrap">
              {{ data.relations }} relations en commun
            </span>
        </div>

        <div
          class="d-flex buttons w-100"
          [ngClass]="
            isBlock === true
              ? 'justify-content-start justify-content-sm-end'
              : 'justify-content-start'
          "
          style="gap: 12px"
        >
          <datnek-ds-button
            [label]="'Confirmer'"
            [size]="sizeEnum.medium"
            [type]="colorEnum.primary"
            [onlyText]="true"
            (datnekdsClickOrTouch)="isFollowed(i)"
            *ngIf="follow === false"
          ></datnek-ds-button>
          <datnek-ds-button
            [label]="'Suivi'"
            [size]="sizeEnum.medium"
            [type]="colorEnum.primary"
            [onlyText]="true"
            [leftIcon]="iconEnum.outlineWhitecheck"
            (datnekdsClickOrTouch)="isFollowed(i)"
            *ngIf="follow === true"
          ></datnek-ds-button>
          <datnek-ds-button
            [label]="'Supprimer'"
            [size]="sizeEnum.medium"
            [type]="colorEnum.secondary"
            [onlyText]="true"
            (datnekdsClickOrTouch)="onShowed(i)"
            *ngIf="follow === false"
          ></datnek-ds-button>
          <datnek-ds-button
            [label]="'Annuler'"
            [size]="sizeEnum.medium"
            [type]="colorEnum.secondary"
            [onlyText]="true"
            (datnekdsClickOrTouch)="isFollowed(i)"
            *ngIf="follow === true"
            [leftIcon]="iconEnum.outlinex"
          ></datnek-ds-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngFor="let in of counter(3); let i = index">
  <datnek-skeleton-invitation-list
    *ngIf="isLoading"
  ></datnek-skeleton-invitation-list>
</ng-container>
