import { Component, Inject, Input, OnInit, TemplateRef, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogAddCalendarComponent, EventsService, Event } from '@del-shared';
import { DialogSharePostWithEditComponent, DialogUserEventListComponent } from '@network-shared';
import { Environment } from 'datnek-core';
import { AvatarTypeEnum, IconEnum, ControlSizeEnum, ControlTypeColorEnum, ChallengeStateTypeEnum, PopupInterface, DialogService, DialogTypeEnum, DialogSizeEnum, TooltipTypeEnum } from 'datnek-ds';

@Component({
  selector: 'network-list-events-item',
  templateUrl: './list-events-item.component.html',
})
export class ListEventsItemComponent implements OnInit {

  @Input() event!: Event;
  avatarEnum = AvatarTypeEnum;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  challengeEnum = ChallengeStateTypeEnum;
  isLoading = false;

  longText = `Lorem ipsum dolor sit amet consectetur adipisicing elit.
  Assumenda voluptatibus optio recusandae? Repudiandae
  aspernatur praesentium eius. Nobis ut magni delectus
  numquam sapiente! Voluptatem ex, blanditiis recusandae
  maiores illum sint vero.`;
  longText1 = `Lorem ipsum dolor sit amet consectetur adipisicing elit.
  Assumenda voluptatibus optio recusandae? Repudiandae
  aspernatur praesentium eius. Nobis ut magni delectus
  numquam sapiente! Voluptatem ex, blanditiis recusandae
  maiores illum sint vero. Lorem ipsum dolor sit amet consectetur adipisicing elit.
  Assumenda voluptatibus optio recusandae? Repudiandae
  aspernatur praesentium eius. Nobis ut magni delectus
  numquam sapiente! Voluptatem ex, blanditiis recusandae
  maiores illum sint vero.`;
  readMore = false;
  readMore1 = false;
  readMore2 = false;
  readMore3 = false;
  readMore4 = false;

  showPopup = false;
  dataUsersChat: PopupInterface[] = [
    {
      id: '1',
      name: 'Danick takam ouhakh kjahkjehkjehjk ekajh kjehkjeh kjaehazkjehaz kjh',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      writer: 'Vous',
      message: 'Je vous en prie monsieur. vous poriez me demander votre numéro ?',
    },
    {
      id: '2',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jean-Marc',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '3',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Arno yankam',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '4',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Kevin Sokodjou',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '5',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jordan Wildes',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '6',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Jospin Chimi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '7',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Lionel Messi',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '8',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Christiane',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '9',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'Laurent Nkoh',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
    {
      id: '10',
      display: true,
      right: 0,
      imageSrc: 'assets/Img/users/1.png',
      time: '5m',
      name: 'BITE Blaise',
      writer: 'Vous',
      message: 'Je vous en prie',
    },
  ];

  status = false;

  //this variable represents the total number of popups can be displayed according to the viewport width
  totalPopups = 0;

  //arrays of popups ids
  popups: PopupInterface[] = [];

  videoItems = [
    {
      videoSrc:
        'https://mdn.github.io/learning-area/html/multimedia-and-embedding/video-and-audio-content/rabbit320.webm',
      title: 'Diplome de Développeur front-end',
    },
    {
      videoSrc:
        'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm  ',
      title: 'Titre de la deuxième vidéo',
    },
  ];

  constructor(
    private modalService: DialogService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
    const eventId = this.route.snapshot.params['id'];
    this.event = this.eventService.getEventById(eventId);
  }

  onViewEvent() {
    this.router.navigateByUrl(
      `/events/list-view/${this.event.Id}`
    );
  }
  onViewParticipants() {
    this.router.navigateByUrl(
      `/events/list-view/${this.event.Id}/users`
    );
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  listOfParticipants(): void {
    const ref = this.modalService.open(DialogUserEventListComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Liste des participants`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  openDelete(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Dialog,
      origin,
      data: {
        id: 1,
        title: `Supprimer l'évènement ?`,
        size: DialogSizeEnum.small,
      },
    });
  }

  openMask(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Dialog,
      origin,
      data: {
        id: 1,
        title: `Masquer l'évènement ?`,
        size: DialogSizeEnum.small,
      },
    });
  }

  editEvent(): void {
    const ref = this.modalService.open(DialogAddCalendarComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Modifier l'évènement`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  toggleReadMore(): void {
    this.readMore = !this.readMore;
  }

  counter(i: number) {
    return new Array(i);
  }

  showStatus() {
    this.status = !this.status;
    console.log('STATUS : ' + this.status);
  }

  popupShow() {
    this.showPopup = !this.showPopup;
    console.log('POPUP PARENT : ' + this.showPopup);
  }

  goToChatMessage(e: any) {
    e.preventDefault();
    //this.router.navigate(['/chat/home']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/chat/home`;
    this.status = false;
    console.log(e);
  }

  sharePostWithEdit() {
    const ref = this.modalService.open(DialogSharePostWithEditComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Partager l'évènement sur Delenscio`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

}
