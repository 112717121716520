<div class="datnek-ds-dialog-body dialog-challenge-show-winner">
  <div class="content-body challenge-show-winner">
    <div class="grid">
      <div
        [ngClass]="
          yourChallenges === true ? 'd-none' : 'grid__col_12 grid__col_sm5'
        "
      >
        <div class="user-box">
          <div class="datnek-ds-card content" *ngIf="!isLoading">
            <div class="avatar">
              <datnek-ds-avatar [size]="sizeEnum.large"></datnek-ds-avatar>
            </div>
            <div class="infos">
              <div class="display-1">John Doe</div>
              <div class="display-4">Designer Web chez DIGITAILLE</div>
              <div class="display-4">Senior</div>
            </div>

            <div class="stats">
              <div
                class="datas d-flex justify-content-around pb-3 border-bottom"
              >
                <div class="left text-center pointer">
                  <div class="display-1">10</div>
                  <span class="display-7">Années d'expérience</span>
                </div>
                <div class="center text-center pointer">
                  <div class="display-1">2</div>
                  <span class="display-7">Certifications</span>
                </div>
                <div class="right text-center pointer">
                  <div class="display-1">134</div>
                  <span class="display-7">Recommanda...</span>
                </div>
              </div>
              <div class="datas d-flex justify-content-around pt-3">
                <div
                  class="left text-center pointer"
                  (datnekdsClickOrTouch)="goToFollowingPersons($event)"
                >
                  <div class="display-1">6,664</div>
                  <span>Following</span>
                </div>
                <div
                  class="right text-center pointer"
                  (datnekdsClickOrTouch)="goToMyFollowers($event)"
                >
                  <div class="display-1">19,924</div>
                  <span>Followers</span>
                </div>
              </div>
            </div>

            <div class="footer d-flex justify-content-around text-center">
              <datnek-ds-link
                (datnekdsClickOrTouch)="showChallenges()"
                label="Voir les autres challenges"
              ></datnek-ds-link>
              <datnek-ds-link
                (datnekdsClickOrTouch)="
                  goToProfile($event)
                "
                label="Voir le profil"
              ></datnek-ds-link>
            </div>
          </div>

          <datnek-skeleton-card-user-box
            *ngIf="isLoading"
          ></datnek-skeleton-card-user-box>
        </div>
      </div>
      <div
        class="scores"
        [ngClass]="
          yourChallenges === true
            ? 'grid__col_12'
            : 'grid__col_12 grid__col_sm7'
        "
      >
        <div
          class="
            header
            d-flex
            justify-content-between
            align-items-center
            border-bottom
          "
          *ngIf="!isLoading"
        >
          <div class="display-1 title-score p-3">
            Résultats du challenge
            <span class="challenge-name">Nom du Challenge</span>
          </div>
          <button
            (datnekdsClickOrTouch)="openDropdown(actions, target)"
            #target
          >
            <datnek-ds-button-icon
              [Icon]="iconEnum.outlinedotsVertical"
              [type]="colorEnum.ghost"
              [round]="true"
            ></datnek-ds-button-icon>
          </button>
        </div>

        <datnek-skeleton-title *ngIf="isLoading"></datnek-skeleton-title>
        <p-scrollPanel
          [style]="{ width: '100%', height: '300px' }"
          *ngIf="!isLoading"
        >
          <div class="content-modal">
            <div
              class="
                score-items
                d-flex
                align-items-center
                justify-content-between
              "
              *ngFor="let item of scores"
            >
              <div class="display-3 title">
                {{ item.libelle | truncatetext: 30 }}
              </div>
              <div class="jauge">
                <div
                  class="bar"
                  [ngStyle]="{ width: (item.note / item.total) * 100 + '%' }"
                ></div>
              </div>
              <div class="note display-1 text-right">
                {{ item.note }}/{{ item.total }}
              </div>
            </div>
            <div class="message-jury p-3" *ngIf="!isLoading">
              <div class="display-3 d-flex align-items-center mb-3">
                <img src="assets/Img/jurys.svg" class="mr-3" alt="" /> Message
                du Jury
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptate nam deleniti libero voluptas temporibus, quibusdam
                molestiae eveniet vero itaque facilis commodi consequuntur,
                voluptatem corrupti ab iusto dignissimos. Ex, officiis quas.
              </p>
              <div class="display-3 d-flex align-items-center mb-3">
                <img src="assets/Img/jurys.svg" class="mr-3" alt="" />Liste des
                jurés
              </div>
              <div class="list-jury mb-3">
                <div class="jury d-flex align-items-center p-3">
                  <img src="assets/Img/user-placeholder-avatar.svg" alt="" />
                  <div class="infos ml-3">
                    <div class="name display-4">John Doe</div>
                    <div class="fonction">Web Designer</div>
                  </div>
                </div>
                <div class="jury d-flex align-items-center p-3">
                  <img src="assets/Img/user-placeholder-avatar.svg" alt="" />
                  <div class="infos ml-3">
                    <div class="name display-4">John Doe</div>
                    <div class="fonction">Web Designer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
        <div class="datnek-ds-dialog-footer" *ngIf="yourChallenges === true">
          <div
            class="
              icons
              d-flex
              align-items-center
              justify-content-between
              footer-modal
            "
          >
            <datnek-ds-checkbox-group
              label="Rendre mes résultas publics"
              [markLeft]="true"
            ></datnek-ds-checkbox-group>
            <datnek-ds-button
              [label]="'Ok'"
              [size]="sizeEnum.large"
              [type]="colorEnum.primary"
              (datnekdsClickOrTouch)="close()"
            ></datnek-ds-button>
          </div>
        </div>

        <datnek-skeleton-challenge-stats
          *ngIf="isLoading"
        ></datnek-skeleton-challenge-stats>
      </div>
    </div>
  </div>
</div>

<!--NOTIFS MESSAGES-->
<ng-template #cp let-close="close">
  Le lien a été copié dans le presse-papier
</ng-template>
<ng-template #sh let-close="close">
  Vos résultats ont été partagés en public
</ng-template>

<!-- DROPDOWNS -->
<ng-template #actions let-close="close">
  <div class="datnek-ds-card dropdown-card actions-share-results-challenge">
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlineuserGroup"
      label="Partager dans un groupe"
      (datnekdsClickOrTouch)="shareToGroup(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinemail"
      label="Partager dans une adresse email"
      (datnekdsClickOrTouch)="shareToEmail(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlineuser"
      label="Partagez à quelques personnes"
      (datnekdsClickOrTouch)="shareToUser(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinereplyRight"
      label="partagez au grand public"
      (datnekdsClickOrTouch)="showToast(sh); close()"
    ></datnek-ds-icon-submenu>
  </div>
</ng-template>
