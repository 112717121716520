import { Component, Input, OnInit } from '@angular/core';
import {
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  AvatarTypeEnum,
} from 'datnek-ds';

@Component({
  selector: 'nk-shared-notification-blox',
  templateUrl: './notification-blox.component.html',
  styleUrls: ['./notification-blox.component.scss'],
})
export class NotificationBloxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = false;

  /**
   * Titre de la notification box
   */
  @Input()
  title = '';

  /**
   * Image de la notification box
   */
  @Input()
  imgSrc = 'assets/Img/password_protected_2.svg';

  /**
   * Description de la notification box
   */
  @Input()
  description = '';

  /**
   * Si la notification est de type restricted
   *
   * @boolean
   */
  @Input()
  restricted = false;

  show = true;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  onShow() {
    this.show = !this.show;
  }
}
