<!--=====================================-->
<!--=        Footer Area Start       	=-->
<!--=====================================-->
<footer class="footer-wrap" [ngClass]="{'no-topbg': !topDesignBg}">
  <ul class="footer-top-image" *ngIf="topFooter">
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="400"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_5.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="500"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_6.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="300"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_4.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="600"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_7.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="200"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_3.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="700"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_8.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="100"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_2.png'|assetUrl" alt="Man" />
    </li>
    <li
      data-sal="slide-up"
      data-sal-duration="500"
      data-sal-delay="800"
      class="sal-animate"
    >
      <img [src]="'Img/homepage/man_9.png'|assetUrl" alt="Man" />
    </li>
    <li data-sal="slide-up" data-sal-duration="500" class="sal-animate">
      <img [src]="'Img/homepage/man_1.png'|assetUrl" alt="Man" />
    </li>
  </ul>
  <div class="main-footer">
    <div class="container">
      <div class="row row-cols-lg-4 row-cols-sm-2 row-cols-1">
        <div class="col-md-4 col-12">
          <div class="footer-box">
            <h3 class="footer-title mb-3">About us</h3>
            <div class="footer-link">
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Company infos</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#" (datnekdsClickOrTouch)="inProgress()">Newsroom</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Carrees FAQ</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Looking for contractor roles ?</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 d-flex justify-content-lg-center">
          <div class="footer-box">
            <h3 class="footer-title mb-3">More resources</h3>
            <div class="footer-link">
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Family safety center</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Delenscio test</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Delenscio for developers</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Delenscio learning</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Delenscio social network</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 d-flex justify-content-lg-center">
          <div class="footer-box">
            <h3 class="footer-title mb-3">Our policies</h3>
            <div class="footer-link">
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Candidate privacy</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#">Statement</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#" (datnekdsClickOrTouch)="inProgress()">Data Policy</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#" (datnekdsClickOrTouch)="inProgress()">Coockies</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 d-flex justify-content-lg-center">
          <div class="footer-box">
            <h3 class="footer-title mb-3">Followers</h3>
            <div class="footer-link">
              <ul>
                <li><a href="https://www.facebook.com/" target="_blank">facebook</a></li>
                <li><a href="https://twitter.com/" target="_blank">twitter</a></li>
                <li><a href="https://www.instagram.com/" target="_blank">instagram</a></li>
                <li><a href="https://www.youtube.com/" target="_blank">Youtube</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="footer-copyright">
      Copy© Delenscio 2022. All Rights Reserved
    </div>
  </div>
</footer>

<!-- ======= Footer ======= -->
<!--<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-6 col-6 footer-links">
          <h4>About us</h4>
          <ul>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Company infos</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Newsroom</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Carrees FAQ</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Looking for contractor roles ?</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-6 col-6 footer-links">
          <h4>On social</h4>
          <ul>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Facebook</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Instagram</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">LinkedIn</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-6 col-6 footer-links">
          <h4>Our Policies</h4>
          <ul>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Candidate privacy</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Statement</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Data Policy</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Coockies</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 col-6 footer-links">
          <h4>More resources</h4>
          <ul>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Family safety center</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Delenscio test</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Delenscio for developers</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Delenscio learning</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Delenscio social network</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-6 footer-links">
          <h4>Delenscio © 2021</h4>
          <button
            (click)="open(newContent)"
            class="btn-img-txt d-flex align-items-center"
          >
            <img
              class="mr-2"
              [src]="'img/icons/global-network.svg' | assetUrl"
              alt=""
            />
            <span>Français (France)</span>
          </button>
          <a href="#" class="langues btn-img-txt small"></a>
        </div>
      </div>
    </div>
  </div>

  <a (click)="scrollToTop()" class="back-to-top d-none d-md-block"
    ><i class="fa fa-chevron-up"></i
  ></a>

  <ng-template #newContent let-modal>
    <div class="modal-header header-blue">
      <h4 class="modal-title">Sélectionnez votre langue</h4>
    </div>
    <div class="modal-body">
      <div class="header-body">
        <div class="row d-flex align-items-center mb-3 pb-3 border-bottom">
          <div class="col-3">Langues suggérées</div>
          <div class="col-9 d-flex justify-content-between langues">
            <button class="btn btn-img-txt small active">
              Français (France)
              <img
                class="mr-2"
                [src]="'img/icons/check-mark.svg' | assetUrl"
                alt=""
              />
            </button>
            <button class="btn">English (US)</button>
            <button class="btn">Nederlands (Belgique)</button>
            <button class="btn">Español</button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="d-flex">
          <ul
            ngbNav
            #nav="ngbNav"
            [(activeId)]="active"
            class="nav-pills"
            orientation="vertical"
          >
            <li ngbNavItem="all">
              <a ngbNavLink>toutes les langues</a>
              <ng-template ngbNavContent>
                <div class="columns">
                  <a href="">AF-Somalie</a><a href="">Afrikaans</a
                  ><a class="active" href="">Français (France)</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="africa">
              <a ngbNavLink>Afrique et moyen-orient</a>
              <ng-template ngbNavContent>
                <div class="columns">
                  <a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="america">
              <a ngbNavLink>Amériques</a>
              <ng-template ngbNavContent>
                <div class="columns">
                  <a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a
                  ><a href="">Langue 1</a><a href="">Langue 1</a>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="asia">
              <a ngbNavLink>Asie-Pacifique</a>
              <ng-template ngbNavContent> </ng-template>
            </li>
            <li ngbNavItem="europe-east">
              <a ngbNavLink>Europe de 'Est</a>
              <ng-template ngbNavContent> </ng-template>
            </li>
            <li ngbNavItem="europe-west">
              <a ngbNavLink>Europe de l'Ouest</a>
              <ng-template ngbNavContent> </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="ml-4"></div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn-post-publication d-flex justify-content-end">
        <button
          (click)="modal.close('Close click')"
          type="button"
          class="btn dc-btn btn-lg mr-2"
        >
          Fermer
        </button>
        <button type="button" class="btn dc-btn blue btn-lg ml-2">
          Traduire Delenscio
        </button>
      </div>
    </div>
  </ng-template>
</footer> -->
