import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  ControlSizeEnum,
} from 'datnek-ds';
import { DialogGroupAdhesionComponent } from '../dialogs';

interface groups {
  imageSrc?: string;
  groupName: string;
  members?: string;
  publication?: number;
}

interface UsersItems {
  id: string;
  imageSrc: string;
  society: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
})
export class GroupCardComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  followed = false;

  /**
   *
   */
  @Input()
  groups: groups[] = [];

  /**
   *
   */
  @Input()
  societyData: UsersItems[] = [];

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status: AvatarTypeEnum = this.avatarEnum.group;

  isLoading = false;

  /**
   * La source de l'image
   */
  @Input()
  imgSrc!: IconEnum | string;

  /**
   * If restricted Group
   */
  @Input()
  restricted = false;
  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  adhesionDemand(): void {
    const ref = this.modalService.open(DialogGroupAdhesionComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Demande d'adhésion au groupe`,
        size: DialogSizeEnum.small,
      },
    });
    this.followed = !this.followed;
  }

  goToGroup(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.restricted === true) {
      this.router.navigate(['/groups/restricted-group']);
    } else {
      this.router.navigate(['/groups/single-group/group-discussion']);
    }
  }

  goToSociety(url: string): void {
    //this.router.navigate(['/enterprise']);
    window.open(url, '_blank');
  }

  onFollow(): void {
    this.followed = !this.followed;
  }
}
