import { Component, Input, OnInit } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  DialogRedirectToComponent,
} from 'datnek-ds';

interface JobsItems {
  imageSrc: string;
  jobTitle: string;
  society: string;
  time: string;
}

@Component({
  selector: 'nk-shared-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
})
export class JobsListComponent implements OnInit {
  @Input() jobData: JobsItems[] = [];
  isLoading = false;

  constructor(private overlay: Overlay, private modalService: DialogService) {}

  redirectToJobs(): void {
    const ref = this.modalService.open(DialogRedirectToComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Redirection vers Dekenscio Jobs`,
        size: DialogSizeEnum.medium,
      },
    });
  }

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }
}
