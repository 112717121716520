import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from 'datnek-core';
import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  relations: number;
}

@Component({
  selector: 'nk-shared-invitation-box',
  templateUrl: './invitation-box.component.html',
  styleUrls: ['./invitation-box.component.scss'],
})
export class InvitationBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2 kqjkqd dkjsdj kjdsj k djj dsj',
      society: 'DIGITAILLE',
      relations: 4,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 18,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 0,
    },
  ];

  @Input()
  status!: AvatarTypeEnum;

  /**
   * Les Titre de la card
   */
  @Input()
  titleCard = '';

  isLoading = false;

  constructor(private router: Router, @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  gotoInvitation(e: any) {
    e.preventDefault();
    //this.router.navigate(['/request/invitation']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/request/invitation`;
  }
}
