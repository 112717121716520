/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';


import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum
} from 'datnek-ds';

interface stateItems {
  id: number;
  name: string;
  src: IconEnum;
}

@Component({
  selector: 'del-shared-user-infos',
  templateUrl: './user-infos.component.html',
  styleUrls: ['./user-infos.component.scss'],
})
export class UserInfosComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  /**
   * Le Nom du Profil de la personne ou de l'entreprise
   */
  @Input()
  name = '';

  /**
   * La profession de la personne ou de l'entreprise
   */
  @Input()
  professionalTitle = '';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status: AvatarTypeEnum = AvatarTypeEnum.person;

  /**
   * Si l'on est sur le compnent de groupe, certains éléments doivet s'afficher
   *
   */
  @Input()
  isGroup = false;

  /**
   * Si l'utilisateur est en tran de saisir un texte
   *
   */
  @Input()
  isTyping = false;

  /**
   * Si l'utilisateur est en tran de saisir un texte
   *
   */
  @Input()
  imgSrc!: IconEnum;

  showSubmenuCompetence = false;

  stateSubmenu: stateItems[] = [
    {
      id: 0,
      name: 'Public',
      src: IconEnum.outlineglobe,
    },
    {
      id: 0,
      name: 'Privé',
      src: IconEnum.outlinelockClosed,
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  onShowSubmenuCompetence() {
    this.showSubmenuCompetence = !this.showSubmenuCompetence;
  }
}
