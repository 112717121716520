<main class="content single-event">
  <div class="container p-0">
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1">
        <div class="d-flex no-gutters gap-16">
          <div class="col-lg-8 col-12">
            <div class="datnek-ds-card px-3">
              <div class="row g-0">
                <div class="col-12 px-0">
                  <div
                    class="
                      header
                      d-flex
                      justify-content-between
                      align-items-center
                      px-3
                    "
                  >
                    <datnek-ds-button
                      label="Retour"
                      [leftIcon]="iconEnum.outlinearrowLeft"
                      [type]="colorEnum.secondary"
                      [size]="sizeEnum.large"
                      class="mr-3"
                      (datnekdsClickOrTouch)="goToEvent($event)"
                    ></datnek-ds-button>
                    <div class="d-flex">
                      <datnek-ds-button-icon
                        [type]="colorEnum.ghost"
                        [Icon]="iconEnum.outlinepencil"
                        [size]="sizeEnum.medium"
                        (datnekdsClickOrTouch)="editEvent()"
                      ></datnek-ds-button-icon>
                      <datnek-ds-button-icon
                        [type]="colorEnum.ghost"
                        [Icon]="iconEnum.outlinetrash"
                        [size]="sizeEnum.medium"
                        (datnekdsClickOrTouch)="deleteEvent()"
                      ></datnek-ds-button-icon>
                    </div>
                  </div>
                  <div class="body-content">
                    <div class="row pt-3 pl-3 pr-3 pb-0">
                      <div class="col-12">
                        <div class="titles" *ngIf="!isLoading">
                          <div class="display-1 mb-3">{{ eventTitle }}</div>
                          <div class="display-2 mb-3">
                            {{ eventSubtitle }}
                          </div>
                        </div>
                        <datnek-skeleton-suggestion-list
                          [withAvatar]="false"
                          *ngIf="isLoading"
                        ></datnek-skeleton-suggestion-list>
                      </div>
                    </div>

                    <div class="">
                      <div class="featured mb-3" *ngIf="!isLoading">
                        <img
                          src="assets/Img/AdobeStock_104857309-1140x650.jpg"
                          alt=""
                        />
                      </div>
                      <div class="event-details p-3" *ngIf="!isLoading">
                        <div class="metadata mb-3">
                          <div class="d-flex align-items-center">
                            <datnek-ds-svg-icon
                              [icon]="iconEnum.outlinelocationMarker"
                              class="mr-1"
                            ></datnek-ds-svg-icon>
                            <span
                              >Pays: CM, Ville: Douala, Full Adress: CM, Douala,
                              233, Douala PK9, 235</span
                            >
                          </div>
                          <div class="d-flex align-items-center">
                            <datnek-ds-svg-icon
                              [icon]="iconEnum.outlinecalendar"
                              class="mr-1"
                            ></datnek-ds-svg-icon>
                            <span>01 September 2022</span>
                          </div>
                          <div class="d-flex align-items-center">
                            <datnek-ds-svg-icon
                              [icon]="iconEnum.outlinefilm"
                              class="mr-1"
                            ></datnek-ds-svg-icon>
                            <span>En vidéo conférence</span>
                          </div>
                          <div class="d-flex align-items-center">
                            <datnek-ds-svg-icon
                              [icon]="iconEnum.outlineusers"
                              class="mr-1"
                            ></datnek-ds-svg-icon>
                            <span>Nombre de participants</span>
                          </div>
                        </div>

                        <div class="item">
                        <div class="display-1 mb-3">Liste des intervenants</div>
                        <div class="user-list-grid">
                          <nk-shared-user-box-simple
                            [userData]="dataUsers"
                            [status]="avatarEnum.person"
                          ></nk-shared-user-box-simple>
                        </div>
                      </div>
                        <div class="description">
                          <div class="display-1 mb-3">
                            Compte rendu de l'évènement
                          </div>
                          <p>
                            Pellentesque in ipsum id orci porta dapibus. Nulla
                            quis lorem ut libero malesuada feugiat. Mauris
                            blandit aliquet elit, eget tincidunt nibh pulvinar
                            a. Curabitur arcu erat, accumsan id imperdiet et,
                            porttitor at sem. Mauris blandit aliquet elit, eget
                            tincidunt nibh pulvinar a. Mauris blandit aliquet
                            elit, eget tincidunt nibh pulvinar a. Curabitur arcu
                            erat, accumsan id imperdiet et, porttitor at sem.
                          </p>
                          <p>
                            Vivamus magna justo, lacinia eget consectetur sed,
                            convallis at tellus. Nulla porttitor accumsan
                            tincidunt. Vivamus magna justo, lacinia eget
                            consectetur sed, convallis at tellus. Nulla
                            porttitor accumsan tincidunt. Vivamus suscipit
                            tortor eget felis porttitor volutpat. Nulla quis
                            lorem ut libero malesuada feugiat. Donec
                            sollicitudin molestie malesuada.
                          </p>
                          <p>
                            Sed porttitor lectus nibh. Vivamus magna justo,
                            lacinia eget consectetur sed, convallis at tellus.
                            Vestibulum ante ipsum primis in faucibus orci luctus
                            et ultrices posuere cubilia Curae; Donec velit
                            neque, auctor sit amet aliquam vel, ullamcorper sit
                            amet ligula. Donec sollicitudin molestie malesuada.
                            Pellentesque in ipsum id orci porta dapibus.
                            Vestibulum ac diam sit amet quam vehicula elementum
                            sed sit amet dui. Vestibulum ante ipsum primis in
                            faucibus orci luctus et ultrices posuere cubilia
                            Curae; Donec velit neque, auctor sit amet aliquam
                            vel, ullamcorper sit amet ligula.
                          </p>
                          <p>
                            Vivamus suscipit tortor eget felis porttitor
                            volutpat. Nulla quis lorem ut libero malesuada
                            feugiat. Nulla porttitor accumsan tincidunt. Mauris
                            blandit aliquet elit, eget tincidunt nibh pulvinar
                            a. Nulla quis lorem ut libero malesuada feugiat.
                            Praesent sapien massa, convallis a pellentesque nec,
                            egestas non nisi. Vestibulum ac diam sit amet quam
                            vehicula elementum sed sit amet dui.
                          </p>

                          <p>
                            Pellentesque in ipsum id orci porta dapibus. Nulla
                            quis lorem ut libero malesuada feugiat. Mauris
                            blandit aliquet elit, eget tincidunt nibh pulvinar
                            a. Curabitur arcu erat, accumsan id imperdiet et,
                            porttitor at sem. Mauris blandit aliquet elit, eget
                            tincidunt nibh pulvinar a. Mauris blandit aliquet
                            elit, eget tincidunt nibh pulvinar a. Curabitur arcu
                            erat, accumsan id imperdiet et, porttitor at sem.
                          </p>
                        </div>

                        <div
                          class="
                            actions
                            d-flex
                            flex-column flex-md-row
                            align-items-center
                            justify-content-around
                          "
                        >
                          <datnek-ds-button
                            label="Voir les participants"
                            [type]="colorEnum.secondary"
                            [size]="sizeEnum.large"
                            [leftIcon]="iconEnum.outlineeye"
                            (datnekdsClickOrTouch)="onViewParticipants()"
                            [block]="true"
                            class="mr-3"
                          ></datnek-ds-button>
                          <div ngbDropdown class="d-inline-block">
                            <div
                              class="pointer"
                              id="dropdownBasic2"
                              ngbDropdownToggle
                            >
                              <datnek-ds-button
                                label="Partager"
                                [type]="colorEnum.secondary"
                                [size]="sizeEnum.large"
                                [leftIcon]="iconEnum.outlineshare"
                                [block]="true"
                              ></datnek-ds-button>
                              <!--<span>Partager</span>
                                  <datnek-ds-svg-icon
                                    [icon]="'share'"
                                    class="ml-2"
                                  ></datnek-ds-svg-icon>-->
                            </div>

                            <div
                              ngbDropdownMenu
                              aria-labelledby="dropdownBasic2"
                            >
                              <button
                                ngbDropdownItem
                                (datnekdsClickOrTouch)="sharePostWithEdit()"
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlineshare"
                                ></datnek-ds-svg-icon>
                                Partager dans une publication Delenscio
                              </button>
                              <button
                                ngbDropdownItem
                                (datnekdsClickOrTouch)="postInGroupOrUser()"
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlinechatAlt"
                                ></datnek-ds-svg-icon>
                                Envoyer à un contact par message
                              </button>
                              <button ngbDropdownItem>
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlineshare"
                                ></datnek-ds-svg-icon
                                >Facebook
                              </button>
                              <button ngbDropdownItem>
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlineshare"
                                ></datnek-ds-svg-icon
                                >Twitter
                              </button>
                              <button
                                ngbDropdownItem
                                (datnekdsClickOrTouch)="postInGroupOrUser()"
                              >
                                <datnek-ds-svg-icon
                                  [icon]="iconEnum.outlinelink"
                                ></datnek-ds-svg-icon
                                >Envoyer le lien
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <datnek-skeleton-single-events
                        *ngIf="isLoading"
                      ></datnek-skeleton-single-events>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="medias">
              <div class="datnek-ds-card images mb-3">
                <div class="header d-flex align-items-center display-1 px-3">
                  Images de l'évènement
                </div>
                <div class="p-3">
                  <del-shared-image-gallery></del-shared-image-gallery>
                  <!-- <datnek-ds-image-gallery></datnek-ds-image-gallery> -->
                </div>
              </div>
              <div class="datnek-ds-card videos mb-3">
                <div class="header d-flex align-items-center display-1 px-3">
                  Vidéos de l'évènement
                </div>
                <div class="p-3">
                  <datnek-ds-video-gallery></datnek-ds-video-gallery>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
