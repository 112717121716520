import { Component, Input, OnInit, TemplateRef, Type } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import {
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
} from 'datnek-ds';
import { DialogAcceptChallengeModalComponent } from '../../dialogs';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
  status: AvatarTypeEnum;
}

@Component({
  selector: 'nk-shared-challenge-battle-box',
  templateUrl: './challenge-battle-box.component.html',
  styleUrls: ['./challenge-battle-box.component.scss'],
})
export class ChallengeBattleBoxComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  hide = false;
  name1 = 'John Doe';
  name2 = 'Yankap Arnaud Ghislain';

  competences = [
    'AngularJS',
    'Angular2+',
    'NextJS',
    'Data Science',
    'Front-end Development',
  ];

  competences1 = [
    'Angular2+',
    'ReactJS',
    'Data Science',
    'Front-end Development',
  ];

  isLoading = false;
  society = 'Yarno Ghislain';
  sector = 'Angular JS';

  acceptedChallenge = false;
  rejectedChallenge = true;

  competencesList = [
    {
      name: 'Gestion de la paie',
    },
    {
      name: 'Intégration',
    },
    {
      name: 'Manipiulation des Big Data',
    },
    {
      name: 'Analyse',
    },
    {
      name: 'Harmnisation des candidatures',
    },
    {
      name: 'Recrutement',
    },
    {
      name: 'Conventions collectives',
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="assets/icons/src/outline/chevron-left.svg" class="arrow-slide">',
      '<img src="assets/icons/src/outline/chevron-right.svg" class="arrow-slide">',
    ],
    items: 1,
    nav: true,
  };

  @Input()
  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'AMOUREUX DE REACTJS',
      relations: 4,
      sector: 'Génie civil',
      status: AvatarTypeEnum.person,
    },
  ];

  constructor(private modalService: DialogService) {}

  ngOnInit(): void {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  confirmChallenge(): void {
    const ref = this.modalService.open(DialogAcceptChallengeModalComponent, {
      dialogType: DialogTypeEnum.Dialog,
      data: {
        title: `Accepter le challenge`,
        size: DialogSizeEnum.small,
      },
    });
  }

  onHide(): void {
    this.hide = true;
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }

  acceptChallenge(): void {
    this.acceptedChallenge = !this.acceptedChallenge;
  }
}
