<div class="comment-add d-flex align-items-start justify-content-between">
  <datnek-ds-avatar
    [size]="sizeEnum.small"
    [type]="avatarEnum.person"
    class="mr-3 d-none d-sm-block"
  ></datnek-ds-avatar>
  <datnek-ds-editor-add-comments
    (onClick)="clickListener($event)"
  ></datnek-ds-editor-add-comments>
</div>
