import { Component, OnInit } from '@angular/core';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  DialogRef
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
}

@Component({
  selector: 'nk-shared-dialog-add-member',
  templateUrl: './dialog-add-member.component.html',
  styleUrls: ['./dialog-add-member.component.scss'],
})
export class DialogAddMemberComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  groupName = false;
  isMultiple = false;

  items = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 2 }, { id: 3 }];

  dataUsers: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur et Data Senior',
      name: 'Fara Moiten 2 kkjsfksjd skfjkds fkjhfjksdfh kj fhjsdfdsk',
      society: 'DIGITAILLE Multimedia Intertainement',
      groupName: 'Nom du groupe',
      relations: 4,
      sector: 'Génie civil',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 18,
      sector: 'Technologie',
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      groupName: 'Nom du groupe',
      relations: 0,
      sector: 'Agroalimentaire',
    },
  ];

  added = false;
  removedItem = false;

  constructor(
    public dialogRef: DialogRef // private data: {
  ) //   groupName: boolean;
  //   isMultiple: boolean;
  // }
  {}

  ngOnInit(): void {
    // console.log(this.data);
    // this.groupName = this.data.groupName;
    // this.isMultiple = this.data.isMultiple;
  }

  remove(index: any) {
    this.items.splice(index, 2);
  }

  add() {
    this.added = true;
  }

  removed() {
    this.added = false;
  }
}
