import {
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import {
  AvatarTypeEnum,
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  ChallengeStateTypeEnum,
  PopupInterface,
  DialogService,
  DialogSizeEnum,
  DialogTypeEnum,
  TooltipTypeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  relations: number;
  sector: string;
  confirmed: boolean;
}

@Component({
  selector: 'network-users-list-event',
  templateUrl: './users-list-event.component.html',
})
export class UsersListEventComponent implements OnInit {
  avatarEnum = AvatarTypeEnum;
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  challengeEnum = ChallengeStateTypeEnum;
  isLoading = true;
  showMore = false;
  confirmed = true;

  showPopup = false;
  //this variable represents the total number of popups can be displayed according to the viewport width
  totalPopups = 0;

  //arrays of popups ids
  popups: PopupInterface[] = [];

  @Input()
  userData: UsersItems[] = [
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur et Data Senior',
      name: 'Fara Moiten 2 kkjsfksjd skfjkds fkjhfjksdfh kj fhjsdfdsk',
      society: 'DIGITAILLE Multimedia Intertainement',
      relations: 4,
      sector: 'Génie civil',
      confirmed: true
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 4,
      sector: 'Génie civil',
      confirmed: false,
    },
    {
      imageSrc: 'assets/Img/users/1.png',
      professionalTitle: 'Analyste Programmeur',
      name: 'Fara Moiten 2',
      society: 'DIGITAILLE',
      relations: 4,
      sector: 'Génie civil',
      confirmed: true,
    },
  ];

  constructor(private router: Router, private modalService: DialogService, private _location: Location) {}

  ngOnInit() {
    setTimeout(() => (this.isLoading = false), 2500);
  }

  onShowMore() {
    this.showMore = !this.showMore;
  }

  onConfirm() {
    this.confirmed = !this.confirmed;
  }

  back() {
    this._location.back();
  }

  //this function can remove a array element.
  remove(item: PopupInterface) {
    this.popups = this.popups.filter((pp) => pp.id !== item.id);
    console.log('new count popups:', this.popups);
  }

  //this is used to close a popup
  closePopup(item: PopupInterface) {
    this.remove(item);
    this.calculatePopups();
  }

  //displays the popups. Displays based on the maximum number of popups that can be displayed on the current viewport width
  displayPopups() {
    let right = 376;

    let i = 0;
    for (i; i < this.totalPopups; i++) {
      if (this.popups[i]) {
        this.popups[i].right = right;
        right = right + 360;
        this.popups[i].display = true;
      }
    }

    for (let j = i; j < this.popups.length; j++) {
      this.popups[i].display = false;
    }
  }

  //creates markup for a new popup. Adds the id to popups array.
  registerPopup(item: PopupInterface) {
    //already registered. Bring it to front.
    this.popups = this.popups.filter((pp) => pp.id !== item.id);

    this.popups.unshift(item);
    console.log('count popups:', this.popups);
    this.calculatePopups();
  }

  //calculate the total number of popups suitable and then populate the toatal_popups variable.
  @HostListener('window:resize', ['$event'])
  @HostListener('window:load', ['$event'])
  calculatePopups() {
    //recalculate when window is loaded and also when window is resized.
    let width = window.innerWidth;
    if (width < 540) {
      this.totalPopups = 0;
    } else {
      width = width - 350;
      //350 is width of a single popup box
      this.totalPopups = parseInt(`${width / 350}`);
    }

    this.displayPopups();
  }

  deleteUser(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Dialog,
      origin,
      data: {
        id: 1,
        title: `Supprimer ce participant ?`,
        size: DialogSizeEnum.small,
      },
    });
  }

  showToast(content: string | TemplateRef<any> | Type<any>): void {
    const ref = this.modalService.open(content, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
  }
}
