/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  IconEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  AvatarTypeEnum,
  SuggestionTypeEnum,
  DialogService,
  DialogTypeEnum,
} from 'datnek-ds';

interface UsersItems {
  imageSrc: string;
  professionalTitle: string;
  name: string;
  society: string;
  groupName: string;
  relations: number;
  sector: string;
  challengedCompetence: string;
  userYearsOfExperience: number;
}

@Component({
  selector: 'nk-shared-user-box-simple',
  templateUrl: './user-box-simple.component.html',
})
export class UserBoxSimpleComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  @Input() userData: UsersItems[] = [];
  currentIndex = 0;

  /**
   * Le type de suggestion renvoyé : 'invitation' | 'suggestion'
   *
   * @default = 'suggestion'
   */
  @Input()
  type: SuggestionTypeEnum = SuggestionTypeEnum.suggestion;
  //type: 'invitation' | 'suggestion' = 'suggestion';

  /**
   * Le type de status renvoyé : 'person' | 'society' = 'person'
   *
   * @default = 'person'
   */
  @Input()
  status!: AvatarTypeEnum;

  /**
   * Button d'envoi de message
   *
   * @default = false
   */
  @Input()
  sendMessage = false;

  /**
   * Si l'on veut ajoutr l'utilisateur comme admin d'un groupe
   *
   * @default = false
   */
  @Input()
  role = false;

  /**
   * Si le lien renvoit à la page du profil. Sinon le click permet juste d'ajouter les user à une liste
   *
   * @default = true
   */
  @Input()
  link = true;

  follow = false;

  constructor(private router: Router, private modalService: DialogService) {}

  ngOnInit(): void {
    console.log(typeof this.type);
    setTimeout(() => (this.isLoading = false), 2500);
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  isFollowed(index: number): void {
    this.follow = !this.follow;
    this.currentIndex = index;
  }

  gotoEnterprise(e: any): void {
    e.preventDefault();
    this.router.navigate(['/enterprise']);
  }
  gotoGroup(e: any): void {
    e.preventDefault();
    this.router.navigate(['/groups/single-group/group-discussion']);
  }
  gotoProfile(e: any): void {
    e.preventDefault();
    this.router.navigate(['/']);
  }
}
