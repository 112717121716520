import { Component, ElementRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { IconEnum, ControlSizeEnum, ControlTypeColorEnum, AvatarTypeEnum, DialogService, DialogSizeEnum, DialogTypeEnum } from 'datnek-ds';

import { trigger, transition, style, animate } from '@angular/animations';
import { Environment } from 'datnek-core';
import { DialogShowVideoGalleryComponent } from '@network-shared';

interface VideoItems {
  id: string;
  videoSrc: string;
  title: string;
  vues: number;
  time: string;
}

@Component({
  selector: 'nk-shared-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({ transform: 'scale(0.5)' }),
        animate('150ms', style({ transform: 'scale(1)' })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0.8 })),
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' })),
      ]),
    ]),
  ]
})

export class VideoGalleryComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;

  @Input()
  videoData: VideoItems[] = [
    {
      id: UUID.UUID(),
      videoSrc: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
      title: 'Edition 2018 de la nuit des entrepreneurs',
      vues: 855,
      time: 'Il ya 2 semaines',
    },
    {
      id: UUID.UUID(),
      videoSrc: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
      title: 'Edition 2018 de la nuit des entrepreneurs',
      vues: 855,
      time: 'Il ya 2 semaines',
    },
    {
      id: UUID.UUID(),
      videoSrc: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
      title: 'Edition 2018 de la nuit des entrepreneurs',
      vues: 855,
      time: 'Il ya 2 semaines',
    },
    {
      id: UUID.UUID(),
      videoSrc: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
      title: 'Edition 2018 de la nuit des entrepreneurs',
      vues: 855,
      time: 'Il ya 2 semaines',
    },
    {
      id: UUID.UUID(),
      videoSrc: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
      title: 'Edition 2018 de la nuit des entrepreneurs',
      vues: 855,
      time: 'Il ya 2 semaines',
    },
    {
      id: UUID.UUID(),
      videoSrc: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
      title: 'Edition 2018 de la nuit des entrepreneurs',
      vues: 855,
      time: 'Il ya 2 semaines',
    },
  ]
  
  @ViewChild('videoPlayer')
  videoplayer!: ElementRef<HTMLVideoElement>;

  @Input() showCount = true;
  @Input() totalImg!: (__number: number) => number;

  @Input()
  previewImage = false;

  showMask = false;
  currentLightboxImage: VideoItems = this.videoData[0];

  numberImg = this.videoData.slice(0, 5);

  @Input()
  currentIndex = 0;

  @Input()
  controls = true;

  @Input()
  totalImageCount = 0;

  followed = false;

  @Input() grid: 'auto' | 'style1' | 'style2' = 'auto';

  constructor(private modalService: DialogService,  @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,) {}

  ngOnInit(): void {
    this.totalImageCount = this.videoData.length;
    setTimeout(() => (this.isLoading = false), 2500);
  }

  public get classes(): string[] {
    const displayOne = this.grid === 'auto' ? 'd-grid-auto' : '';
    const displayTwo = this.grid === 'style1' ? 'd-grid-style1' : '';

    return [displayOne, displayTwo];
  }

  counter(i: number): Array<number> {
    return new Array(i);
  }

  startPreview(): void {
    this.videoplayer.nativeElement.muted = true;
    this.videoplayer.nativeElement.currentTime = 1;
    this.videoplayer.nativeElement.playbackRate = 0.5;
    this.videoplayer.nativeElement.play();
  }

  stopPreview(): void {
    this.videoplayer.nativeElement.currentTime = 0;
    this.videoplayer.nativeElement.playbackRate = 1;
    this.videoplayer.nativeElement.pause();
  }

  openPreviewImg(index: number): void {
    this.showMask = true;
    this.previewImage = true;
    this.currentIndex = index;
    this.currentLightboxImage = this.videoData[index];
  }

  goToPitch(e: any) {
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/request/suggestion-pitch`;
  }


  @Output()
  showImages(index: number) {
    const ref = this.modalService.open(DialogShowVideoGalleryComponent, {
      dialogType: DialogTypeEnum.Gallery,
      data: {
        title: `show gallery`,
        previewImage: true,
        controls: true,
        currentIndex: this.currentIndex,
        galleryItems: this.videoData,
      },
    });
    ref.afterClosed$.subscribe((result) => console.log(result));
  }
}
