<div
  class="datnek-ds-card network-user-suggestion-small suggestion-single pointer"
  *ngIf="!isLoading && status === avatarEnum.person"
  [ngClass]="
    isInline === true ? 'd-flex flex-row inline-box' : 'd-flex flex-column'
  "
  (datnekdsClickOrTouch)="goToPitch($event)"
>
  <div class="video" (mouseover)="startPreview()" (mouseout)="stopPreview()">
    <div class="play">
      <img src="assets/Img/icons/play-video.svg" alt="" />
    </div>
    <video class="embed-responsive embed-responsive-16by9" #videoPlayer>
      <source
        src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm"
      />
    </video>
  </div>
  <div class="infos d-flex">
    <datnek-ds-avatar [size]="sizeEnum.small" *ngIf="avatar"></datnek-ds-avatar>
    <div class="w-100 d-flex flex-column">
      <div class="name mb-2">
        <div class="display-2 clip ellipsis mr-2">
          {{ data.name }}
        </div>
        <img src="assets/icons/src/check-color.png" alt="" />
      </div>
      <span class="text-left truncate_2 details">
         {{ data.professionalTitle }} à {{ data.society }}</span
      >
      <span id="relations" class="display-8 mb-3 text-left">
        {{ data.relations }} relations en commun
      </span>
      <!-- <datnek-ds-button
        label="Suivre"
        [type]="colorEnum.primary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === false"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button>
      <datnek-ds-button
        label="Suivi"
        [type]="colorEnum.secondary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === true"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button> -->
      <div class="stats d-flex justify-content-between border-top py-2 gap-10">
        <div class="followers clip ellipsis"><span class="">264</span> following</div>
        <div class="followers clip ellipsis"><span class="">134</span> folowers</div>
      </div>
    </div>
    <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
  </div>
</div>

<div
  class="datnek-ds-card network-user-suggestion-small suggestion-single pointer"
  *ngIf="!isLoading && status === avatarEnum.society"
  [ngClass]="
    isInline === true ? 'd-flex flex-row inline-box' : 'd-flex flex-column'
  "
  (datnekdsClickOrTouch)="goToSociety()"
>
  <div class="img"></div>

  <div class="infos d-flex px-3 pt-3">
    <datnek-ds-avatar [size]="sizeEnum.small"></datnek-ds-avatar>
    <div class="name d-flex flex-column">
      <div class="d-flex">
        <div class="display-2 mr-2">
          {{ data.society | truncatetext: 15 }}
        </div>
      </div>
      <span>{{ data.sector | truncatetext: 15 }}</span>
      <span id="relations" class="display-8 mb-3">
        {{ data.relations }} relations en commun
      </span>
      <datnek-ds-button
        label="Suivre"
        [type]="colorEnum.primary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === false"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button>
      <datnek-ds-button
        label="Suivi"
        [type]="colorEnum.secondary"
        [block]="true"
        class="mb-3"
        *ngIf="followed === true"
        (datnekdsClickOrTouch)="onFollow()"
      ></datnek-ds-button>
      <div class="stats d-flex justify-content-between border-top py-3">
        <div class="followers"><span class="">264</span> following</div>
        <div class="followers"><span class="">134</span> folowers</div>
      </div>
    </div>
    <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
  </div>
</div>

<div class="network-user-suggestion-small" *ngIf="isLoading">
  <datnek-skeleton-suggestion-single></datnek-skeleton-suggestion-single>
</div>
