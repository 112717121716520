<div class="loading">
    <div class="anim-logo">
      <svg>
        <circle
          class="circle"
          cx="100"
          cy="100"
          r="75"
          stroke="#292B2C"
          stroke-width="10"
          fill-opacity="0"
        />
        <circle
          class="circle1"
          cx="100"
          cy="100"
          r="55"
          stroke="#dc3545"
          stroke-width="10"
          fill-opacity="0"
        />
        <circle
          class="circle2"
          cx="100"
          cy="100"
          r="35"
          stroke="#1877f2"
          stroke-width="10"
          fill-opacity="0"
        />
      </svg>
    </div>

    <img [src]="'Img/Logo%20Delenscio%20final.png' |assetUrl" class="logo-delenscio" alt="" />
  </div>  