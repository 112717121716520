<section
  class="
    z-150
    dc-header-menu
    d-flex
    justify-content-between
    align-items-center
    mx-1
    shadow-sm
  "
>
  <div class="input-search-topbar position-relative" *ngIf="searchbox">
    <input
      id="search"
      name="search"
      type="text"
      autocomplete="off"
      placeholder="Qu'est ce que vous recherchez ?"
      (keydown)="openAutocomplete(origin1)"
      #origin1
    />
    <datnek-ds-button-icon
      [type]="colorEnum.unstyled"
      [size]="sizeEnum.large"
      [Icon]="iconEnum.solidinboxclose"
      (datnekdsClickOrTouch)="closeSearchlist(); showSearchbox()"
    ></datnek-ds-button-icon>
  </div>
  <div class="container d-flex align-content-center" *ngIf="!isLoading">
    <div class="col-3 col-sm-1 col-md-3 d-flex align-items-center logo">
      <img
        [src]="'Img/Logo%20Delenscio%20final.png' |assetUrl"
        alt=""
        (datnekdsClickOrTouch)="home($event)"
        class="web d-none d-md-block pointer"
      />
      <img
        [src]="'Img/Icon-logo-delenscio.png' | assetUrl"
        alt=""
        (datnekdsClickOrTouch)="home($event)"
        class="mobile d-md-none pointer"
      />
    </div>
    <div
      class="
        col-md-6 col-sm-8
        d-md-flex d-none
        align-items-center
        justify-content-center
      "
    >
      <div
        class="menu-icon d-flex justify-content-between px-4"
        *ngIf="isLogin"
      >
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [Icon]="iconEnum.outlinehome"
          [size]="sizeEnum.large"
          (datnekdsClickOrTouch)="home($event)"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlineofficeBuilding"
          (datnekdsClickOrTouch)="enterprise($event)"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [Icon]="iconEnum.outlinebriefcase"
          [size]="sizeEnum.large"
          (datnekdsClickOrTouch)="link(environment.website.websiteDelenscioJob)"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [Icon]="iconEnum.outlinebell"
          [size]="sizeEnum.large"
          (datnekdsClickOrTouch)="notifications()"
          [badge]="true"
          [count]="9"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [Icon]="iconEnum.outlineuserGroup"
          [size]="sizeEnum.large"
          (datnekdsClickOrTouch)="groups()"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [Icon]="iconEnum.outlinechatAlt2"
          (datnekdsClickOrTouch)="chat($event)"
          [size]="sizeEnum.large"
        ></datnek-ds-button-icon>
      </div>
    </div>
    <div
      class="
        col-9 col-sm-11 col-md-3
        d-flex
        align-items-center
        justify-content-end
      "
      ngbDropdown
      #myDrop="ngbDropdown"
    >
      <!--<div
        class="searchbox align-items-center"
        [ngClass]="searchbox === true ? 'd-flex' : 'd-none'"
      >
        <datnek-ds-search-inputs
          placeholder="Rechercher dans les groupes"
          class="w-100"
        ></datnek-ds-search-inputs>
        <datnek-ds-button-icon
          [Icon]="iconEnum.solidinboxclose"
          [type]="colorEnum.unstyled"
          (datnekdsClickOrTouch)="showSearchbox()"
        ></datnek-ds-button-icon>
      </div>-->
      <ng-container *ngIf="isLogin">
        <datnek-ds-button-icon
          [size]="sizeEnum.medium"
          [type]="colorEnum.unstyled"
          [round]="true"
          [Icon]="iconEnum.outlinesearch"
          class="mr-3"
          (datnekdsClickOrTouch)="showSearchbox()"
        ></datnek-ds-button-icon>
        <div
          class="icon-profile pointer d-flex align-items-center mr-3"
          (datnekdsClickOrTouch)="openDropdown(sub, target1)"
           #target1
        >
          <datnek-ds-avatar
            [size]="sizeEnum.small"
            [type]="avatarEnum.person"
            class="mr-md-3"
          ></datnek-ds-avatar>
          <span class="display-4 d-none d-lg-block" *ngIf="currentUser && currentUser.FirstName">{{
            currentUser.FirstName  | truncatetext: 8
          }}</span>
        </div>

        <!-- <div
          class="dropdown-menu-block dropdown-menu dropdown-menu-right mt-md-1 p-md-3"
          ngbDropdownMenu
          aria-labelledby="dropdownBasic2"
        >
          <div class="display-4 mb-3">Changer de profil</div>
          <div class="d-flex align-items-center pointer mb-3" (datnekdsClickOrTouch)=" profile()">
            <datnek-ds-avatar
              [size]="sizeEnum.small"
              [type]="avatarEnum.person"
              class="mr-md-3"
            ></datnek-ds-avatar>
            <span class="display-4 d-none d-lg-block" *ngIf="currentUser && currentUser.FirstName">{{
              currentUser.FirstName  | truncatetext: 16
            }}</span>
          </div>
          <div class="d-flex align-items-center pointer" (datnekdsClickOrTouch)="company()">
            <datnek-ds-avatar
              [size]="sizeEnum.small"
              [type]="avatarEnum.society"
              class="mr-md-3"
            ></datnek-ds-avatar>
            <span class="display-4 d-none d-lg-block">
              Digitaille</span>
          </div>
        </div> -->
        <del-shared-app-header></del-shared-app-header>
      </ng-container>

      <ng-container *ngIf="!isLogin">
        <datnek-ds-button
          label="Connectez-vous"
          [size]="sizeEnum.large"
          [type]="colorEnum.primary"
          (datnekdsClickOrTouch)="login($event)"
        ></datnek-ds-button>
      </ng-container>
    </div>
  </div>
  <div class="container d-flex skeleton" *ngIf="isLoading">
    <div class="col-3 col-sm-1 col-md-3 d-flex align-items-center logo">
      <div class="button medium-h w-50 d-none d-md-block"></div>
      <div class="circle medium-c d-md-none"></div>
    </div>
    <div
      class="
        col-md-6 col-sm-8
        d-md-flex d-none
        align-items-center
        justify-content-center
      "
      style="gap: 3rem !important"
    >
      <div class="square medium-square"></div>
      <div class="square medium-square"></div>
      <div class="square medium-square"></div>
      <div class="square medium-square"></div>
      <div class="square medium-square"></div>
      <div class="square medium-square"></div>
    </div>
    <div
      class="
        col-9 col-sm-11 col-md-3
        d-flex
        align-items-center
        justify-content-end
        gap-3
      "
    >
      <div class="circle medium-c"></div>
      <div class="d-sm-flex align-items-center d-none" style="width: 35%">
        <div class="circle medium-c"></div>
        <div
          class="line h15"
          style="width: calc(65% - 16px); margin-left: 16px"
        ></div>
      </div>
      <div class="circle medium-c d-sm-none"></div>
      <div class="circle medium-c"></div>
    </div>
  </div>
</section>


<!-- DROPDOWNS -->
<ng-template #sub let-close="close">
  <div class="datnek-ds-card dropdown-card edit-menu">
    <div class="display-4 mb-3">Changer de profil</div>
    <a
      class="dropdown-item align-items-center p-1 d-flex gap-10"
      (datnekdsClickOrTouch)=" profile(); close()"
      #target2
    >
      <datnek-ds-avatar
              [size]="sizeEnum.small"
              [type]="avatarEnum.person"
              class=""
            ></datnek-ds-avatar>
            <span class="display-4" *ngIf="currentUser && currentUser.FirstName">{{
              currentUser.FirstName  | truncatetext: 16
            }}</span>
    </a>
    <a
      class="dropdown-item align-items-center p-1 d-flex gap-10"
      (datnekdsClickOrTouch)=" company(); close()"
      #target2
    >
      <datnek-ds-avatar
              [size]="sizeEnum.small"
              [type]="avatarEnum.society"
              [imgSrc]="iconEnum.avatarSociety"
              class=""
            ></datnek-ds-avatar>
            <span class="display-4">
              Digitaille</span>
    </a>
  </div>
</ng-template>