/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { Emails } from '../../emails/emails.model';


@Injectable({
  providedIn: 'root',
})
export class EmailListService {
  public emailList: Emails[] = [];

  constructor() {}

  addEmail(email: any) {
    const id = this.emailList.length + 1;

    const item: Emails = {
      id: id,
      email: email,
    };
    this.emailList.unshift(item);
  }

  deleteEmail(item: any) {
    const index = this.emailList.indexOf(item);
    this.emailList.splice(index, 1);
  }
}
