import {RouterModule, Routes} from "@angular/router";
import {HOME_ROUTES} from "@network-shared";
import {HomeAppComponent, HomeComponent} from "./ui/container";
import {HelpDevComponent} from "./ui/components/help-dev/help-dev.component";
import {UiBoxComponent} from "./ui/components/ui-box/ui-box.component";
import {AddArticleComponent} from "./ui/components/add-article/add-article.component";
import {ContactsComponent} from "./ui/components/contacts/contacts.component";
import {EventsComponent} from "./ui/components/events/events.component";
import {SingleEventComponent} from "./ui/components/events/single-event/single-event.component";
import {SinglePostPageComponent} from "./ui/components/single-post-page/single-post-page.component";
import {PaiementCheckoutComponent} from "./ui/components/paiement-checkout/paiement-checkout.component";
import {SingleArticleComponent} from "./ui/components/single-article/single-article.component";
import {SavedMessagesComponent} from "./ui/components/saved-messages/saved-messages.component";
import {NotificationsComponent} from "./ui/components/notifications/notifications.component";
import {ApiGoogleYahooComponent} from "./ui/components/api-google-yahoo/api-google-yahoo.component";
import {ListArticlesComponent} from "./ui/components/list-articles/list-articles.component";
import {BlankComponent} from "./ui/components/blank/blank.component";
import {
  SavedCategoryMessagesComponent
} from "./ui/components/saved-category-messages/saved-category-messages.component";
import {NgModule} from "@angular/core";
import {HomepageComponent} from "@del-shared";
import { CalendarViewComponent, ListViewComponent, UsersListEventComponent } from "./ui";

const routes: Routes = [
  { path: HOME_ROUTES.HOME, component: HomeComponent },
  { path: HOME_ROUTES.HOME_APP, component: HomeAppComponent },
  { path: HOME_ROUTES.HOME_PAGE, component: HomepageComponent },
  { path: HOME_ROUTES.HELP_DEV, component: HelpDevComponent },
  { path: HOME_ROUTES.UI_BOX, component: UiBoxComponent },
  { path: HOME_ROUTES.ADD_ARTICLE, component: AddArticleComponent },
  { path: HOME_ROUTES.CONTACTS, component: ContactsComponent },
  {
    path: HOME_ROUTES.EVENTS, component: EventsComponent,
    children: [
      { path: HOME_ROUTES.CALENDAR_VIEW, component: CalendarViewComponent },
      { path: HOME_ROUTES.LIST_VIEW, component: ListViewComponent },
    ],
  },
  { path: HOME_ROUTES.SINGLE_EVENTS, component: SingleEventComponent },
  { path: HOME_ROUTES.EVENT_USERS, component: UsersListEventComponent },
  //{ path: HOME_ROUTES.SINGLE_EVENTS, component: SingleEventComponent },
  { path: HOME_ROUTES.SINGLE_POST_PAGE, component: SinglePostPageComponent },
  { path: HOME_ROUTES.CHECKOUT, component: PaiementCheckoutComponent },
  { path: HOME_ROUTES.SINGLE_ARTICLE, component: SingleArticleComponent },
  { path: HOME_ROUTES.SAVED_MESSAGES, component: SavedMessagesComponent },
  { path: HOME_ROUTES.NOTIFICATIONS, component: NotificationsComponent },
  { path: HOME_ROUTES.API_GOOGLE_YAHOO, component: ApiGoogleYahooComponent },
  { path: HOME_ROUTES.LIST_ARTICLES, component: ListArticlesComponent },
  { path: HOME_ROUTES.BLANK, component: BlankComponent },
  {
    path: HOME_ROUTES.SAVED_CATEGORY,
    component: SavedCategoryMessagesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
