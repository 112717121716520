export const environment = {
  production: false,
  securityRoute:  {
    home: 'de',
    register: '/de/register',

    registerStep1: '/de/register/step1',
    registerStep2: '/de/register/step2',
    registerStep3: '/de/register/step3',
    registerStep3SMS: '/de/register/step3/phone/check',

    registerMeStudentStep4: '/de/register/me/step4/student',
    registerMeEmployeeStep4: '/de/register/me/step4/employee',
    registerMeSelfContractorStep4: '/de/register/me/step4/self-contractor',
    registerMeUnemployedStep4: '/de/register/me/step4/unemployed',

    registerCompanyStep3: '/de/register/company/step3',

    registerStep5EmailVerify: '/de/register/step5/email-verify',


    login: '/de/auth/login',
    forgetPassword: '/de/auth/forgot/password',
    forgetPasswordStep2Email: '/de/auth/forgot/password/step2/email',
    forgetPasswordStep3Email: '/de/auth/forgot/password/step3/email',
    forgetPasswordStep2SMS: '/de/auth/forgot/password/step2/sms',
    forgetPasswordStep3SMS: '/de/auth/forgot/password/step3/sms',
    forgetPasswordNew: '/de/auth/forgot/password/new',

    logout: '/de/logout',
    webSite: 'https://dev.delenscio.com',
    defaultRedirectAuth: 'https://dev.delenscio.com'
  },
  website: {
    websiteDelenscioCvLibrary: 'https://dev.delenscio.com/resume',
    websiteDelenscioTesting: 'https://dev.delenscio.com/testing',
    websiteDelenscioJob: 'https://dev.delenscio.com/jobs',
    websiteDelenscioNetwork: 'https://dev.delenscio.com/',
    websiteDelenscioHome: 'https://dev.delenscio.com/home',
    websiteDelensciolearning: 'https://dev.delenscio.com/learning'
  },
  api: {
    cv: 'https://api.delenscio.be/dev-resume/api',
    home: 'https://api.delenscio.be/dev-user/api',
    network: 'https://api.delenscio.be/dev-network/api',
    learning: 'https://api.delenscio.be/dev-elearning/api',
    testing: 'https://api.delenscio.be/dev-testing/api',
    jobs: 'https://api.delenscio.be/dev-jobs/api',

    // FTP
    medias: 'https://dev-file.delenscio.be/uploads/'
  },
  // api: {
  //   cv: 'https://dev-api.delenscio.com/resume/api',
  //   home: 'https://dev-api.delenscio.com/home/api',
  //   network: 'https://dev-api.delenscio.com/network/api',
  //   learning: 'https://dev-api.delenscio.com/learning/api',
  //   testing: 'https://dev-api.delenscio.com/testing/api',
  //   jobs: 'https://dev-api.delenscio.com/jobs/api',
  //   identity: 'https://dev-api.delenscio.com/identity',

  //   // FTP
  //   medias: 'https://dev.delenscio.com/file/uploads/'
  // },
  paginationTake: {
    //=========== Formations ===========
    educations: 15,
    articles: 15,
    projects: 15,
    certifications: 15,

    //=========== Competences ===========
    experiences: 4,
    recommandations: 4,
    portfolios: 4,

    //=========== Competences ===========
    skillsUser: 1000,

    //=========== Publications ===========
    publications: 3,

    //=========== Languages ===========
    languages: 4,

    // ************************************ COMPANY ***********************
    products: 2,
    services: 3,
    events: 3,
    medias: 5,

    //=========== Parametres / Locations ===========
    locationsHistories: 15,
  },
};
