import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseHttpService,
  Environment,
  ListResult,
  LoaderService,
} from 'datnek-core';
import { Event as EventCompany } from '../../../models';
import { assetUrl } from 'datnek-ds';
@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseHttpService<EventCompany, EventCompany> {
  events: EventCompany[] = [
    {
      Id: '1',
      CreateAt: new Date(),
      UpdateAt: new Date(),
      Title: "Le meilleur évènement inédit de l'année 2024 sur les effets de l'IA sur la conscience",
      SubTitle: 'Sous-titre de mon premier event',
      ReportOfTheMeeting: 'Aucun report',
      StartDate: '12 dec 2023',
      StartTime: { hours: 12, minutes: 34 },
      AddressId: 'Zoom',
      EventImage: assetUrl('Img/items/attachment_98927507.png'),
    },
    {
      Id: '2',
      CreateAt: new Date(),
      UpdateAt: new Date(),
      Title: 'Mon deuxième évènement !',
      SubTitle: 'Sous-titre de mon deuxième event',
      ReportOfTheMeeting: 'Aucun report',
      StartDate: '12-dec-2023',
      StartTime: { hours: 12, minutes: 34 },
      AddressId: 'Iron palace',
      EventImage: assetUrl('Img/items/1d29bdc244b2aa8503f3ca4a531f3fb4.webp'),
    },
  ];

  constructor(
    http: HttpClient,
    loaderService: LoaderService,
    @Inject(Environment.Provider.API) private api: Environment.Api
  ) {
    super(http, loaderService);
    this.setUrl(`${this.api.network}/Events`);
  }

  getAllEvents(): EventCompany[] {
    return this.events;
  }

  getEventById(eventId: string): EventCompany {
    const event = this.events.find((event) => event.Id === eventId);
    if (!event) {
      throw new Error('Aucun Evènement sur cet ID');
    } else {
      return event;
    }
  }

  getByCompany(
    companyId: string,
    skip: number,
    take: number,
    globalLoading = false
  ): Observable<ListResult<EventCompany>> {
    return this.getLazyData(
      take,
      skip,
      `${this.rootURL}/Company/${companyId}`,
      globalLoading
    );
  }
}
