import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import {
  AvatarTypeEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  IconEnum,
  DialogService,
  DialogTypeEnum,
  SelectGlobalSearchComponent,
  BaseComponent,
} from 'datnek-ds';
import { Environment } from 'datnek-core';
import { AccountTypeEnum, AuthService, GenderEnum, UserSsoState } from 'datnek-sso';
import { Store } from '@ngxs/store';
import { User } from '@del-shared';
@Component({
  selector: 'del-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;

  isLoading = true;
  searchList = false;

  currentUser!: User;
  searchbox = false;

  isLogin = true;

  educationUrl = '/private/user/formation/education';

  constructor(
    public router: Router,
    private store: Store,
    @Inject(Environment.Provider.ENVIRONMENT)
    public environment: Environment.Env,
    private modalService: DialogService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    const sub = this.store
      .select((state) => state.user.currentUser)
      .subscribe((res) => {
        this.isLogin = !!res;
        this.currentUser = res;
        // this.isLoading = true;
        // this.currentUser = new User(
        //   "12",
        //   new Date(),
        //   new Date(),
        //   'Bassahak',
        //   "Jean Marc",
        //   GenderEnum.MAN,
        //   "21/10/1984",
        //   "237699461205",
        //   "jmbassahak@gmail.com",
        //   "jaimeJesus***",
        //   AccountTypeEnum.ME,
        //   "none"
        // );
      });

    this.subscribe.add(sub);
    setTimeout(() => (this.isLoading = false), 2500);
    //this.educationUrl = `${this.environment.website.websiteDelenscioHome}${this.educationUrl}`;
    console.log('EDU URL : ', this.educationUrl)
  }

  openDropdown(tpl: TemplateRef<any>, origin: HTMLElement): void {
    this.modalService.open(tpl, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }


  openAutocomplete(origin: HTMLElement): void {
    this.modalService.open(SelectGlobalSearchComponent, {
      dialogType: DialogTypeEnum.Select,
      origin,
      data: {
        id: 1,
      },
    });
  }

  home(e:any) {
    e.preventDefault();
    //this.router.navigate(['/']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}`;
  }

  onFocus() {
    this.searchList = !this.searchList;
  }

  closeSearchlist() {
    this.searchList = false;
  }

  enterprise(e:any) {
    e.preventDefault();
    //this.router.navigate(['/enterprise/list-enterprises']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/enterprise/list-enterprises`;
  }

  chat(e:any) {
    e.preventDefault();
    //this.router.navigate(['/chat/home']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/chat/home`;
  }

  login(e: any) {
    e.preventDefault();
    this.router.navigateByUrl(this.environment.securityRoute.login);
    /* this.isLogin = true;
    localStorage.setItem('partialLogin', 'true');
    this.router.navigate(['/']);
    window.location.reload();  */
    // this.router.navigate(['/register']);
  }

  profile() {
    //e.preventDefault();
    //this.router.navigateByUrl(`${this.environment.website.websiteDelenscioHome}${this.educationUrl}`);
    window.location.href = `${this.environment.website.websiteDelenscioHome}${this.educationUrl}`;
    //this.router.navigate([this.educationUrl]);
  }

  company() {
    //e.preventDefault();
    //this.router.navigateByUrl(`${this.environment.website.websiteDelenscioHome}${this.educationUrl}`);
    window.location.href = `${this.environment.website.websiteDelenscioHome}/private/company/home`;
    //this.router.navigate([this.educationUrl]);
  }

  groups() {
    //this.router.navigate(['/groups/home-group']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/groups/home-group`;
  }
  notifications() {
    //this.router.navigate(['/notifications']);
    window.location.href = `${this.environment.website.websiteDelenscioNetwork}/notifications`;
  }

  link(url: string) {
    window.open(url, '_blank');
  }

  showSearchbox() {
    this.searchbox = !this.searchbox;
  }
}
