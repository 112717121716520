/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  AvatarTypeEnum,
  ControlDisplayEnum,
  ControlSizeEnum,
  ControlTypeColorEnum,
  DialogRef,
  FileAcceptEnum,
  IconEnum,
  IconRadioImageEnum,
  ImageTextDropdownFormlyFieldProps,
  InputCheckboxFormlyFieldProps,
  InputDateFormlyFieldProps,
  InputTimeFormlyFieldProps,
  Select2FormlyFieldProps,
  SelectListTypeEnum,
  UtilsInterface,
  UploadFileFormlyFieldProps,
  DialogService,
  DialogTypeEnum,
  TooltipTypeEnum
} from 'datnek-ds';
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'del-shared-dialog-add-calendar',
  templateUrl: './dialog-add-calendar.component.html',
  styleUrls: ['./dialog-add-calendar.component.scss'],
})
export class DialogAddCalendarComponent implements OnInit {
  accept = [FileAcceptEnum.image];
  iconEnum = IconEnum;
  sizeEnum = ControlSizeEnum;
  colorEnum = ControlTypeColorEnum;
  avatarEnum = AvatarTypeEnum;
  displayEnum = ControlDisplayEnum;

   @ViewChild('afterPublish')
  private afterPublish!: TemplateRef<any>;

  users = [
    {
      name: 'John Doe',
      competence: 'IT Developer',
      society: 'UBI SOFT',
    },
    {
      name: 'Boris Belobo',
      competence: 'Web Designer Jr',
      society: 'DIGITAILLE',
    },
    {
      name: 'Yankap Arno Ghislain',
      competence: '',
      society: 'Delenscio',
    },
  ];


  toDay = new Date();
  isLoading = false;
  form = new FormGroup({});
  model = {
    EventType: null,
    Background: null,
    EventName: null,
    CreatorId: null,
    InRemoteLink: null,
    InRemoteAddress: null,
    StartDate: null,
    EndDate: null,
    StartTime: null,
    EndTime: null,
    Description: null,
    Intervenants: null,
  };
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: true,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      key: 'Background',
      type: 'upload',
      props: {
        required: true,
        accept: [FileAcceptEnum.image],
        title: 'Téléchargez une photo de couverture',
        imgSize: 'Largeur minimum de 1280 X 720',
        fileType: 'single',
        type: 'image',
        multimedia: false,
        placeholder: 'Ajouter une légende',
      } as UploadFileFormlyFieldProps,
    },
    {
      wrappers: ['panel-div'],
      props: {
        label: 'p-3',
      },
      fieldGroup: [
        {
          key: 'CreatorId',
          type: 'dropdown-image-text',
          props: {
            label: 'Organisateur',
            required: true,
            isFormControl: true,
            icon: IconRadioImageEnum.me,
            options: [
              {
                label: 'Danick takam',
                value: 'danick',
                type: ControlDisplayEnum.inline,
                icon: IconRadioImageEnum.me,
              },
              {
                label: 'Datnek Bv',
                value: 'datnek',
                type: ControlDisplayEnum.inline,
                icon: IconRadioImageEnum.enterprise,
              },
              {
                label: 'Afelio',
                value: 'afelio',
                type: ControlDisplayEnum.inline,
                icon: IconRadioImageEnum.enterprise,
              },
            ] as UtilsInterface.DropdownItem[],
          } as ImageTextDropdownFormlyFieldProps,
        },
        {
          key: 'EventType',
          type: 'checkbox',
          props: {
            label: "Type d'évènement",
            position: 'flex',
            required: true,
            options: [
              { Label: 'En ligne', Value: 'In remote' },
              { Label: 'En Présentiel', Value: 'In office' },
            ],
          } as InputCheckboxFormlyFieldProps,
        },
        {
          key: 'InRemoteLink',
          type: 'url',
          props: {
            label: 'Lien vers le meeting en ligne',
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              return field.model?.EventType?.includes('remote');
            },
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.EventType?.includes('remote');
            },
          },
        },
        {
          key: 'InRemoteAddress',
          type: 'text',
          props: {
            label: "Adresse du lieu de l\\'évènement",
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              return field.model?.EventType?.includes('office');
            },
            hide: (field: FormlyFieldConfig) => {
              return !field.model?.EventType?.includes('office');
            },
          },
        },
        {
          key: 'EventName',
          type: 'name',
          props: {
            label: "Nom de l'évènement",
            required: true,
          },
        },

        {
          fieldGroupClassName: 'form-group-custorm',
          fieldGroup: [
            {
              key: 'StartDate',
              type: 'date',
              props: {
                label: 'Date de début',
                required: true,
                minDate: this.toDay,
              } as InputDateFormlyFieldProps,
              expressions: {
                className: (field: FormlyFieldConfig) => {
                  return field.model?.StartDate
                    ? 'col-md-6 col-sm-12'
                    : 'col-12';
                },
              },
            },
            {
              className: 'col-md-6 col-sm-12',
              key: 'StartTime',
              type: 'time',
              props: {
                label: 'Heure de début',
                required: true,
              } as InputTimeFormlyFieldProps,
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model?.StartDate &&
                    !isNaN(Date.parse(field.model?.StartDate))
                  );
                },
                'props.minTime': (field: FormlyFieldConfig) => {
                  return field.model.StartDate &&
                    new Date(field.model.StartDate) > this.toDay
                    ? null
                    : new Date().toLocaleTimeString().substr(0, 5);
                },
              },
              /*
              validators: {
                minDate: {
                  expression: (c: AbstractControl, field) => !c.value || c.value > field.model?.StartDate,
                  message: (error: any, field: FormlyFieldConfig) => {
                    const date = new Date(field.model?.StartDate);
                    return this.utilsService.label.dateMinInvalid(`${date.getDay()}/${date.getMonth()+1}/${date.getFullYear()}`);
                  },
                },
              }
             */
            },
          ],
        },

        {
          fieldGroupClassName: 'form-group-custorm',
          fieldGroup: [
            {
              key: 'EndDate',
              type: 'date',
              props: {
                label: 'Date de fin',
                required: true,
                minDate: new Date(),
              } as InputDateFormlyFieldProps,
              expressions: {
                className: (field: FormlyFieldConfig) => {
                  return field.model?.StartDate
                    ? 'col-md-6 col-sm-12'
                    : 'col-12';
                },
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model?.StartDate &&
                    !isNaN(Date.parse(field.model?.StartDate))
                  );
                },
                'props.minDate': (field: FormlyFieldConfig) => {
                  return new Date(field.model?.StartDate);
                },
              },
            },
            {
              className: 'col-md-6 col-sm-12',
              key: 'EndTime',
              type: 'time',
              props: {
                label: 'Heure de fin',
                required: true,
              } as InputTimeFormlyFieldProps,
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model?.StartDate &&
                    !isNaN(Date.parse(field.model?.StartDate))
                  );
                },
                'props.minTime': (field: FormlyFieldConfig) => {
                  return field.model.EndDate &&
                    field.model?.StartDate &&
                    field.model.EndDat > field.model?.StartDate
                    ? null
                    : field.model?.StartTime;
                },
              },
            },
          ],
        },

        {
          key: 'Description',
          type: 'editor',
          props: {
            label: 'Description',
            className: ['h-110'],
            placeholder: 'Commencez à rédiger...',
            required: true,
          },
        },

        {
          key: 'Intervenants',
          type: 'select2',
          props: {
            label: 'Intervenants',
            required: true,
            selectType: SelectListTypeEnum.users,
            options: [
              { Label: 'Iron Man', Value: 'iron_man' },
              { Label: 'Captain America', Value: 'captain_america' },
              { Label: 'Black Widow', Value: 'black_widow' },
              { Label: 'Hulk', Value: 'hulk' },
              { Label: 'Captain Marvel', Value: 'captain_marvel' },
            ],
          } as Select2FormlyFieldProps,
        },
      ],
    },
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    private translateService: TranslateService,
    private modalService: DialogService,
  ) {
    this.toDay.setHours(0, 0, 0);
  }

  ngOnInit(): void {}

  submit() {
    const ref = this.modalService.open(this.afterPublish, {
      dialogType: DialogTypeEnum.Toast,
      tooltipType: TooltipTypeEnum.base,
    });
    if(this.form.valid){
      // SHOW TOAST
      this.dialogRef.close();
      console.log(this.model)
    }
  }
}
