<div class="d-grid" [ngClass]="classes">
    <div
    class="datnek-ds-card suggestion-card-mobile pointer"
    *ngFor="let data of videoData; let i = index"
    (datnekdsClickOrTouch)="goToPitch($event)"
  >
    <div class="video" (mouseover)="startPreview()" (mouseout)="stopPreview()">
      <div class="play">
        <img src="assets/Img/icons/play-video.svg" alt="" />
      </div>
      <video class="embed-responsive embed-responsive-16by9" #videoPlayer>
        <source
          src="{{data.videoSrc}}"
        />
      </video>
    </div>
    <div class="infos p-2 d-flex">
 
      <div class="w-100 d-flex flex-column gap-2">
        <div class="name">
          <div class="display-3 truncate_2 ">
            {{ data.title }}
          </div>
        </div>

        <span id="relations" class="display-8 mb-3 text-left">
          {{ data.time }} - {{ data.vues }} vues
        </span>
        
      </div>
      <!--<datnek-ds-button iconLeft="user" label=""></datnek-ds-button>-->
    </div>
  </div>
</div>
