<div
  class="image-gallery {{ galleryClass }}"
  [class.is-group-gallery]="isgroupMultimedia === true"
  *ngIf="imagesItems.length && !isLoading && !closed"
>
  <ng-container *ngFor="let item of imagesItems; let i = index">
    <figure
      class="gallery__item"
      [class.last-img]="i === 4 && totalImageCount > 5"
      [attr.rest]="'+' + (totalImageCount - 5).toString()"
      *ngIf="i < 5 && !isList"
      (datnekdsClickOrTouch)="showImages(i)"
      [style]="getThumbHeight()"
    >
      <img [src]="item.imageSrc" alt="item.imageAlt" title="item.title" />
    </figure>
    <figure *ngIf="isList" (datnekdsClickOrTouch)="showImages(i)">
      <img [src]="item.imageSrc" alt="item.imageAlt" title="item.title" />
    </figure>
  </ng-container>

  <!--<datnek-ds-button-icon
    [Icon]="iconEnum.solidinboxclose"
    [type]="colorEnum.secondary"
    [round]="true"
    class="icon-close ml-3"
    (datnekdsClickOrTouch)="close()"
    *ngIf="closable"
  ></datnek-ds-button-icon>-->
</div>

<datnek-skeleton-multimedia-images
  *ngIf="isLoading"
></datnek-skeleton-multimedia-images>

<datnek-ds-no-content
  *ngIf="!imagesItems.length"
  imgSrc="assets/icons/src/image.svg"
  titleMsg="Aucune image"
  contentDesc="Vous n’avez aucune image ajoutée  pour l’instant"
></datnek-ds-no-content>
