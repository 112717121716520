<div
  class="datnek-ds-card publication-box"
  [class.border]="shared === true"
  [ngClass]="classes"
  *ngIf="!isLoading"
>
  <div class="datnek-ds-card-header d-flex mb-3">
    <div
      class="user-infos d-flex justify-content-between w-100 align-items-start"
    >
      <del-shared-user-infos
        name="John Doe kqjdnjqdsjdjkkjsdfkdj skfhdkjs jfhfkjhfkjhfkdsjfhdskjhf kjfhkdsjh fkdjfhkjsdfhkdjsfh kjfh kdsjfhkdjsh fds"
        professionalTitle="Graphic Designer Senior"
        [status]="status"
      ></del-shared-user-infos>

      <button class="" (click)="openDropdown(actions, origin2)" #origin2>
        <datnek-ds-button-icon
          [Icon]="iconEnum.outlinedotsHorizontal"
          [type]="colorEnum.ghost"
          [round]="true"
        ></datnek-ds-button-icon>
      </button>

      <div class="follow" *ngIf="shared === true">
        <datnek-ds-button-icon
          [type]="colorEnum.primary"
          [size]="sizeEnum.medium"
          [Icon]="iconEnum.outlineWhiteuserAdd"
          (datnekdsClickOrTouch)="isFollowed()"
          *ngIf="follow === false && status === 'person'"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.secondary"
          [size]="sizeEnum.medium"
          [Icon]="iconEnum.outlinecheck"
          (datnekdsClickOrTouch)="isFollowed()"
          *ngIf="follow === true && status === 'person'"
        ></datnek-ds-button-icon>

        <datnek-ds-button-icon
          [type]="colorEnum.primary"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlineWhiteofficeAdd"
          (datnekdsClickOrTouch)="isFollowed()"
          *ngIf="follow === false && status === 'society'"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.secondary"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlinecheck"
          (datnekdsClickOrTouch)="isFollowed()"
          *ngIf="follow === true && status === 'society'"
        ></datnek-ds-button-icon>
      </div>
    </div>
  </div>
  <div class="datnek-ds-card-content">
    <!--TEXT-->
    <div class="text mb-3">
      <p>
        {{ readMore ? longText : (longText | slice: 0:250) }}
        <span *ngIf="!readMore">...</span>
      </p>
      <datnek-ds-link
        href="javascript:;"
        label="Voir plus"
        *ngIf="!readMore"
        (datnekdsClickOrTouch)="readMore = true"
      ></datnek-ds-link>
      <datnek-ds-link
        href="javascript:;"
        label="Voir moins"
        *ngIf="readMore"
        (datnekdsClickOrTouch)="readMore = false"
      ></datnek-ds-link>
    </div>

    <!--IF: GALLERY IMAGES-->
    <ng-container *ngIf="galleryData.length && publicationType === 'images'">
      <datnek-ds-adaptative-image-gallery></datnek-ds-adaptative-image-gallery>
    </ng-container>

    <!--IF: VIDEO PLAYER-->
    <ng-container *ngIf="videoData.length && publicationType === 'video'">
      <div class="video-publication mb-3 {{ galleryClass }}">
        <video controls autoplay>
          <ng-container *ngFor="let data of videoData">
            <source src="{{ data.videoSrc }}" type="video/mp4" />
          </ng-container>

          <!--<source src="movie.ogg" type="video/ogg">-->
          Your browser does not support the video tag.
        </video>
      </div>
    </ng-container>

    <!--IF: DOCUMENT VIEW-->
    <ng-container *ngIf="documentData.length && publicationType === 'document'">
      <div
        class="document-publication mb-3 {{ galleryClass }}"
        (datnekdsClickOrTouch)="redirectToCV()"
      >
        <ng-container *ngFor="let data of documentData">
          <div class="document-box">
            <div class="viewbox d-flex justify-content-center">
              <img src="{{ data.docKeyshot }}" alt="" />
            </div>
            <div
              class="infosbox d-flex align-items-center justify-content-between flex-wrap"
            >
              <div class="left mr-3">
                <div class="display-1 mb-3">
                  {{ data.docName | truncatetext: 55 }}
                </div>
                <p>{{ data.docDescription | truncatetext: 150 }}</p>
              </div>
              <datnek-ds-button
                [label]="'Consulter'"
                [size]="sizeEnum.large"
                [type]="colorEnum.primary"
                class="text-right"
              ></datnek-ds-button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!--IF: SHARED POST-->
    <ng-container *ngIf="publicationType === 'post'">
      <div class="mb-3">
        <del-shared-card-publication-box
          publicationType="images"
          [shared]="true"
          [status]="avatarEnum.person"
          class="mb-3"
        ></del-shared-card-publication-box>
      </div>
      
    </ng-container>

    <!--IF: CHALLENGE-->
    <ng-container *ngIf="publicationType === 'challenge'">
      <div class="mb-3">
        <del-shared-card-challenge-box
          [challengeData]="challengeData"
          [challengeEnum]="challengeEnum.future"
          [fullChallenges]="true"
          [acceptedChallenges]="false"
          [listChallenges]="true"
          class="mb-3"
        ></del-shared-card-challenge-box>
      </div>
      
    </ng-container>

    <!--IF: SHARED ARTICLE-->
    <ng-container *ngIf="publicationType === 'article'">
      <div
        class="article-box mb-3 pointer"
        (datnekdsClickOrTouch)="openArticle($event)"
      >
        <div class="img">
          <img [src]="'Img/AdobeStock_104857309-1140x650.jpg' | assetUrl" alt="" />
        </div>
        <div class="desc p-3">
          <div class="display-1 mb-3">
            {{ articleTitle | truncatetext: 55 }}
          </div>
          <p class="short">
            {{ articleDesc | truncatetext: 150 }}
          </p>
        </div>
      </div>
    </ng-container>

    <!--IF: SHARED GROUP-->
    <ng-container *ngIf="publicationType === 'group'">
      <div
        class="group-box mb-3 pointer"
        (datnekdsClickOrTouch)="openGroup($event)"
      >
        <div class="img">
          <img [src]="'Img/group-placeholder-bg.jpg' | assetUrl" alt="" />
        </div>
        <div class="desc p-3">
          <div class="infos-group d-flex mb-2">
            <span>Groupe public</span> <span class="point"></span>
            <span>92 membres</span>
          </div>
          <div class="display-1 mb-3">{{ groupName | truncatetext: 45 }}</div>
        </div>
      </div>
    </ng-container>

    <!--IF: SHARED EVENT-->
    <ng-container *ngIf="publicationType === 'event'">
      <div
        class="group-box mb-3 pointer"
        (datnekdsClickOrTouch)="openEvent($event)"
      >
        <div class="img">
          <img [src]="'Img/Event-placeholder.jpg' | assetUrl" alt="" />
        </div>
        <div class="desc p-3">
          <div class="display-1 mb-3">{{ eventTitle | truncatetext: 45 }}</div>
          <div class="d-flex justify-content-around" style="gap: 16px">
            <div class="date mr-3">
              <span>Du : 12/12/2022</span>
            </div>

            <div class="date">
              <span>Au : 23/02/2023</span>
            </div>
            <datnek-ds-link
              label="Voir l'évènement"
              href="#"
              target="_blank"
              class="mb-3 mx-3"
            ></datnek-ds-link>
          </div>
        </div>
      </div>
    </ng-container>

    <!--LIKES-->
    <div
      class="
        datnek-ds-card-likes
        d-flex
        justify-content-between
        p-md-3
        mb-3 mb-md-0
      "
    >
      <div class="left d-flex align-items-center">
        <div class="mr-3 d-flex align-items-center">
          <img
            src="../assets/icons/src/solid/color/heart-active-color.svg"
            alt=""
            class="mr-2"
          />
          <span class="display-6 stat-post pointer">
            12k
            <div class="drop-stat-post datnek-ds-card">
              <ul>
                <ng-container
                  *ngFor="let item of haveReaction | slice: 1:5; let i = index"
                >
                  <li>{{ item }}</li>
                </ng-container>
                <li>+ {{ rest }} 13 autres personnes</li>
              </ul>
            </div>
          </span>
        </div>
        <div class="mr-3 d-flex align-items-center">
          <img
            src="../assets/icons/src/solid/color/comment-active-color.svg"
            alt=""
            class="mr-2"
          />
          <span class="display-6">234</span>
        </div>
        <div class="d-flex align-items-center">
          <img
            src="../assets/icons/src/solid/color/share-active-color.svg"
            alt=""
            class="mr-2"
          />
          <span class="display-6">1k</span>
        </div>
      </div>
      <span
        class="text-right display-6 pointer"
        (datnekdsClickOrTouch)="onShowListComments()"
        >45 commentaires</span
      >
    </div>
  </div>

  <!--LIKES BUTTONS-->
  <div
    class="datnek-ds-card-likes-buttons d-flex align-items-center pb-3 z-200"
    *ngIf="shared === false"
  >
      <datnek-ds-avatar-dropdown></datnek-ds-avatar-dropdown>
    <div class="buttons-icons">
      <div
        class="like-btn"
        (datnekdsClickOrTouch)="openDropdown(likesicons, origin1)"
        #origin1
      >
        <datnek-ds-button
          [label]="'Likes'"
          [size]="sizeEnum.large"
          [block]="true"
          [type]="colorEnum.secondary"
          [leftIcon]="iconEnum.outlineheart"
          [isMobile]="true"
        ></datnek-ds-button>
      </div>

      <datnek-ds-button
        [label]="'Commentaires'"
        [size]="sizeEnum.large"
        [block]="true"
        [type]="colorEnum.secondary"
        [leftIcon]="iconEnum.outlinechat"
        (datnekdsClickOrTouch)="onAddComment()"
        [isMobile]="true"
      ></datnek-ds-button>

      <button (datnekdsClickOrTouch)="openDropdown(share, origin3)" #origin3>
        <datnek-ds-button
          [label]="'Partages'"
          [size]="sizeEnum.large"
          [block]="true"
          [type]="colorEnum.secondary"
          [leftIcon]="iconEnum.outlinereplyRight"
          [isMobile]="true"
        ></datnek-ds-button>
      </button>
    </div>
  </div>

  <!--COMMENTS ADD-->
  <div
    class="add-comments"
    [ngClass]="addComment === true ? 'd-block pb-3' : 'd-none'"
  >
    <del-shared-comment-add></del-shared-comment-add>
  </div>
  <!--COMMENTS LIST-->
  <div class="list-comments" *ngIf="showListComments">
    <div class="filter d-flex align-items-center justify-content-end mb-3">
      <span class="mr-3">Filtrer par </span>
      <datnek-ds-simple-text-dropdown
        [submenuData]="subMenu"
        [label]="label"
      ></datnek-ds-simple-text-dropdown>
    </div>

    <div class="comments-items">
      <del-shared-comment-pushed
        [commentData]="dataComments"
      ></del-shared-comment-pushed>
      <del-shared-comment-pushed
        [commentData]="dataComments"
        *ngIf="showMore === true"
        [@moreSuggestion]="showMore"
      ></del-shared-comment-pushed>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <datnek-ds-button
        [type]="colorEnum.link"
        label="Voir plus de commentaires"
        *ngIf="showMore === true"
        (datnekdsClickOrTouch)="onShowMore()"
      ></datnek-ds-button>
      <datnek-ds-button
        [type]="colorEnum.link"
        label="Voir moins de commentaires"
        *ngIf="showMore === true"
        (datnekdsClickOrTouch)="onShowMore()"
      ></datnek-ds-button>
    </div>
  </div>
</div>

<datnek-skeleton-publication-box
  *ngIf="isLoading"
></datnek-skeleton-publication-box>

<!--MESSAGES-->
<ng-template #en let-close="close"> Element enregistré </ng-template>

<ng-template #cp let-close="close">
  Le lien de ce post a bien été copié
</ng-template>

<ng-template #sv let-close="close">
  Vous ne suivez plus John Doe désormais
</ng-template>

<ng-template #sp let-close="close">
  Ce post a été supprimé de votre mur
</ng-template>

<ng-template #rp let-close="close"> Le post a été partagé </ng-template>
<ng-template #val let-close="close"> Le post a bien été validé </ng-template>

<!-- DROPDOWNS -->
<ng-template #likesicons let-close="close">
  <div class="dropdown-card like-icons-drop">
    <del-shared-likes-icons-popbox></del-shared-likes-icons-popbox>
  </div>
</ng-template>
<ng-template #actions let-close="close">
  <div class="datnek-ds-card dropdown-card card-publication-actions">
    <div class="close justify-content-end">
      <datnek-ds-button-icon 
        [Icon]="iconEnum.outlinex" 
        [size]="sizeEnum.medium" 
        (datnekdsClickOrTouch)="close()" 
        [type]="colorEnum.secondary"
      > </datnek-ds-button-icon>
    </div>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.checkCircle"
      label="Valider le post"
      description="Le post répond aux normes du groupe et peut être validé"
      (datnekdsClickOrTouch)="showToast(val); close()"
      *ngIf="!isPublic"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinetrash"
      label="Supprimer le post"
      description="Supprimer définitivement le post"
      (datnekdsClickOrTouch)="deletePost(); close()"
      *ngIf="!isPublic"
    ></datnek-ds-icon-submenu>
    <hr />
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinebookmark"
      label="Enregistrer"
      description="Enregistrer pour plus tard"
      (datnekdsClickOrTouch)="addToCategory(); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinelink"
      label="Copiez le lien de ce post"
      description="Vous pourrez le partager ou y accéder en copiant dans le navigateur"
      (datnekdsClickOrTouch)="showToast(cp); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinexCircle"
      label="Ne plus suivre {John Doe}"
      description="Restez en relation avec {John Doe} mais ne plus suivre ses actualites"
      (datnekdsClickOrTouch)="showToast(sv); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlineeyeOff"
      label="Ne plus voir ce post"
      description="Supprimez ce post de votre mur"
      (datnekdsClickOrTouch)="showToast(sp); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlineflag"
      label="Signaler ce post"
      description="Ce post contient du contenu offansant ou compte piraté"
      (datnekdsClickOrTouch)="addSignalization(); close()"
    ></datnek-ds-icon-submenu>
  </div>
</ng-template>

<ng-template #share let-close="close">
  <div class="datnek-ds-card dropdown-card card-publication-share">
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.outlinereplyRight"
      label="Republier le post"
      description="Publier ce post tel quel sans changement."
      (datnekdsClickOrTouch)="showToast(rp); close()"
    ></datnek-ds-icon-submenu>
    <datnek-ds-icon-submenu
      class="dropdown-item"
      [icon]="iconEnum.pencilAlt"
      label="Partager avec modification"
      description="Publier ce posten ajoutant votre propre commentaire "
      (datnekdsClickOrTouch)="sharePostWithEdit(); close()"
    ></datnek-ds-icon-submenu>
  </div>
</ng-template>
