<form class="dialog-new-content"  (ngSubmit)="submit()">
  <div class="datnek-ds-dialog-body">
    <div class="content-modal">

      <formly-form [model]="model" [fields]="fields"
                   [options]="options" [form]="form"></formly-form>

      <!-- <formly-form [model]="modelSelect" [fields]="fieldsSelect"
                   [options]="options" [form]="form"></formly-form> -->


      <!--IF IS PICTURES ADDED-->
      <ng-container *ngIf="imagesList === true">
        <div class="mt-3">
          <div class="thumbnail-view px-3">
            <div class="d-flex">
              <div class="items">
                <div class="mb-3">
                  <ngb-progressbar [value]="progress"
                    >{{ progress }}%</ngb-progressbar
                  >
                </div>

                <div class="item-list" *ngIf="imported">
                  <ng-container *ngFor="let item of imageList; let i = index">
                    <div class="img position-relative">
                      <img src="{{ item }}" alt="" />
                      <datnek-ds-button-icon
                        [Icon]="iconEnum.solidinboxclose"
                        [type]="colorEnum.secondary"
                        class="close-btn"
                        [round]="true"
                        [fade]="true"
                        (datnekdsClickOrTouch)="removeFile(i)"
                        [size]="sizeEnum.small"
                      ></datnek-ds-button-icon>
                    </div>
                  </ng-container>
                </div>
              </div>
              <datnek-ds-button-icon
                [Icon]="iconEnum.solidinboxclose"
                [type]="colorEnum.secondary"
                [round]="true"
                class="icon-close ml-3"
                [size]="sizeEnum.small"
                (datnekdsClickOrTouch)="imagesList = false"
              ></datnek-ds-button-icon>
            </div>
          </div>

          <!--<datnek-ds-image-gallery
        [isgroupMultimedia]="true"
        [galleryItems]="galleryItems"
        [closable]="true"
      ></datnek-ds-image-gallery>-->
        </div>
      </ng-container>

      <!--IF IS VIDEOS ADDED-->
      <ng-container *ngIf="videoList === true">
        <div class="mt-3">
          <div class="thumbnail-view px-3">
            <div class="d-flex">
              <div class="items">
                <div class="mb-3">
                  <ngb-progressbar [value]="progress"
                    >{{ progress }}%</ngb-progressbar
                  >
                </div>
                <div class="item-list" *ngIf="imported">
                  <figure
                    class="
                      d-flex
                      align-items-center
                      pointer
                      justify-content-center
                    "
                  >
                    <div class="play">
                      <img [src]="'Img/icons/play-video.svg' | assetUrl" alt="" />
                    </div>
                    <video class="embed-responsive embed-responsive-16by9">
                      <source
                        src="https://static.videezy.com/system/resources/previews/000/000/168/original/Record.mp4"
                      />
                    </video>
                  </figure>
                </div>
              </div>
              <datnek-ds-button-icon
                [Icon]="iconEnum.solidinboxclose"
                [type]="colorEnum.secondary"
                [round]="true"
                class="icon-close ml-3"
                [size]="sizeEnum.small"
                (datnekdsClickOrTouch)="videoList = false"
              ></datnek-ds-button-icon>
            </div>
          </div>

        </div>

      </ng-container>

      <!--IF AUDIO-->
      <ng-container *ngIf="voiceList === true">
        <div class="my-3 message-audio">
          <div class="thumbnail-view px-3">
            <div class="d-flex">
              <div class="items">
                <div class="mb-3">
                  <ngb-progressbar [value]="progress"
                    >{{ progress }}%</ngb-progressbar
                  >
                </div>
                <audio controls="">
                  <source
                    [src]="'file_example_WAV_1MG.wav' | assetUrl"
                    type="audio/wav"
                  />
                </audio>
              </div>
              <datnek-ds-button-icon
                [Icon]="iconEnum.solidinboxclose"
                [type]="colorEnum.secondary"
                [round]="true"
                class="icon-close ml-3"
                (datnekdsClickOrTouch)="voiceList = false"
              ></datnek-ds-button-icon>
            </div>
          </div>
        </div>
      </ng-container>

      <!--IF CALENDAR-->
      <!--ng-container *ngIf="linkList === true">
        <div class="mt-3">
          <div class="thumbnail-view px-3">
            <div class="d-flex">
              <div class="items">
                <ngb-progressbar [value]="progress" *ngIf="!imported"
                  >{{ progress }}%</ngb-progressbar
                >
                <div class="item-list link-list" *ngIf="imported">
                  <div
                    class="link datnek-ds-card"
                    (datnekdsClickOrTouch)="link('http://getbootstrap.com/')"
                  >
                    <div class="img">
                      <img [src]="'Img/link-placeholder-bg.png' | assetUrl" alt="" />
                    </div>
                    <div class="content-infos p-3">
                      <div class="display-2 clip ellipsis">Titre de la page</div>
                      <p>
                        {{ linkDescription | truncatetext: 65 }}
                      </p>
                      <span class="d-flex"
                        ><datnek-ds-svg-icon
                          [icon]="iconEnum.outlinelink"
                          class="mr-2"
                        ></datnek-ds-svg-icon>
                        <small class="truncated"
                          >http://getbootstrap.com/</small
                        ></span
                      >
                    </div>
                  </div>
                  <div
                    class="link datnek-ds-card"
                    (datnekdsClickOrTouch)="link('http://getbootstrap.com/')"
                  >
                    <div class="img">
                      <img [src]="'Img/link-placeholder-bg.png' | assetUrl" alt="" />
                    </div>
                    <div class="content-infos p-3">
                      <div class="display-2 clip ellipsis">
                        Titre de la page se passe ici
                      </div>
                      <p>
                        {{ linkDescription | truncatetext: 65 }}
                      </p>
                      <span class="d-flex"
                        ><datnek-ds-svg-icon
                          [icon]="iconEnum.outlinelink"
                          class="mr-2"
                        ></datnek-ds-svg-icon>
                        <small class="truncated"
                          >http://getbootstrap.com/progress-bar/jsdfdskjfhkdshdhfdhs</small
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container-->
    </div>
  </div>

  <!-- FOOTER MODAL -->
  <div class="datnek-ds-dialog-footer">
    <div class="icons d-flex align-items-center justify-content-between w-100">
      <div class="icon-content d-flex">
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlinephotograph"
          (datnekdsClickOrTouch)="addPicture(); showImages()"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlinefilm"
          (datnekdsClickOrTouch)="addVideo(); showVideos()"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlinedocumentText"
          (datnekdsClickOrTouch)="addDocument(); showDocuments()"
        ></datnek-ds-button-icon>
        <datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlinemicrophone"
          (datnekdsClickOrTouch)="addVoice(); showVoices()"
        ></datnek-ds-button-icon>
        <!--datnek-ds-button-icon
          [type]="colorEnum.ghost"
          [size]="sizeEnum.large"
          [Icon]="iconEnum.outlinecalendar"
          (datnekdsClickOrTouch)="addCalendar(); showLinks()"
        ></datnek-ds-button-icon-->
      </div>

      <datnek-ds-button
        [label]="'Publier'"
        [role]="'submit'"
        [size]="sizeEnum.large"
        [type]="colorEnum.primary"
      ></datnek-ds-button>
    </div>
  </div>
</form>
