import { Component, EventEmitter, Output } from '@angular/core';
import {AvatarTypeEnum, ControlSizeEnum} from "datnek-ds";

@Component({
  selector: 'nk-shared-comment-add',
  templateUrl: './comment-add.component.html',
  styleUrls: ['./comment-add.component.scss'],
})
export class CommentAddComponent {
  sizeEnum = ControlSizeEnum;
  avatarEnum = AvatarTypeEnum;

  content = '';
  constructor() {}

  Toolbar = false;

  /**
   * Optional click handler
   */
  @Output()
  onClick = new EventEmitter<Event>();

  showToolbar(): void {
    this.Toolbar = !this.Toolbar;
  }

  onClickButton(event: any) {
    this.onClick.emit(event);
  }

  clickListener(e: any) {
    if (e.keyCode === 13) {
      console.log(e);
    }
  }
}
