<datnek-skeleton-calendar
              *ngIf="isLoading"
            ></datnek-skeleton-calendar>
            
<div class="calendar" *ngIf="!isLoading">
              <div
                class="
                  calendar-header
                  px-2
                  d-flex
                  justify-content-between
                  align-items-center
                  w-100
                "
              >
                <div class="left d-flex align-items-center">
                  <datnek-ds-button
                    [type]="colorEnum.unstyled"
                    [size]="sizeEnum.medium"
                    label="Aujourd'hui"
                    class="bordered-button"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                  ></datnek-ds-button>
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.outlinechevronLeft"
                    [type]="colorEnum.ghost"
                    [size]="sizeEnum.medium"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                  ></datnek-ds-button-icon>
                  <datnek-ds-button-icon
                    [Icon]="iconEnum.outlinechevronRight"
                    [type]="colorEnum.ghost"
                    [size]="sizeEnum.medium"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                  ></datnek-ds-button-icon>
                  <span class="truncated mb-0 ml-3">
                    {{ viewDate | calendarDate: view + "ViewTitle":"fr" }}
                  </span>
                </div>
                <div class="right d-flex justify-content-end">
                  <div
                    class="datnek-border-dorpdown"
                    ngbDropdown
                    #myDrop="ngbDropdown"
                  >
                    <button
                      class="d-flex align-items-center justify-content-between"
                      id="dropdownManual"
                      ngbDropdownAnchor
                      (focus)="myDrop.open()"
                    >
                      <div class="d-flex align-items-center">
                        <span>Semaine</span>
                      </div>

                      <datnek-ds-svg-icon
                        [icon]="iconEnum.outlinechevronDown"
                        class="ml-2"
                      ></datnek-ds-svg-icon>
                    </button>
                    <div
                      class="icon-profile-dropdown"
                      ngbDropdownMenu
                      aria-labelledby="dropdownManual"
                    >
                      <button
                        ngbDropdownItem
                        (datnekdsClickOrTouch)="setView(CalendarView.Month)"
                      >
                        Mois
                      </button>
                      <button
                        ngbDropdownItem
                        (datnekdsClickOrTouch)="setView(CalendarView.Week)"
                      >
                        Semaine
                      </button>
                      <button
                        ngbDropdownItem
                        (datnekdsClickOrTouch)="setView(CalendarView.Day)"
                      >
                        Jour
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div [ngSwitch]="view">
                <mwl-calendar-month-view
                  *ngSwitchCase="CalendarView.Month"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  [activeDayIsOpen]="activeDayIsOpen"
                  (dayClicked)="dayClicked($event.day)"
                  (eventTimesChanged)="eventTimesChanged($event)"
                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                  *ngSwitchCase="CalendarView.Week"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  (eventTimesChanged)="eventTimesChanged($event)"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                  *ngSwitchCase="CalendarView.Day"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  (eventTimesChanged)="eventTimesChanged($event)"
                >
                </mwl-calendar-day-view>
              </div>
            </div>