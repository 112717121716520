<ng-container *ngIf="!isLoading && EventData.length">
  <datnek-ds-search-input
    placeholder="Rechercher personne/groupe"
  ></datnek-ds-search-input >
  <p-scrollPanel [style]="{ width: '100%', height: 'calc(100vh - 410px)' }">
    <div
      class="user-chat-list d-flex py-3 event-list"
      *ngFor="let data of EventData"
      (datnekdsClickOrTouch)="goToEventDetails($event)"
    >
      <div class="d-flex align-items-center user-data">
        <div class="img">
          <img src="/assets/Img/bg/bg-placeholder.svg" alt="" />
        </div>
        <div class="infos">
          <div class="mr-2">{{ data.name | truncatetext: 30 }}</div>
          <small class="time display-6 w-25 text-right">{{ data.date }}</small>
        </div>
      </div>

      <datnek-ds-notification-point number="3"></datnek-ds-notification-point>
    </div>
  </p-scrollPanel>
</ng-container>

<ng-container *ngIf="isLoading && EventData.length">
  <datnek-skeleton-user-chat-list
    *ngFor="let in of counter(5); let i = index"
  ></datnek-skeleton-user-chat-list>
</ng-container>

<ng-container *ngIf="!EventData.length">
  <datnek-ds-button
    label="Créer un évènement"
    [size]="sizeEnum.large"
    [block]="true"
    [type]="colorEnum.primary"
    *ngIf="!isLoading"
    (datnekdsClickOrTouch)="addEvent()"
  ></datnek-ds-button>
  <div class="skeleton" *ngIf="!isLoading">
    <div class="button medium-h w-100"></div>
  </div>
  <datnek-ds-no-content
    imgSrc="assets/icons/src/calendar_1.svg"
    titleMsg="Aucun évènement créé"
    contentDesc="Vous n’avez aucun évènement pour l’instant"
  ></datnek-ds-no-content>
</ng-container>
